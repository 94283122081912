import React from 'react';
import axios from 'axios';

//material-ui
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';


//my component
import MarginedButton from '../common/MarginedButton'
import { DialogTitle, DialogContent } from '../common/DialogParts'
import { DialogOpen, DialogClose } from '../../action/common/DialogAction'


//setting
import { ROOT_URL, API_ROOT_URL } from "../../setting"

export default class DeleteDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    OnSubmitClick = () => {
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.get(API_ROOT_URL + "userdelete/", {}).then(() => {
            document.location.href = ROOT_URL
        })

    }

    render() {
        return (
            <DeleteDialogBox>
                <MarginedButton onClick={() => DialogOpen(this)} variant="contained">
                    アカウントの削除
                </MarginedButton>
                <Dialog onClose={() => DialogClose(this)} ari-labelledby="customized-dialog-title" open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={() => DialogClose(this)}>
                        アカウントの削除
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variang="body2">
                            この動作を実行すると、アカウントと紐づけられたコンテンツがすべて削除されます。<br />
                            削除されたコンテンツを復元することは運営にもできません。<br /><br />
                            本当に削除する場合下のボタンを押してください。
                        </Typography>
                        <MarginedButton
                            variant="outlined"
                            fullWidth
                            onClick={this.OnSubmitClick}
                        >
                            アカウントを削除
                        </MarginedButton>
                        <br />
                    </DialogContent>
                </Dialog>
            </DeleteDialogBox>
        )
    }
}

const DeleteDialogBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 1, 1, 2),
        padding: theme.spacing(1),
    }
})

const DeleteDialogBox = withStyles(DeleteDialogBoxStyles)(Box);