import React from 'react';

//material-ui
import TextField from '@material-ui/core/TextField';

const OfficialTitleField = (props) => {
    return (
        <>
            <TextField
                id="standard-basic"
                label="正式な曲名"
                name="official_title"
                helperText={<>JASRACへの報告で使用します。<br />投稿主のオリジナル曲や同人音楽でも必ず入力してください。<br />余分な括弧や飾り文字を含まず正式な曲名を入力してください。<br />100文字以内</>}
                required
                fullWidth
                inputProps={{ maxLength: 100 }}
                onChange={props.onTextChange}
                value={props.value}
            />

        </>
    )
}
export default OfficialTitleField