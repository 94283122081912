import React from 'react';

//material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const MarginedButtonStyles = (theme) => ({
    root: {
        margin: theme.spacing(1),
    }
})

const MarginedButton = withStyles(MarginedButtonStyles)(Button);
export default MarginedButton
