import React from 'react';


//material-ui
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
//my component
import MarginedButton from '../common/MarginedButton'

//setting
import { LIMIT_DATE } from "../../setting"

export default class AuthorMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: <></>,
            publosh: <></>,
            private: <></>,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            let view
            let publish
            let isPrivate
            if (this.props.IsOwner) {
                view =
                    <>
                        <MarginedButton
                            variant="contained"
                            size="small"
                            onClick={() => { this.props.history.push("/edit/" + this.props.music.url) }}
                        >
                            編集
                        </MarginedButton>
                    </>
            } else {
                view = <></>
            }

            if (this.IsMusicPublish(this.props.music)) {
                publish = <></>
            } else {
                publish =
                    <>
                        <Typography color='secondary' variant='body1'>
                            この音源は公開されていません
                        </Typography>
                    </>

            }

            if (this.props.music.private) {
                isPrivate =
                    <>
                        <Typography color='secondary' variant='body1'>
                            この音源はurlを知っている人だけに公開されます
                        </Typography>
                    </>
            } else {
                isPrivate = <></>
            }

            this.setState({
                view: view,
                publish: publish,
                private: isPrivate,
            })
        }
    }
    IsMusicPublish = (music) => {
        if (music.original) {
            return (music.publish)
        } else if (music.credit_count > 0) {
            return (music.publish)
        }
        const limitDate = new Date(
            music.upload_date.slice(0, 4),
            parseInt(music.upload_date.slice(5, 7)) - 1,
            parseInt(music.upload_date.slice(8, 10)) + LIMIT_DATE,
            music.upload_date.slice(11, 13),
            music.upload_date.slice(14, 16),
            music.upload_date.slice(17, 19),
        )
        if (limitDate.getTime() > Date.now()) {
            return (music.publish)
        } else {
            return (false)
        }
    }

    render() {
        return (
            <>
                {this.state.view}
                {this.state.publish}
                {this.state.private}
            </>
        )
    }
}
