import React from 'react';

//material-ui
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

export default class DescriptionArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: this.props.data.description,
            descriptions: []
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                description: this.props.data.description,
                descriptions: this.props.data.description.split("\n"),
            })
        }
    }

    GetLine = (line) => {
        if (line.match(/(.*)(https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)(.*)/)) {
            const before=RegExp.$1
            const main=RegExp.$2
            const after=RegExp.$3
            return (
                <>
                    {this.GetLine(before)}
                    <a href={main} target='_blank'>
                        {main}
                    </a>
                    {after}
                </>
            )
        } else {
            return (line)
        }
    }

    render() {
        return (
            <Box>
                <Typography variant="body2" className={this.props.classes.contents}>
                    {this.state.descriptions.map((line) => (
                        <>

                            {this.GetLine(line)}
                            < br />
                        </>
                    ))}
                </Typography>
                <Divider />
            </Box>
        )
    }
}