import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//material-ui
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/core/styles';

//my component
import Header from './Header'
import Footer from './Footer'
import Rule from './view/Rule'
import SimpleRule from './view/SimpleRule'
import Privacy from './view/Privacy'
import Guideline from './view/Guideline'
import TopPage from './view/TopPage'
import Dev from './view/Dev'
import Uploader from './view/Uploader'
import MusicPlayer from './view/MusicPlayer'
import MusicEdit from './view/MusicEdit'
import Search from './view/Search'
import NewsDetail from './view/NewsDetail'
import NewsList from './view/NewsList'
import UserPage from './view/UserPage'
import PlayList from './view/PlayList'
import ScrollToTop from './ScrollToTop';
import Appsupport from './view/Appsupport';
import Login from './view/Login'

class App extends React.Component {
    constructor(props) {
        super(props);
        window.addEventListener('resize', this.SetAppStyle)
        let redirectV2 = JSON.parse(localStorage.getItem('redirectV2'))
        if (redirectV2 === null) {
            redirectV2 = false
        } else if (redirectV2 === true) {
            if (document.location.pathname.startsWith('/user') || document.location.pathname.startsWith('/edit') || document.location.pathname.startsWith('/upload') || document.location.pathname.startsWith('/appsupport')) {
            } else {
                const redirect_url = document.location.href.replace(document.location.origin, document.location.origin + "/v2")
                document.location.href = redirect_url
            }
        }
        this.state = {
            appStyle: {
                minHeight: window.innerHeight * 0.85,
            }
        }
    }

    SetAppStyle = () => {
        this.setState({
            appStyle: {
                minHeight: window.innerHeight * 0.85,
            }
        })
    }


    render() {
        return (
            <div>
                <Router>
                    <ScrollToTop>
                        <Route path="/" component={Header} />
                        <Container maxWidth="xl" style={this.state.appStyle} onResize={() => { console.log("resize") }}>
                            <Route path="/(tag)/:keyword" component={Search} />
                            <Route path="/(search)/:keyword" component={Search} />
                            <Route path="/upload" component={Uploader} />
                            <Route path="/music/:id" component={MusicPlayer} />
                            <Route path="/edit/:id" component={MusicEdit} />
                            <Route path="/news/:id" component={NewsDetail} />
                            <Route path="/playlist/:id" component={PlayList} />
                            <Route path="/user/:username" component={UserPage} />
                            <Route path="/news/" exact component={NewsList} />
                            <Route path="/rule/" exact component={Rule} />
                            <Route path="/simplerule/" exact component={SimpleRule} />
                            <Route path="/privacy/" exact component={Privacy} />
                            <Route path="/guideline/" exact component={Guideline} />
                            <Route path="/appsupport/" exact component={Appsupport} />
                            <Route path="/login/" exact component={Login} />
                            <Route path="/" exact component={TopPage} />
                        </Container>
                        <Route path="/" component={Footer} />
                    </ScrollToTop>
                </Router>
                <ScrollTop />
            </div>
        );

    }
}

export default App;

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));
function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#header');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </div>
        </Zoom>
    );
}
