import React from 'react';

//material-ui
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { GetLikeMusic, onFavClick, onFavDelete } from '../../action/player/FavoriteButtonAction'


export default class FavoriteButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            isFavorite: false,
        }
        console.log(this.props.isLoggedIn)
        GetLikeMusic(this.props.id).then(() => {
            this.setState({
                isFavorite: true,
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.setState({
                id: this.props.id,
                isFavorite: false,
            })
            GetLikeMusic(this.props.id).then(() => {
                this.setState({
                    isFavorite: true,
                })
            })
        }

    }

    render() {
        return (
            <>
                {this.props.isLoggedIn
                ?this.state.isFavorite
                    ? <><Tooltip title="この曲のイイネを取り消す">
                        <IconButton
                            onClick={() => onFavDelete(this, this.state.id)}
                        >
                            <FavoritedIcon
                                classes={this.props.classes}
                            />
                        </IconButton>
                    </Tooltip></>
                    : <><Tooltip title="この曲をイイネする">
                        <IconButton
                            onClick={() => onFavClick(this, this.state.id)}
                        >
                            <NoFavoriteIcon
                                classes={this.props.classes}
                            />
                        </IconButton>
                </Tooltip></>
                    : <><Tooltip title="ログインするとイイネでこの曲を応援できます">
                        <IconButton>
                            <NoFavoriteIcon
                                classes={this.props.classes}
                            />
                        </IconButton>
                    </Tooltip></>}
            </>
        )
    }
}

const FavoritedIcon = (props) => {
    return (
        <>
            <FavoriteIcon
                size="large"
                className={props.classes.IconMargin}
                color="secondary"
                onClick={props.onFavDelete}
            />
        </>
    )
}

const NoFavoriteIcon = (props) => {
    return (
        <>
            <FavoriteBorderIcon
                size="large"
                className={props.classes.IconMargin}
                onClick={props.onFavClick}
            />
        </>
    )
}