import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";


//my component
import { useStyles } from '../style/common'
import Box from '@material-ui/core/Box';
import RootBox from './common/RootBox'
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import RuleTabs from './RuleTabs'
//setting
import { SITE_TITLE } from "../setting"

const Privacy = (props) => {
    const classes = useStyles()


    return (
        <RootBox>
            <Helmet>
                <title>{SITE_TITLE} -プライバシーポリシー-</title>
            </Helmet>
            <Paper className={classes.userBlock} id="music">
                <Breadcrumbs>
                    <Link
                        color="inherit"
                        onClick={() => { props.history.push("/") }}
                    >
                        TOP
                    </Link>
                    <Typography>
                        プライバシーポリシー
                    </Typography>
                </Breadcrumbs>
                <Divider />
                <RuleTabs
                    value={2}
                />
                <RuleBox>
                    <Typography variant="subtitle1">
                        本プライバシーポリシーは、きみがため(以下「運営団体」といいます。)が提供する「Utaloader」(以下「本サービス」といいます。)において、
                        運営団体が本サービスを利用する利用者(以下「利用者」といいます。)のプライバシーを尊重し、利用者の個人情報を適切に管理、運用することを目的として、
                        本サービスにおける個人情報の取り扱いと責任の所在を明らかにするものです。
                    </Typography>
                    <br />
                    <Typography variant="h6">
                        第1条 定義
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営団体では、次の情報を個人情報と定めます。
                            <ol>
                                <li>個人情報の保護に関する法律に規定される、生存する個人に関する情報(氏名、住所、電話番号、その他の特定の個人を識別することができる情報)</li>
                                <li>メールアドレス</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第2条 利用目的
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営団体は、個人情報を次の目的で利用します。
                            <ol>
                                <li>本サービスに関する案内、問い合わせへの対応</li>
                                <li>規約とポリシーに違反する行為への対応</li>
                                <li>本サービスの改善、新サービスの開発</li>
                                <li>個別の識別情報を含まない統計情報の作成</li>
                                <li>その他、上記利用目的に付随する目的</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第3条 取得方法
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営団体は、利用者が本サービスおよび運営会社が委託する事業者のサービスを利用して入力した範囲に限り、利用者の個人情報を取得します。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第4条 第三者への提供
                    </Typography>
                    <RuleSubBox>
                        運営団体は、利用者の同意を得ず、利用者の個人情報を第三者に提供しません。ただし、次の場合はこの限りではありません。
                        <Typography variant="body2">
                            <ol>
                                <li>運営会社が、利用者にメールの配信を行う目的で、配信サービスを有する事業者に委託する場合</li>
                                <li>運営会社が、法務を遂行する目的で、弁護士に委託する場合</li>
                                <li>その他、運営会社が、本サービスの業務を遂行する目的で、業務の一部を事業者に委託する場合</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第5条 取り扱いの制限
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営団体は、利用者の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。ただし、次の場合はこの限りではありません。
                            <ol>
                                <li>法令に基づく場合</li>
                                <li>人の生命、身体または財産の保護のために必要がある場合であって、利用者の同意を得ることが困難である場合</li>
                                <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第6条 開示
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営会社は、利用者から利用者本人の個人情報の開示を求められた場合は、その開示に応じます。ただし、次の場合にはその全部または一部を開示しない場合があります。
                            <ol>
                                <li>法令に違反する恐れがある場合</li>
                                <li>利用者本人または第三者の生命・身体・財産その他の権利を害するおそれがある場合</li>
                                <li>開示に応じることが運営会社の業務遂行に著しい障害を生じさせる場合</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第7条 Cookie
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営会社は、利用者の利便性の向上と利用状況の把握を目的として本サービスでCookieを使用する場合があります。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第8条 免責
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営会社は、第三者による個人情報の取り扱いに関し、次に掲げる場合には一切の責任を負いません。
                            <ol>
                                <li>利用者が、本サービスの機能または別の手段を用いて第三者に個人情報を明らかにした場合</li>
                                <li>第三者が、外部の手段を用いて取得した利用者の個人情報を本サービスの機能を用いて明らかにした場合</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第9条 改定
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営会社は、本プライバシーポリシーについて、事前に利用者へ適切な周知を行うことで、利用者の同意を得ず、改定できるものとします。
                        </Typography>
                    </RuleSubBox>
                    <RuleDateBox>
                        <Typography variant="body2">
                            制定：2020年7月23日
                        </Typography>
                    </RuleDateBox>
                </RuleBox>
            </Paper>
        </RootBox>
    )

}
export default withRouter(Privacy)

const RuleBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(2),
    }
})

const RuleBox = withStyles(RuleBoxStyles)(Box);

const RuleSubBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 2, 4),
    }
})

const RuleSubBox = withStyles(RuleSubBoxStyles)(Box);


const RuleDateBoxStyles = (theme) => ({
    root: {
        textAlign: 'right',
    }
})

const RuleDateBox = withStyles(RuleDateBoxStyles)(Box);