import React from 'react';


//material-ui
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

//my component
import CreditButton from './CreditButton'
import CreditsHelp from './CreditsHelp'

//setting
import { LIMIT_DATE } from "../../setting"

export default class CreditArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            credit: [],
        }
        this.table = {
            MN: 0,
            V: 1,
            M: 2,
            L: 3,
            A: 4,
            G: 5,
            P: 6,
            S: 7,
            J: 8
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            let credits = this.props.data.credit
            credits.sort((a, b) => {
                if (this.table[a.type] > this.table[b.type]) return 1;
                if (this.table[a.type] < this.table[b.type]) return -1;
                return 0;
            })
            this.setState({
                credit: credits
            })
        }
    }

    GetCredits = () => {
        if (this.props.data.original) {
            return (
                <>
                    <Typography variant="body1">
                        この作品はすべて投稿主により制作されています。
                    </Typography>
                </>
            )
        } else if (this.state.credit.length === 0) {
            if (this.props.data.upload_date === undefined) {
                return (<></>)
            }
            console.log(this.props.data.upload_date)
            const limitDate = new Date(
                this.props.data.upload_date.slice(0, 4),
                parseInt(this.props.data.upload_date.slice(5, 7)) - 1,
                parseInt(this.props.data.upload_date.slice(8, 10)) + LIMIT_DATE,
                this.props.data.upload_date.slice(11, 13),
                this.props.data.upload_date.slice(14, 16),
                this.props.data.upload_date.slice(17, 19),
            )
            if (limitDate.getTime() <= Date.now()) {
                return (
                    <>
                        <Typography variant="body1">
                            この作品はまだクレジット登録がされておりません。<br />
                            期限が過ぎたため、クレジットを登録するまで公開することはできません。
                    </Typography>
                    </>
                )
            } else if (this.props.data.publish) {
                return (
                    <>
                        <Typography variant="body1">
                            この作品はまだクレジット登録がされておりません。<br />
                            {limitDate.toLocaleString('ja-JP')}に自動的に非公開にされます。
                    </Typography>
                    </>
                )
            } else {
                return (
                    <>
                        <Typography variant="body1">
                            この作品はまだクレジット登録がされておりません。<br />
                            {limitDate.toLocaleString('ja-JP')}以降、クレジットを登録するまで公開できなくなります。
                    </Typography>
                    </>
                )
            }
        }
    }

    render() {
        return (
            <>
                <Typography variant="h6" className={this.props.classes.title}>
                    クレジット
                <CreditsHelp classes={this.props.classes} />
                </Typography>
                <CreditBox>
                    {this.GetCredits()}
                    {
                        this.state.credit.map((credit) => (
                            <CreditButton value={credit.name} type={credit.type} />
                        ))
                    }
                </CreditBox>
                <Divider />
            </>
        )
    }
}

const CreditBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(0, 1, 1, 2),
        padding: theme.spacing(1),
    }
})

const CreditBox = withStyles(CreditBoxStyles)(Box);