import React from 'react';

//my component
import ReportDialog from './ReportDialog'
import WidePlusAd from '../common/WidePlusAd'

export default class ShareArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.data.id,
            url: this.props.data.url,
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.data !== prevProps.data)) {
            this.setState({
                id: this.props.data.id,
                url: this.props.data.url,
            })
        }
    }
    render() {
        return (
            <>
                <ReportDialog musicId={this.state.id} />
                <WidePlusAd />
            </>
        )
    }
}

