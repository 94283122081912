import axios from 'axios';


//Action
import { tagUpload } from './TagUploadAction'
import { creditUpload } from './CreditUploadAction'

//setting
import { API_ROOT_URL } from "../../setting"

export async function Upload(self) {
    const params = new FormData()
    self.setState({
        isUploading: true,
    })
    let errorvalue = ["アップロードに失敗しました"]
    params.append("title", self.state.title)
    if (self.state.title === "") {
        errorvalue.push("タイトルの入力は必須です")
    }
    params.append("description", self.state.description)
    if (self.state.description === "") {
        errorvalue.push("説明文の入力は必須です")
    }
    params.append("is_music", !self.state.is_music)
    if (self.state.official_title === null) {
        params.append("official_title", "")
    } else {
        params.append("official_title", self.state.official_title)
    }
    if ((!self.state.is_music) && ((self.state.official_title === "") || (self.state.official_title === null))) {
        errorvalue.push("音楽の場合曲名は省略できません。")
        self.setState({
            errorBarOpen: true,
            errorBarText: errorvalue,
            isUploading: false,
        })
        return
    }
    params.append("audio", self.props.audioId)
    params.append("image", self.state.imageId)
    params.append("publish", self.state.publish)
    params.append("private", self.state.private && self.state.publish)
    params.append("tag_lock", self.state.tag_lock)
    params.append("original", self.state.original)

    if ((!self.state.is_music) && !self.state.original && !(CheckCredit(self))){
        errorvalue.push("オリジナル以外の音楽の場合、作詞者もしくは作曲者のクレジットが必要です。")
        self.setState({
            errorBarOpen: true,
            errorBarText: errorvalue,
            isUploading: false,
        })
        return
    }

    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    if (self.props.isUpdate) {
        axios.put(API_ROOT_URL + "music/" + self.props.musicId + "/",
            params
        ).then(async (res) => {
            if (!self.state.original) {
                await creditUpload(self, res.data.id)
            }
            if (!self.props.isUpdate) {
                await tagUpload(self, res.data.id)
            }
            self.props.moveMusic(res.data.url)
        }).catch(err => {
            self.setState({
                errorBarOpen: true,
                errorBarText: ["更新に失敗しました"],
                isUploading: false,
            })
        });

    } else {
        axios.post(API_ROOT_URL + "music/",
            params
        ).then(async (res) => {
            if (!self.state.original) {
                await creditUpload(self, res.data.id)
            }
            if (!self.props.isUpdate) {
                await tagUpload(self, res.data.id)
            }
            self.props.moveMusic(res.data.url)
        }).catch(err => {
            if (err.response.data['audio']) {
                if (err.response.data['audio'][0].indexOf("データが存在しません。") != -1) {
                    errorvalue.push("アップロードした音源は別のタブから削除されました")
                } else if (err.response.data['audio'][0].indexOf("一意でなければなりません。") != -1) {
                    errorvalue.push("この音源はすでに投稿されています")
                }
            }
            self.setState({
                errorBarOpen: true,
                errorBarText: errorvalue,
                isUploading: false,
            })
        });
    }

}

const CheckCredit = (self) => {
    for (let i = 0; i < self.state.creditsCount; i++) {
        if ((self.state.credits[i].type === 'M') || (self.state.credits[i].type === 'L')) {
            return true
        }
    }
    return false
}