import React from 'react';
import axios from 'axios';

//material-ui
import Typography from '@material-ui/core/Typography';

//my component
import MusicList from '../common/MusicList'
import MarginedButton from '../common/MarginedButton';

//setting
import { API_ROOT_URL } from "../../setting"


export default class FavoriteMusicList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tileData: [],
            next: null
        }
        this.GetTileData()
    }

    async GetTileData() {
        let tileData = []
        await (axios.get(API_ROOT_URL + "favoritetagmusic/", {}))
            .then(res => {
                this.setState({
                    tileData: res.data.results,
                    next: res.data.next
                })
            })
    }
    OnNextButtonClick = () => {
        this.AddTileData()
    }
    async AddTileData() {
        await (axios.get(this.state.next, {}))
            .then(res => {

                const tileData = this.state.tileData.slice().concat(res.data.results)
                this.setState({
                    tileData: tileData,
                    next: res.data.next
                })

            })
    }
    render() {
        return (
            <>
                <Typography variant="h6" className={this.props.classes.title}>
                    {this.props.title}
                </Typography>
                <MusicList
                    classes={this.props.classes}
                    tileData={this.state.tileData}
                    musicOnClick={this.props.musicOnClick}
                />
                {this.state.next !== null && <>
                    <MarginedButton
                        fullWidth
                        onClick={this.OnNextButtonClick}
                    >
                        更に表示
                </MarginedButton>
                </>}
            </>);
    }
}
