import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function NotFoundBar(props) {
    return (
            <Snackbar open={props.open} autoHideDuration={6000} onClose={props.handleClose}>
                <Alert severity="error">この音源は、存在しないか表示できません。</Alert>
            </Snackbar>
    );
}
