import React from 'react';

//material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MicIcon from '@material-ui/icons/Mic';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import SubjectIcon from '@material-ui/icons/Subject';
import TuneIcon from '@material-ui/icons/Tune';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import ImageIcon from '@material-ui/icons/Image';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const CreditButtonStyles = (theme) => ({
    root: {
        margin: theme.spacing(1),
        cursor: 'default',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&:active": {
            backgroundColor: "transparent",
        }
    }
})

export const CreditButtonBase = withStyles(CreditButtonStyles)(Button);

const CreditButton = (props) => {
    let icon
    let tooltip = ""
    if (props.type == "V") {
        icon = < MicIcon />
        tooltip = "ボーカル"
    } else if (props.type == "M") {
        icon = < MusicNoteIcon />
        tooltip = "作曲"
    } else if (props.type == "L") {
        icon = < SubjectIcon />
        tooltip = "作詞"
    } else if (props.type == "A") {
        icon = < LibraryMusicIcon />
        tooltip = "編曲"
    } else if (props.type == "P") {
        icon = < TuneIcon />
        tooltip = "調声/演奏/Mixなど"
    } else if (props.type == "S") {
        icon = < ImageIcon />
        tooltip = "サムネイル"
    } else if (props.type == "G") {
        icon = < QueueMusicIcon />
        tooltip = "採譜"
    } else if (props.type == "MN") {
        icon = < MusicNoteIcon />
        tooltip = "曲名"
    } else if (props.type == "J") {
        icon = <Typography variant="body1">J</Typography>
        tooltip = "JASRAC管理番号"
    }
    let color = "primary"
    if ((props.type == "MN") || (props.type == "J")) {
        color = "secondary"
    }

    return (
        <>
            <Tooltip title={tooltip}>
                <CreditButtonBase
                    variant="outlined"
                    size="small"
                    color={color}
                    startIcon={icon}
                    disableRipple
                    disableFocusRipple
                >
                    {props.value}
                </CreditButtonBase>
            </Tooltip>
        </>
    )
}

export default CreditButton