import React from 'react';
import { TwitterShareButton, } from 'react-twitter-embed';

//material-ui
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

//my component
import PlayerPaper from './PlayerPaper'
import AudioPlayer from './AudioPlayer'
import FavoriteButton from './FavoriteButton'
import PlaylistButton from './PlaylistButton'
import AuthorMenu from './AuthorMenu'
import Box from '@material-ui/core/Box';
import TitleArea from './TitleArea'
import WideAd from '../common/WideAd'

//setting
import { ROOT_URL, SITE_NAME } from "../../setting"

export default class PlayerArea extends React.Component {
    constructor(props) {
        super(props);
        let volume = localStorage.getItem("volume")
        if (volume === null) {
            volume = 0.5
        }
        window.addEventListener('resize', this.SetThumbStyle)
        this.state = {
            username: document.getElementById('username').innerText,
            id: this.props.data.id,
            imagePath: this.props.data.image_path,
            audioPath: this.props.data.audio_path,
            loop: true,
            volume: volume,
            shareButton: <></>,
            thumbStyle: {
                maxHeight: window.innerHeight * 0.5,
            }
        }
    }

    SetThumbStyle = () => {
        if (document.getElementById("thumb") !== null) {
            this.setState({
                thumbStyle: {
                    maxHeight: window.innerHeight - document.getElementById("thumb").getBoundingClientRect().top - 150,
                }
            })
        } else {
            this.setState({
                thumbStyle: {
                    maxHeight: window.innerHeight * 0.5,
                }
            })
        }
    }
    componentDidMount = () => {
        this.setState({
            thumbStyle: {
                maxHeight: window.innerHeight - document.getElementById("thumb").getBoundingClientRect().top - 150,
            }
        })
        console.log(document.getElementById("thumb").getBoundingClientRect().top)
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                imagePath: this.props.data.image_path,
                audioPath: this.props.data.audio_path,
                id: this.props.data.id,
                shareButton: <><TwitterShareButton
                    url={ROOT_URL + "music/" + this.props.data.url}
                    options={{
                        text: "-" + SITE_NAME + "-\n" + this.props.data.title + " by " + this.props.data.owner + " #Utaloader",
                        size: 'large'
                    }}
                /></>
            })
        }
    }
    IsLoggedIn = () => {
        if (this.state.username !== "AnonymousUser") {
            return (
                <Box style={{
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxWidth: '85%'
                }}>
                    <FavoriteButton id={this.state.id} classes={this.props.classes} isLoggedIn={this.state.username !== "AnonymousUser"}/>
                    <Box>{this.state.shareButton}</Box>
                    <PlaylistButton username={this.state.username} musicId={this.state.id} classes={this.props.classes} isLoggedIn={this.state.username !== "AnonymousUser"}/>
                </Box>
            )
        } else {
            return (
                <Box style={{
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxWidth: '85%'
                }}>
                    <FavoriteButton id={this.state.id} classes={this.props.classes} isLoggedIn={this.state.username !== "AnonymousUser"} />
                    <Box>{this.state.shareButton}</Box>
                    <PlaylistButton username={this.state.username} musicId={this.state.id} classes={this.props.classes} isLoggedIn={this.state.username !== "AnonymousUser"} />
                </Box>)
        }
    }

    render() {
        return (
            <>
                <PlayerPaper>
                    <Breadcrumbs>
                        <Link
                            color="inherit"
                            href="/"
                        >
                            TOP
                    </Link>
                        <Link
                            color="inherit"
                            href={"/user/" + this.props.data.owner}
                        >
                            {this.props.data.owner}のユーザーページ
                    </Link>
                        <Typography>
                            音源ページ
                        </Typography>
                    </Breadcrumbs>
                    <AuthorMenu
                        IsOwner={this.props.data.is_owner}
                        history={this.props.history}
                        id={this.props.data.id}
                        music={this.props.data}
                    />
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={ROOT_URL + "Media/" + this.state.imagePath}
                            alt={this.state.imagePath + "_サムネイル"}
                            className={this.props.classes.thumb}
                            style={this.state.thumbStyle}
                            id="thumb"
                        />
                        <AudioPlayer
                            audioPath={ROOT_URL + "Media/" + this.state.audioPath}
                            loop={this.state.loop}
                            volume={this.state.volume}
                            onVolumeChange={this.onVolumeChange}
                            classes={this.props.classes}
                            url={this.props.data.url}
                        />
                        {this.IsLoggedIn()}
                        <br />
                        <br />
                    </div>
                    <WideAd />
                </PlayerPaper>
            </>
        )
    }
}
