import React from 'react';

//material-ui
import Typography from '@material-ui/core/Typography';

//my component
import UserPlaylistItem from './UserPlaylistItem'



export default class UserPlaylists extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedin: false,
            playlist: []
        }
        this.SetPlaylist()
        this.SetIsLoggedin()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.playlist !== this.props.playlist) {
            this.SetPlaylist()
        }
        if (prevProps.username !== this.props.username) {
            this.SetIsLoggedin()
        }
    }

    SetPlaylist = () => {
        if (this.props.playlist !== undefined) {
            this.setState({ playlist: this.props.playlist })
        }
    }
    SetIsLoggedin = () => {
        const isLoggedin = (this.props.username === this.props.loggedInUser)
        this.setState({ isLoggedin: this.props.IsOwner })

    }
    render() {
        return (
            <>
                <Typography variant="h6" className={this.props.classes.title}>
                    プレイリスト一覧
                </Typography>
                {this.state.playlist.map((play) => (
                    <UserPlaylistItem
                        isLoggedin={this.state.isLoggedin}
                        title={play.name}
                        playListId={play.id}
                        publish={play.publish}
                        history={this.props.history}
                    />
                ))}
            </>
        )
    }
}
