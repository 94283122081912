import React from 'react';

//material-ui
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

//setting

const UserTop = (props) => {
    const OwnerMenu = () => {
        if (props.IsOwner) {
            return (
                <>
                    <Box style={{ width: '100%' }}><Link variant="subtitle1" onClick={(event) => { handleClick(event, "#favoritetag") }}>お気に入りタグ</Link><Divider /></Box>
                    <Box style={{ width: '100%' }}><Link variant="subtitle1" onClick={(event) => { handleClick(event, "#images") }}>投稿した画像一覧</Link><Divider /></Box>
                    <Box style={{ width: '100%' }}><Link variant="subtitle1" onClick={(event) => { handleClick(event, "#account") }}>アカウントメニュー</Link><Divider /></Box>
                </>
            )
        } else {
            return (<></>)
        }
    }
    const handleClick = (event, anchorId) => {
        const anchor = (event.target.ownerDocument || document).querySelector(anchorId);

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <>
            <Paper className={props.classes.userBlock}>
                <Breadcrumbs>
                    <Link
                        color="inherit"
                        href="/"
                    >
                        TOP
                    </Link>
                    <Typography>
                        {props.username}のユーザーページ
                    </Typography>
                </Breadcrumbs>
                <Divider />
                <br />
                <Typography variant="h6" className={props.classes.title}>
                    {props.name}({props.username})
                </Typography>
                <UserTopBaseBox>
                    <Avatar variant="square" src={props.icon} alt={props.username} style={{ width: 240, height: 240}} />
                    <UserTopSubBox>
                        <Box style={{ width: '100%' }}><Link variant="subtitle1" onClick={(event) => { handleClick(event, "#music") }}>投稿した音源</Link><Divider /></Box>
                        <Box style={{ width: '100%' }}><Link variant="subtitle1" onClick={(event) => { handleClick(event, "#playlist") }}>プレイリスト一覧</Link><Divider /></Box>
                        {OwnerMenu()}
                    </UserTopSubBox>
                </UserTopBaseBox>
            </Paper>
        </>
    )
}
export default UserTop

const UserTopBaseBoxStyles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    }
})

const UserTopBaseBox = withStyles(UserTopBaseBoxStyles)(Box);

const UserTopSubBoxStyles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
        alignContent: 'space-around',
        '& > *': {
            margin: theme.spacing(2)
        },
    }
})

const UserTopSubBox = withStyles(UserTopSubBoxStyles)(Box);