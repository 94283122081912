import React from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

//my component
import { useStyles } from '../../style/common'
import MusicList from '../common/MusicList'
import OffsetSelect from './OffsetSelect'

function SearchBody(props) {
    const classes = useStyles()
    const musicOnClick = (musicId) => {
        props.history.push("/music/" + musicId)
    }
    const len = Math.ceil(props.count / 20)
    return (
        <>
            <Paper className={classes.userBlock}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <OffsetSelect
                            classes={classes}
                            onOffsetChange={props.onOffsetChange}
                            offset={props.offset}
                            len={len}
                        />
                    </Grid>
                </Grid>
                <MusicList
                    classes={classes}
                    tileData={props.tileData}
                    musicOnClick={musicOnClick}
                />
                <OffsetSelect
                    classes={classes}
                    onChange={props.onChange}
                    offset={props.offset}
                    len={len}
                />
            </Paper>
        </>
    )

}

export default withRouter(SearchBody)