import React from 'react';

//material-ui

//my component
import { useStyles } from '../../style/player'
import PlayerPaper from './PlayerPaper'
import PlayerArea from './PlayerArea'
import TitleArea from './TitleArea'
import DescriptionArea from './DescriptionArea'
import CounterArea from './CounterArea'
import CreditArea from './CreditArea'
import TagArea from './TagArea'
import ShareArea from './ShareArea'


const Player = (props) => {
    const classes = useStyles()
    return (
        <>
            <div className={classes.listroot}>
                <PlayerArea data={props.data} classes={classes} history={props.history} />
                <PlayerPaper>
                    <TitleArea data={props.data} classes={classes} />
                    <DescriptionArea data={props.data} classes={classes} />
                    <CounterArea data={props.data} />
                    <CreditArea data={props.data} classes={classes} />
                    <TagArea
                        classes={classes}
                        tags={props.tags}
                        tagArea={props.tagArea}
                        lock={props.tag_lock}
                        onTagsEditClick={props.onTagsEditClick}
                        onTagsEditClose={props.onTagsEditClose}
                        onTagsChange={props.onTagsChange}
                        onTagDeleteClick={props.onTagDeleteClick}
                        onTagKeyDown={props.onTagsEditKeyDown}
                        edit={props.tagsEdit}
                    />
                    <ShareArea data={props.data} classes={classes} />
                </PlayerPaper>
            </div>
        </>
    )
}

export default Player