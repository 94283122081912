//material-ui
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';


const RootBoxStyles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    '& > *': {
        padding: theme.spacing(1),
        margin: theme.spacing(1, 1),
        width: theme.spacing(90),
        maxWidth: '95%',
    },
})

const RootBox = withStyles(RootBoxStyles)(Box)

export default RootBox