import React from 'react';


//material-ui
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const TagLockCheck = (props) => {
    return (
        <FormControlLabel
            label="タグロック"
            control={
                <Checkbox
                    name="tag_lock"
                    checked={props.tag_lock}
                />
            }
            onChange={props.handleChange}
        />)
}
export default TagLockCheck