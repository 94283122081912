import React from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';

class ReactHlsAudio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            playerId: Date.now()
        };

        this.hls = null;
        this.audio = React.createRef();
        //this.audio.volume = this.props.audioProps['volume']
    }

    componentDidMount() {
        this._initPlayer();
    }

    componentWillUnmount() {
        if (this.hls) {
            this.hls.destroy();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {
            this._initPlayer();
        }
    }

    _initPlayer() {
        if (this.hls) {
            this.hls.destroy();
        }

        let { url, autoplay, hlsConfig } = this.props;
        let hls = new Hls(hlsConfig);

        hls.attachMedia(this.props.ref.current);
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            hls.loadSource(url);

            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                if (autoplay) {
                    this.props.ref.current.play();
                }
            })
        })

        hls.on(Hls.Events.ERROR, function (event, data) {
            if (data.fatal) {
                switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        hls.startLoad();
                        break;
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        hls.recoverMediaError();
                        break;
                    default:
                        this._initPlayer();
                        break;
                }
            }
        });

        this.hls = hls;
    }

    onEnded = () => {
        if (!this.props.audioProps.loop) {
            this.props.onEnded()
        }
    }

    render() {
        let playerId
        if (this.props.id === "") {
            playerId = this.state.playerId;
        } else {
            playerId = this.props.id
        }
        const { controls, audioProps } = this.props;

        return (
            <div key={playerId} className="playerArea">
                <audio ref={this.props.ref}
                    className={this.props.className}
                    id={"react-hls-" + playerId}
                    controls={controls}
                    onEnded={this.onEnded}
                    onPlay={this.props.onPlay}
                    onLoadedData={this.props.onLoadedData}
                    onLoadedMetadata={this.props.onLoadedMetadata}
                    onVolumeChange={this.props.onVolumeChange}
                    onTimeUpdate={this.props.onTimeUpdate}
                    {...audioProps}></audio>
            </div>
        )
    }
}

ReactHlsAudio.propTypes = {
    url: PropTypes.string.isRequired,
    autoplay: PropTypes.bool,
    hlsConfig: PropTypes.object, //https://github.com/audio-dev/hls.js/blob/master/docs/API.md#fine-tuning
    controls: PropTypes.bool,
    audioProps: PropTypes.object,
    onEnded: PropTypes.func,
    onLoadedData: PropTypes.func,
    onLoadedMetaData: PropTypes.func,
    onTimeUpdate: PropTypes.func,
    onVolumeChange: PropTypes.func,
    id: PropTypes.string
}

ReactHlsAudio.defaultProps = {
    autoplay: false,
    hlsConfig: {},
    controls: true,
    ref: React.createRef(),
    onPlay: () => { },
    onEnded: () => { },
    onLoadedData: () => { },
    onLoadedMetaData: () => { },
    onVolumeChange: () => { },
    onTimeUpdate: () => { },
    id: ""
}

export default ReactHlsAudio;
