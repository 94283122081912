import axios from 'axios';

//setting
import { API_ROOT_URL } from "../../setting"

export function imageDropZoneChange(self, files) {
    if (files.length === 0) {
        return
    }
    const params = new FormData()
    params.append("file_path", files[0])
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.post(API_ROOT_URL + "imagefile/",
        params,
        {
            headers: {
                'content-type': 'multipart/form-data',
            }
        }
    )
        .then(res => {
            self.setState({ imageId: res.data.id,image:res.data })
            self.setState({
                successBarOpen: true,
                successBarText: "画像を投稿しました"
            })
        }).catch(err => {
            self.setState({
                errorBarOpen: true,
                errorBarText:["画像の投稿に失敗しました。"]
            })
        });

}