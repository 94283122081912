import React from 'react';
import axios from 'axios';

//material-ui
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//my component
import MarginedButton from '../common/MarginedButton'
import { DialogTitle, DialogContent } from '../common/DialogParts'
import { DialogOpen, DialogClose } from '../../action/common/DialogAction'


//setting
import { API_ROOT_URL } from "../../setting"

export default class ReportDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            name: "",
            eMail: "",
            url: "",
            musicId: this.props.musicId,
            barOpen: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.musicId !== prevProps.musicId) {
            this.setState({
                musicId: this.props.musicId,
            })
        }
    }
    OnChangeText = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }


    barOpen = () => {
        this.setState({ barOpen: true })
    }
    barClose = () => {
        this.setState({ barOpen: false })
    }

    OnSubmitClick = () => {
        const params = new FormData()
        params.append("name", this.state.name)
        params.append("e_mail", this.state.eMail)
        params.append("music", this.state.musicId)
        params.append("url", this.state.url)
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.post(API_ROOT_URL + "report/",
            params
        ).then(res => {
            this.barOpen()
            DialogClose(this)
        }).catch(err => {
            /*todo エラー内容読み込んでそれぞれの内容でstate.formsを更新する*/
        });

    }

    render() {
        return (
            <>
                <MarginedButton onClick={() => DialogOpen(this)}>
                    権利侵害の報告
                </MarginedButton>
                <Dialog onClose={() => DialogClose(this)} ari-labelledby="customized-dialog-title" open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={() => DialogClose(this)}>
                        権利侵害の報告
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variang="body2">
                            違反性のある投稿により大変ご迷惑をおかけしております。<br /><br />
                            対処内容については、後日運営より連絡いたします。<br />
                            連絡可能なメールアドレスをご入力ください。<br /><br />
                            なお、権利者さま以外からのお問い合わせには対応いたしかねます。<br /><br />
                        </Typography>
                        <TextField
                            id="standard-basic"
                            label="権利者名義"
                            name="name"
                            helperText="15文字以内"
                            required
                            fullWidth
                            inputProps={{ maxLength: 15 }}
                            onChange={this.OnChangeText}
                        />
                        <TextField
                            id="standard-basic"
                            label="メールアドレス"
                            name="eMail"
                            helperText="有効なメールアドレス"
                            required
                            fullWidth
                            onChange={this.OnChangeText}
                        />
                        <TextField
                            id="standard-basic"
                            label="原作url"
                            name="url"
                            helperText="任意 2048文字以内"
                            fullWidth
                            inputProps={{ maxLength: 2048 }}
                            onChange={this.OnChangeText}
                        />
                        <br />
                        <MarginedButton
                            variant="outlined"
                            fullWidth
                            onClick={this.OnSubmitClick}
                        >
                            送信
                        </MarginedButton>
                        <br />
                    </DialogContent>
                </Dialog>
                <Snackbar open={this.state.barOpen} autoHideDuration={6000} onClose={this.barClose}>
                    <MuiAlert elevation={6} severity="success">報告完了しました</MuiAlert>
                </Snackbar>
            </>
        )
    }
}
