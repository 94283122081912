import React from 'react';

//material-ui
import TextField from '@material-ui/core/TextField';

const TagEditTextField = (props) => {
    return (
        <>
            <TextField
                id="standard-basic"
                label="タグ"
                name="tag"
                helperText="検索に使用します(完全一致) 1タグあたり15文字 半角スペース区切り"
                fullWidth
                inputProps={{ maxLength: 1024 }}
                value={props.value}
                onChange={props.onTagsChange}
                onKeyDown={props.onTagKeyDown}
            />
        </>
    )
}

export default TagEditTextField