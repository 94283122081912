import React from 'react';
import { useEffect } from 'react';
import { useStyles } from '../../style/common'

export default function WidePlusAd(props) {
    const classes = useStyles();
    useEffect(() => {
        console.log(window.adsbygoogle)
        if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
            window.adsbygoogle.push({});
        }
    }, [])
    return (
        <ins className={"adsbygoogle" + ' ' + classes.userBlock}
            style={{
                "height": "240px",
                "maxWidth":"90%",
            }}
            data-ad-client="ca-pub-9233272768468620"
            data-ad-slot="1641872318">
        </ins>
    )
}