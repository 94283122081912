import axios from 'axios';

//setting
import { API_ROOT_URL } from "../../setting"


export async function TagPost(value, id, api = "tagmusic") {
    let params
    let apiPath
    params = new FormData()
    params.append("value", value)
    params.append("tag", value)
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    if (id === "") {
        apiPath = API_ROOT_URL + api + "/"
    } else {
        apiPath = API_ROOT_URL + api + "/" + id + "/"
    }
    return await (axios.post(apiPath,
        params
    ))
}

export function TagsDeleteClick(event, self, tags, api = "tag") {
    const temp = event.target.id.replace("tag_", "")
    const data = temp.split(" ")
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.delete(API_ROOT_URL + api + "/" + data[0] + "/", {})
    const newTags = tags.filter(tag => tag.value !== data[1])
    self.setState(
        {
            tags: newTags,
        }
    )
}

export function onTagsEditClick(self) {
    self.setState({ tagsEdit: true })
}

export function onTagsEditClose(self) {
    self.setState({ tagsEdit: false })
}

export function TagsUpdate(self, tags, areaValue) {
    console.log(tags)
    console.log(areaValue)
    self.setState(
        {
            tags: tags,
            tagArea: areaValue,
        }
    )
}

export function onTagsChange(event, self, tags, id, api = "tagmusic") {
    let temp = event.target.value
    let temps = []
    let newTags = []
    let tagValues = []
    if (tags.length !== 0) {
        newTags = tags.slice(0, tags.length)
    }
    for (let x of newTags) {
        tagValues.push(x.value)
    }
    if (temp.match(" ")) {
        temps = temp.split(" ")
        temp = temps[temps.length - 1]
        for (let value of temps) {
            if ((value !== "") && (tagValues.indexOf(value) < 0)) {
                TagPost(value.slice(0, 32), id, api).then((res) => {
                    tags.push(res.data)
                    TagsUpdate(self, tags, temp)
                })
            }
        }
    } else if (temp.match("　")) {
        temps = temp.split("　")
        temp = temps[temps.length - 1]
        for (let value of temps) {
            if ((value !== "") && (tagValues.indexOf(value) < 0)) {
                TagPost(value.slice(0, 32), id, api).then((res) => {
                    tags.push(res.data)
                    TagsUpdate(self, tags, temp)
                })
            }
        }
    } else if (temp.length === 33) {
        if ((tagValues.indexOf(temp))) {
            TagPost(temp.slice(0, 32), id, api).then((res) => {
                tags.push(res.data)
                TagsUpdate(self, tags, "")
            })
        }
    } else {
        self.setState({
            tagArea: temp,
        })
    }
}


export function onTagsEditKeyDown(self, event, id, api = "tagmusic") {
    if (event.key === 'Enter') {
        let temp = event.target.value
        let tags = self.state.tags
        let newTags = []
        let tagValues = []
        if (tags.length !== 0) {
            newTags = tags.slice(0, tags.length)
        }
        for (let x of newTags) {
            tagValues.push(x.value)
        }
        if (self.state.tags.length !== 0) {
            tags = self.state.tags.slice(0, self.state.tags.length)
        }
        if ((temp !== "") && (tagValues.indexOf(temp) < 0)) {
            TagPost(temp.slice(0, 32), id, api).then((res) => {
                tags.push(res.data)
                temp = ""
                TagsUpdate(self, tags, temp)
            })
        }
    } else if ((event.key === "Backspace") && (event.target.value === "") && (self.state.tags.length !== 0)) {
        console.log(self.state.tags.slice(-1)[0].id)
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.delete(API_ROOT_URL + "tag/" + self.state.tags.slice(-1)[0].id + "/", {})
        const newTags = self.state.tags.slice(0, self.state.tags.length - 1)
        self.setState(
            {
                tags: newTags,
            }
        )
    }
}

export async function onTagAdd(self, value, id, api = "tagmusic") {
    let tags = self.state.tags
    let newTags = []
    let tagValues = []
    if (tags.length !== 0) {
        newTags = tags.slice(0, tags.length)
    }
    for (let x of newTags) {
        tagValues.push(x.value)
    }
    if (self.state.tags.length !== 0) {
        tags = self.state.tags.slice(0, self.state.tags.length)
    }
    if ((value !== "") && (tagValues.indexOf(value) < 0)) {
        await (TagPost(value.slice(0, 32), id, api)).then((res) => {
            tags.push(res.data)
            value = ""
            TagsUpdate(self, tags, value)
        })
    }

}

export async function onTagAdds(self, values, id, api = "tagmusic") {
    for (let value of values) {
        if (value.name !== "") {
            await onTagAdd(self, value.name, id, api)
        }
    }
}