import React from 'react';

//material-ui
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//setting
import { ROOT_URL, SITE_NAME, IS_ALPHA, LOGIN_URL } from "../../setting"

export default class Top extends React.Component {
    constructor(props) {
        super(props);
        let buttonValue
        let buttonOnClick
        let url
        if (this.props.isLoggedin) {
            buttonValue = "音楽を投稿する"
            buttonOnClick = () => { this.IsLoggedInAction() }
            url = "/upload"
        } else if (IS_ALPHA) {
            buttonValue = "会員登録はβ版から"
            buttonOnClick = () => { }
            url = ""
        } else {
            buttonValue = "ログイン"
            buttonOnClick = () => { this.IsLoggedOutAction() }
            url = LOGIN_URL
        }

        this.state = {
            buttonValue: buttonValue,
            buttonOnClick: buttonOnClick,
            url: url
        }
    }

    IsLoggedInAction = () => {
        this.props.uploadOnClick()
    }

    IsLoggedOutAction = () => {
        document.location.href = LOGIN_URL
    }

    render() {
        return (
            <>
                <Typography variant="h6" className={this.props.classes.title}>
                    {SITE_NAME}は、twitter連携の同人音楽投稿サービスです。
                </Typography>
                <Button
                    variant="contained"
                    className={this.props.classes.button}
                    href={this.state.url}
                    size="large"
                >
                    {this.state.buttonValue}
                </Button>
                <Typography variant="h6" className={this.props.classes.title}>
                    アプリ版は廃止しました……<br />
                    <Typography variant="body2">
                        既にダウンロードされている方は引き続き利用できますが、今後保守されません。
                    </Typography>
                </Typography>
                <Typography variant="h6" className={this.props.classes.title}>
                    アプリ版相当の機能実装を目指すUTALODER(v2)が開発中です。<br />
                    <Button
                        variant="contained"
                        className={this.props.classes.button}
                        href={'https://utaloader.net/v2/'}
                        size="large"
                    >
                        UTALOADER(v2)
                    </Button>

                    <Button
                        variant="contained"
                        className={this.props.classes.button}
                        onClick={() => { localStorage.setItem('redirectV2', true); document.location.href = ROOT_URL + "v2/" }}
                        size="large"
                    >
                        v2優先設定をする
                    </Button>
                    <Typography variant="body2">
                        開発中です。不具合報告はサポートdiscordまで
                    </Typography>
                </Typography>
            </>
        );
    }
}

