
import React from 'react';
import axios from 'axios';

//material-ui
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//my component
import MarginedButton from '../common/MarginedButton'
import { DialogTitle, DialogContent } from '../common/DialogParts'
import { DialogOpen, DialogClose } from '../../action/common/DialogAction'


//setting
import { ROOT_URL, API_ROOT_URL } from "../../setting"



export default class PasswordDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            successBar: false,
            errorBar: false,
            errorText: <></>,
            successText: <></>,
            old_password: "",
            password1: "",
            password2: "",
            password1Error: false,
            password2Error: false,
        }
    }

    async OnSubmitClick() {
        const params = new FormData()
        let action=""
        if (this.state.old_password === "" && this.props.hasPassword) {
            this.setState({
                errorBar: true,
                errorText: <>現在のパスワードは空にできません。</>,
            })
        } else if (this.state.password1Error) {
            this.setState({
                errorBar: true,
                errorText: <>新しいパスワードが短すぎます。</>,
            })
        } else if (this.state.password2Error) {
            this.setState({
                errorBar: true,
                errorText: <>確認用パスワードが一致しません。</>,
            })
        }else {
            params.append("password1", this.state.password1)
            params.append("password2", this.state.password2)
            if (this.props.hasPassword) {
                params.append("oldpassword", this.state.old_password)
                params.append("password_change", "")
                action = "change"
            } else {
                params.append("password_set", "")
                action = "set"
            }
            axios.defaults.xsrfCookieName = 'csrftoken'
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            await (axios.post(API_ROOT_URL + "accounts/password/"+action+"/", params)).then((res) => {
                if (res.data.match("このパスワードは一般的すぎます")) {
                    this.setState({
                        password1Error: true,
                        password2Error: true,
                        errorBarOpen: true,
                        errorBarText: "このパスワードは一般的すぎます",
                    })

                } else if (res.data.match("現在のパスワードを入力してください")) {
                    this.setState({
                        errorBarOpen: true,
                        errorBarText: "現在のパスワードが一致しません",
                    })

                }  else {
                    this.setState({
                        open: false,
                        successBar: true,
                        successText: <>パスワードを登録しました</>,
                    })
                }
            }).catch(error => {
                this.setState({
                    errorBarOpen: true,
                    errorBarText: "パスワードの登録に失敗しました",
                })
            })
        }
    }
    async OnResetClick() {
        await (axios.get(API_ROOT_URL + "email/" + this.props.username + "/", {}))
            .then(res => {
                let email = ""
                res.data.forEach(e => {
                    if (e.primary) {
                        email=e.email
                    }
                })
                if (email === "") {
                    this.setState({
                        errorBarOpen: true,
                        errorBarText: "メールアドレスが正しく設定されていません",
                    })
                } else {
                    this.SendResetMail(email)
                }
            }).catch(error => {
                this.setState({
                    errorBarOpen: true,
                    errorBarText: "メールアドレスの取得に失敗しました",
                })
            })
    }

    async SendResetMail(email) {
        const params = new FormData()
        params.append("email", email)
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        await (axios.post(API_ROOT_URL + "accounts/password/reset/", params)).then((res) => {
            this.setState({
                open: false,
                successBar: true,
                successText: <>パスワード再設定用のメールを送信しました。</>,
            })
        }).catch(error => {
            this.setState({
                errorBarOpen: true,
                errorBarText: "パスワード再設定用のメールの送信に失敗しました。",
            })
        })
    }


    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
        if (event.target.name === "password1" && event.target.value.length < 8) {
            this.setState({ "password1Error": true })
        } else if (event.target.name === "password1") {
            this.setState({ "password1Error": false })
        }else if (event.target.name === "password2" && event.target.value !== this.state.password1) {
            this.setState({ "password2Error": true })
        } else if (event.target.name === "password2") {
            this.setState({ "password2Error": false })
        }
    }

    DialogOpen = () => {
        this.setState({ open: true })
    }


    render() {
        return (
            <DeleteDialogBox>
                <MarginedButton onClick={() => this.DialogOpen()} variant="contained" disabled={!this.props.hasEmail}>
                    パスワードの設定 {!this.props.hasPassword && <>(未設定)</>}  {!this.props.hasEmail && <><br />パスワードを設定するためにはメールアドレスの登録が必要です。</>}
                </MarginedButton>
                <Dialog onClose={() => DialogClose(this)} ari-labelledby="customized-dialog-title" open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={() => DialogClose(this)}>
                        パスワードの設定
                    </DialogTitle>
                    <DialogContent dividers>
                        {this.props.hasPassword && <>
                            <TextField
                                label="現在のパスワード"
                                name="old_password"
                                value={this.state.new_password1}
                                onChange={this.onChange}
                                fullWidth
                                required
                                type="password"
                                inputProps={{ maxLength: 100 }}
                            />
                        </>}
                        <TextField
                            label="新しいパスワード(8文字以上)"
                            name="password1"
                            value={this.state.password1}
                            onChange={this.onChange}
                            fullWidth
                            required
                            inputProps={{ maxLength: 100 }}
                            type="password"
                            error={this.state.password1Error}
                        />
                        <TextField
                            label="新しいパスワード(確認)"
                            name="password2"
                            value={this.state.password2}
                            onChange={this.onChange}
                            fullWidth
                            required
                            inputProps={{ maxLength: 100 }}
                            type="password"
                            error={this.state.password2Error}
                        />
                        <MarginedButton onClick={() => this.OnSubmitClick()} variant="contained" disabled={this.state.password1Error || this.state.password2Error}>
                            パスワードの設定
                        </MarginedButton>
                        {this.props.hasPassword &&<>
                        <Divider />
                            <MarginedButton onClick={() => this.OnResetClick()} size="small">
                            パスワードを忘れた場合こちら
                        </MarginedButton>
                        </>}
                    </DialogContent>
                </Dialog>
                <Snackbar open={this.state.successBar} autoHideDuration={6000} onClose={() => { window.location.reload() }}>
                    <MuiAlert elevation={6} severity="success">{this.state.successText}</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.errorBar} autoHideDuration={6000} onClose={() => { this.setState({ errorBar: false }) }}>
                    <MuiAlert elevation={6} severity="error">{this.state.errorText}</MuiAlert>
                </Snackbar>
            </DeleteDialogBox>
        )
    }
}

const DeleteDialogBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 1, 1, 2),
        padding: theme.spacing(1),
    }
})

const DeleteDialogBox = withStyles(DeleteDialogBoxStyles)(Box);