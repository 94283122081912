import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";


//my component
import { useStyles } from '../style/common'
import Box from '@material-ui/core/Box';
import RootBox from './common/RootBox'
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import RuleTabs from './RuleTabs'
//setting
import {  SITE_TITLE } from "../setting"

const Rule = (props) => {
    const classes = useStyles()


    return (
        <RootBox>
            <Helmet>
                <title>{SITE_TITLE} -利用規約-</title>
            </Helmet>
            <Paper className={classes.userBlock} id="music">
                <Breadcrumbs>
                    <Link
                        color="inherit"
                        onClick={() => { props.history.push("/") }}
                    >
                        TOP
                    </Link>
                    <Typography>
                        利用規約
                    </Typography>
                </Breadcrumbs>
                <Divider />
                <RuleTabs
                    value={0}
                />
                <RuleBox>
                    <Typography variant="subtitle1">
                        本利用規約は、きみがため(以下「運営団体」といいます。)が提供する「Utaloader」(以下「本サービス」といいます。)において、運営団体と本サービスを利用する利用者(以下「利用者」といいます。)の間で合意されるものです。
                    </Typography>
                    <br />
                    <Typography variant="h6">
                        第1条 定義
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            本利用規約では、次の用語を使用します。
                            <ol>
                                <li>「投稿コンテンツ」とは、利用者が本サービスに投稿、送信、アップロードした文条、音声、画像、その他の情報のことをいいます。</li>
                                <li>「作品」とは、投稿コンテンツのうち、投稿された音声、画像のことをいいます。</li>
                                <li>「投稿者」とは、作品を投稿した利用者のことをいいます。</li>
                                <li>「規約とポリシー」とは、本サービスにおいて、本規約を含む「規約」「ポリシー」「表記」「ガイドライン」の名称で、運営団体が作成し直接掲示しているすべての文条のことを言います。</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第2条 同意
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ol>
                                <li>利用者は、規約とポリシーに対して有効かつ取消不能な同意をしない限り、本サービスを利用できません。</li>
                                <li>利用者が本サービスを事業者のために利用する場合は、当該事業者も規約とポリシーに同意しない限り、本サービスを利用できません。</li>
                                <li>利用者は、本サービスを実際に利用することによって、規約とポリシーに有効かつ取消不能な同意をしたものとみなされます。</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第3条 本サービスの提供
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ol>
                                <li>利用者は、本サービスを利用するにあたり、必要なパーソナルコンピュータ、携帯電話機、通信機器、オペレーションシステム、通信手段および電力などを、利用者の費用と責任で用意しなければなりません。</li>
                                <li>運営団体は、本サービスの全部または一部を、年齢、本人確認の有無、登録情報の有無、その他、運営団体が必要と判断する条件を満たした利用者に限定して提供することができるものとします。</li>
                                <li>運営団体は、運営団体が必要と判断する場合、あらかじめ利用者に通知することなく、いつでも、本サービスの全部または一部の内容を変更し、また、その提供を中止することができるものとします。</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第4条 アカウント
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ol>
                                <li>利用者は、利用者のアカウントが不正に利用されないよう自身の責任で厳重に管理しなければなりません。運営団体は、利用者のアカウントを利用して行われた一切の行為を、利用者本人の行為とみなすことができます。</li>
                                <li>利用者は、いつでも利用者のアカウントを削除して退会することができます。</li>
                                <li>運営団体は、利用者が規約とポリシーに違反または違反するおそれがあると認めた場合、あらかじめ利用者に通知することなく、アカウントを停止、削除または変更等の措置を行うことができるものとし、これらの措置を講じた理由を開示する義務を負うものではありません。</li>
                                <li>利用者の本サービスにおけるすべての利用権は、理由を問わず、アカウントが削除された時点で消滅します。利用者が誤ってアカウントを削除した場合であっても、アカウントの復旧はできません。</li>
                                <li>利用者のアカウントは、利用者に一身専属的に帰属します。利用者の本サービスにおけるすべての利用権は、第三者に譲渡、貸与または相続させることはできません。</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第5条 広告表示
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営団体は、本サービスに運営団体または第三者の広告を掲載することができるものとします。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第6条 知的財産権の帰属及び使用許諾
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ol>
                                <li>作品の著作権その他一切の権利は、投稿者に帰属します。</li>
                                <li>運営団体および運営団体が個別に許可した事業者は、投稿コンテンツに関して、本サービスの役務の提供、利用促進、改良、メンテナンス、運営団体または本サービスの広告、宣伝に必要な範囲内で、無償かつ非独占的に使用および改変等をすることができるものとし、利用者はこれを許諾するものとします。</li>
                                <li>運営団体および運営団体が個別に許可した事業者は、前項に定める形で投稿コンテンツの情報を使用するにあたっては、情報の一部または氏名表示を省略することができるものとします。</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第7条 禁止行為
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            利用者は、本サービスの利用にあたり、次の行為を行なってはなりません。
                            <ol>
                                <li>第三者の著作物を二次利用する場合において、権利者利益を不当に害する行為</li>
                                <li>商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上または契約上の権利を侵害する行為</li>
                                <li>法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為</li>
                                <li>公の秩序または善良の風俗を害するおそれのある行為</li>
                                <li>過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、投稿または送信する行為</li>
                                <li>運営団体または第三者になりすます行為</li>
                                <li>意図的に虚偽の情報を流布させる行為</li>
                                <li>本サービスのアカウントを第三者に譲渡または貸与する行為</li>
                                <li>運営団体が認めていない営業、宣伝、広告、勧誘、その他営利を目的とする行為</li>
                                <li>作品を、投稿者の許可なく個人の私的利用を超えて使用する行為</li>
                                <li>面識のない人との出会いや交際を目的とする行為</li>
                                <li>他の利用者に対する嫌がらせや誹謗中傷を目的とする行為</li>
                                <li>反社会的勢力に対する利益供与その他の協力行為</li>
                                <li>宗教活動または宗教団体への勧誘行為</li>
                                <li>他人の個人情報、登録情報、利用履歴情報などを、不正に収集、開示または提供する行為</li>
                                <li>本サービスのサーバやネットワークシステムに支障を与える行為</li>
                                <li>本サービスを介さず、APIサーバにアクセスする行為</li>
                                <li>BOT、スクレイピング、その他の技術的手段を利用してサービスを不正に操作する行為</li>
                                <li>本サービスの不具合を意図的に利用する行為</li>
                                <li>同様の質問を必要以上に繰り返す等、運営団体に対し不当な問い合わせまたは要求をする行為</li>
                                <li>本サービスの運営または他の利用者による本サービスの利用を妨害し、これらに支障を与える行為</li>
                                <li>運営団体が委託する事業者の利用規約に違反する行為</li>
                                <li>その他、運営団体が不適当と判断した行為</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第8条 投稿コンテンツの非表示、削除
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営団体は、次の場合には、投稿コンテンツの非表示、または削除等の措置を行うことができるものとし、これらの措置を講じた理由を開示する義務を負うものではありません。
                            <ol>
                                <li>運営団体が、投稿コンテンツが利用規約に違反する恐れがあると判断した場合</li>
                                <li>運営団体が、投稿コンテンツが第三者の権利者利益を侵害していると判断した場合</li>
                                <li>第三者が、運営団体へ権利主張の申し立てを行い、運営団体が、その主張を妥当であると判断した場合</li>
                                <li>公的な機関、特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律のガイドラインに規定された信頼性確認団体、インターネット・ホットライン、弁護士等から、公序良俗違反または第三者の権利を侵害する等の指摘があった場合</li>
                                <li>その他、規約とポリシーに違反しているかに関わらず、運営団体が本サービスの運営において、不適切であると判断した場合</li>
                                <li>第三者の著作物を二次利用する場合において、投稿から一定の期間のうちに、適切な権利者表示を行わなかった場合</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第9条 利用者の責任
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ol>
                                <li>利用者は、運営団体に対して、投稿コンテンツが著作権法を含む法令および規約とポリシーに違反していないことを保証するものとします。</li>
                                <li>利用者は、利用者の責任において本サービスを利用するものとし、本サービスにおいて行った一切の行為およびその結果について一切の責任を負うものとします。</li>
                                <li>運営団体は、利用者が本規約に違反して本サービスを利用していると認めた場合、運営団体が必要かつ適切と判断する措置を講じます。ただし、運営団体は、かかる違反を防止または是正する義務を負いません。</li>
                                <li>利用者は、本サービスを利用したことに起因して、運営団体が直接的もしくは間接的に何らかの損害を被った場合、運営団体の請求にしたがって直ちにこれを補償しなければなりません。</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第10条 運営団体の免責
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ol>
                                <li>運営団体は、本サービスに事実上または法律上の瑕疵がないことを明示的にも黙示的にも保証しません。運営団体は、利用者に対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。</li>
                                <li>運営団体は、本サービスに起因して利用者に生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する運営団体と利用者との間の契約が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。</li>
                                <li>運営団体は、運営団体の過失による債務不履行または不法行為により利用者に生じた損害のうち、天災や事故など特別な事情から生じた損害について一切の責任を負いません。</li>
                            </ol>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第11条 プライバシー
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営団体は、利用者の個人情報をプライバシーポリシーに基づき適切に取り扱うものとします。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第12条 準拠法及び管轄裁判所
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            本利用規約の準拠法は日本法とし、運営団体と利用者の間で訴訟の必要が生じた場合、大阪地方裁判所を第一審の専属的合意管轄裁判所とします。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第13条 改定
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営団体は、本利用規約について、事前に利用者へ適切な周知を行うことで、利用者の同意を得ず、改定できるものとします。
                        </Typography>
                    </RuleSubBox>
                    <RuleDateBox>
                        <Typography variant="body2">
                            制定：2020年7月23日
                        </Typography>
                    </RuleDateBox>
                </RuleBox>
            </Paper>
        </RootBox>
    )

}
export default withRouter(Rule)

const RuleBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(2),
    }
})

const RuleBox = withStyles(RuleBoxStyles)(Box);

const RuleSubBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 2, 4),
    }
})

const RuleSubBox = withStyles(RuleSubBoxStyles)(Box);


const RuleDateBoxStyles = (theme) => ({
    root: {
        textAlign: 'right',
    }
})

const RuleDateBox = withStyles(RuleDateBoxStyles)(Box);