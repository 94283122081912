import React from 'react';
import axios from 'axios'
import PropTypes from 'prop-types';

//material-ui
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';


//setting
import { API_ROOT_URL, ROOT_URL } from "../../setting"

export default class ImagePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageId: this.props.imageId,
            view:
                <>
                    <img
                        src={ROOT_URL + "Media/img/" + this.props.imageId + ".png?" + 0}
                        style={{ maxWidth: '100%', }}
                    />
                </>,
            disable: false,
            count: 0,
        };
    }
    async OnIconClick(event, value) {
        let url
        if (value === "l") {
            url = API_ROOT_URL + "rotimagel/" + this.props.imageId + "/"
        } else {
            url = API_ROOT_URL + "rotimager/" + this.props.imageId + "/"
        }
        this.setState({
            disable: true,
            view:
                <>
                    <Box style={{
                        width: 1024, height: 1024, maxWidth: '100%', objectFit: 'contain'
                    }}
                    >
                        <CircularProgress size={200} />
                    </Box>
                </>

        })
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        await (axios.put(url, {})).then(() => {
            this.setState({
                disable: false,
                view:
                    <>
                        <img
                            src={ROOT_URL + "Media/img/" + this.props.imageId + ".png?" + this.state.count + 1}
                            style={{ maxWidth: '100%', }}
                        />
                    </>,
                count: this.state.count + 1,
            })
        }).catch(() => {
            this.setState({
                disable: false,
                view:
                    <>
                        <img
                            src={ROOT_URL + "Media/img/" + this.props.imageId + ".png?" + this.state.count}
                            style={{ maxWidth: '100%', }}
                        />
                    </>
            })
        })
    }



    render() {
        return (
            <>
                <ImagePreviewBox>
                    {this.state.view}
                    <br />
                    {this.props.rotatable && <>
                        <Tooltip title="左に90度回転する">
                            <IconButton
                                disabled={this.state.disable}
                                onClick={(event) => { this.OnIconClick(event, "l") }}
                            >
                                <RotateLeftIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="右に90度回転する">
                            <IconButton
                                disabled={this.state.disable}
                                onClick={(event) => { this.OnIconClick(event, "r") }}
                            >
                                <RotateRightIcon />
                            </IconButton>
                        </Tooltip>
                    </>}
                </ImagePreviewBox>
            </>
        )
    }
}

ImagePreview.propTypes = {
    imageId: PropTypes.number.isRequired
}

const ImagePreviewBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 1),
        padding: theme.spacing(1),
        textAlign: 'center',
        rotatable: true,
    }
})

const ImagePreviewBox = withStyles(ImagePreviewBoxStyles)(Box);