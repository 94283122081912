import React from 'react';
import axios from 'axios';

//my component
import CreditFieldItem from '../../view/uploader/CreditFieldItem'

//setting
import { API_ROOT_URL } from "../../setting"
export function onCreditsAddCircleClick(self) {
    let items = self.state.creditItems.slice(0, self.state.creditsCount)
    let values = self.state.credits.slice(0, self.state.creditsCount)
    const newItem = {
        id: 'None',
        type: 'None',
        name: ''
    }
    items.push(<CreditFieldItem num={self.state.creditsCount} onTypeChange={self.onCreditsTypeChange} onNameChange={self.onCreditsNameChange} value={newItem} classes={self.props.classes}/>)
    values.push(newItem)
    self.setState({
        creditItems: items,
        creditsCount: self.state.creditsCount + 1,
        credits: values,
    });
}
export function onCreditsRemoveCircleClick(self) {
    if (self.state.creditsCount <= 1) {
        return
    }
    if (self.state.credits[self.state.creditsCount - 1].id !== 'None') {

        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.delete(API_ROOT_URL + "credit/" + self.state.credits[self.state.creditsCount - 1].id + "/",
            {}
        )

    }
    let items = self.state.creditItems.slice(0, self.state.creditsCount - 1)
    let values = self.state.credits.slice(0, self.state.creditsCount - 1)
    self.setState({
        creditItems: items,
        creditsCount: self.state.creditsCount - 1,
        credits: values,
    });
}

export function onCreditsTypeChange(self, event) {
    let items = self.state.credits.slice(0, self.state.creditsCount)
    const id = Number(event.target.name.replace("credit_type_", ""))
    items[id].type = event.target.value
    self.setState({
        credits: items
    })
}

export function onCreditsNameChange(self, event) {
    let items = self.state.credits.slice(0, self.state.creditsCount)
    const id = Number(event.target.name.replace("credit_name_", ""))
    items[id].name = event.target.value
    self.setState({
        credits: items
    })
}