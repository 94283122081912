import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

//material-ui
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//my component
import { useStyles } from '../style/common'
import Typography from '@material-ui/core/Typography';
import UserTop from './user/UserTop'
import UserPlaylists from './user/UserPlaylists'
import MyMusicList from './user/MyMusicList'
import RootBox from './common/RootBox'
import Box from '@material-ui/core/Box';
import TagArea from './player/TagArea'
import DeleteDialog from './user/DeleteDialog'
import NameDialog from './user/NameDialog'
import WidePlusAd from './common/WidePlusAd'


//action
import {
    onTagsEditClick,
    onTagsEditClose,
    TagsDeleteClick,
    onTagsChange,
    onTagsEditKeyDown,
} from '../action/player/TagEditAction'


//setting
import { API_ROOT_URL, LIMIT_DATE, SITE_TITLE } from '../setting'
import UserImages from './user/UserImages';
import EmailDialog from './user/EmailDialog';
import PasswordDialog from './user/PasswordDialog';

const UserPage = (props) => {
    const classes = useStyles()
    const { params } = props.match
    const loggedInUser = document.getElementById('username').innerText
    return (
        <>
            <RootBox>
                <UserPageBase
                    classes={classes}
                    username={params.username}
                    history={props.history}
                    loggedInUser={loggedInUser}
                />
            </RootBox>
        </>
    )
}
export default withRouter(UserPage)

class UserPageBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            icon: {},
            tags: [],
            tagArea: "",
            tagsEdit: false,
            tileData: [],
            errorBarOpen: false,
            errorBarText: ""
        }
        this.GetUser()
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.username !== prevProps.username) {
            this.GetUser()
        }
    }

    async GetUser() {
        await (axios.get(API_ROOT_URL + "user/" + this.props.username + "/", {}))
            .then(res => {
                const tileData = this.GetMusicList(res.data)
                this.setState({
                    user: res.data,
                    tags: res.data.favorite_tag,
                    tileData: tileData
                })
                this.GetIcon()
            }).catch(error => {
                this.setState({
                    errorBarOpen: true,
                    errorBarText: "このユーザーは存在しません",
                })
            })
    }
    async GetIcon() {
        await (axios.get(API_ROOT_URL + "icon/" + this.props.username + "/", {}))
            .then(res => {
                const icon = res.data.icon.replace("_normal", "")
                this.setState({ icon: icon })
                console.log(icon)
            }).catch(error => {
                //this.setState({
                //    errorBarOpen: true,
                //    errorBarText: "アイコンの読込に失敗しました",
                //})
            })
    }

    GetMusicList = (data) => {
        let list = data.music
        list.sort((a, b) => {
            if (a.upload_date < b.upload_date) return 1;
            if (a.upload_date > b.upload_date) return -1;
            return 0;
        })
        if (data.is_owner) {
            return (list)
        }
        const newList = list.filter(music => this.IsMusicPublish(music))
        return newList
    }


    onTagsEditClick = () => {
        onTagsEditClick(this)
    }
    onTagsEditClose = () => {
        onTagsEditClose(this)
    }

    onTagsChange = (event) => {
        onTagsChange(event, this, this.state.tags, "", "favoritetag")
    }

    onTagsEditKeyDown = (event) => {
        onTagsEditKeyDown(this, event, "", "favoritetag")
    }



    onTagDeleteClick = (event) => {
        TagsDeleteClick(event, this, this.state.tags, "favoritetag")
    }

    musicOnClick = (musicId) => {
        this.props.history.push("/music/" + musicId)
    }

    errorBarClose = () => {
        this.setState({ errorBarOpen: false })
        this.props.history.push("/")
    }


    IsMusicPublish = (music) => {
        if (music.original) {
            return (music.publish && !music.private)
        } else if (music.credit_count > 0) {
            return (music.publish && !music.private)
        }
        const limitDate = new Date(
            music.upload_date.slice(0, 4),
            parseInt(music.upload_date.slice(5, 7)) - 1,
            parseInt(music.upload_date.slice(8, 10)) + LIMIT_DATE,
            music.upload_date.slice(11, 13),
            music.upload_date.slice(14, 16),
            music.upload_date.slice(17, 19),
        )
        if (limitDate.getTime() > Date.now()) {
            return (music.publish && !music.private)
        } else {
            return (false)
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{SITE_TITLE} -{this.state.user.username}のユーザーページ-</title>
                </Helmet>
                <UserTop
                    classes={this.props.classes}
                    icon={this.state.icon}
                    username={this.state.user.username}
                    name={this.state.user.first_name}
                    history={this.props.history}
                    IsOwner={this.state.user.is_owner}
                />
                <Paper className={this.props.classes.userBlock} id="music">
                    <MyMusicList
                        title="投稿した音源"
                        classes={this.props.classes}
                        musicOnClick={this.musicOnClick}
                        tileData={this.state.tileData}
                    />
                </Paper>
                <WidePlusAd />
                <Paper className={this.props.classes.userBlock} id="playlist">
                    <UserPlaylists
                        classes={this.props.classes}
                        username={this.state.user.username}
                        playlist={this.state.user.playlist}
                        loggedInUser={this.props.loggedInUser}
                        IsOwner={this.state.user.is_owner}
                        history={this.props.history}
                    />
                </Paper>
                {this.state.user.is_owner && <Paper className={this.props.classes.userBlock} id="favoritetag">
                    <TagArea
                        classes={this.props.classes}
                        tags={this.state.tags}
                        tagArea={this.state.tagArea}
                        title="お気に入りタグ"
                        lock={false}
                        onTagsEditClick={this.onTagsEditClick}
                        onTagsEditClose={this.onTagsEditClose}
                        onTagsChange={this.onTagsChange}
                        onTagDeleteClick={this.onTagDeleteClick}
                        onTagKeyDown={this.onTagsEditKeyDown}
                        edit={this.state.tagsEdit}
                    />
                </Paper>}
                {this.state.user.is_owner && < Paper className={this.props.classes.userBlock} id="images">
                    <UserImages
                        user={this.state.user}
                        classes={this.props.classes}
                    />
                </Paper>}
                {this.state.user.is_owner && <Paper className={this.props.classes.userBlock} id="account">
                    <Typography variant="h6" className={this.props.classes.title}>
                        アカウントメニュー
                    </Typography>
                    <NameDialog
                        classes={this.props.classes}
                        history={this.props.history}
                        name={this.state.user.first_name}
                    />
                    <EmailDialog
                        classes={this.props.classes}
                        history={this.props.history}
                        username={ this.props.username}
                        hasEmail={this.state.user.has_email}
                    />
                    <PasswordDialog
                        classes={this.props.classes}
                        history={this.props.history}
                        username={this.props.username}
                        hasEmail={this.state.user.has_email}
                        hasPassword={this.state.user.has_password}
                    />
                    <DeleteDialog
                        classes={this.props.classes}
                        history={this.props.history}
                    />
                </Paper>}
                <Snackbar open={this.state.errorBarOpen} autoHideDuration={6000} onClose={() => { this.errorBarClose() }}>
                    <MuiAlert elevation={6} severity="error">{this.state.errorBarText}</MuiAlert>
                </Snackbar>
            </>
        )
    }
}