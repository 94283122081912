import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

//material-ui
import { useStyles } from '../style/common'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import RootBox from './common/RootBox'


//acction
import { GetDate, GetContent } from '../action/news/NewsAction'

//setting
import { API_ROOT_URL, SITE_TITLE } from '../setting'

const NewsDetail = (props) => {
    const classes = useStyles()
    const { params } = props.match
    return (
        <RootBox>
            <Paper className={classes.userBlock}>
                <NewsContents
                    classes={classes}
                    params={params}
                    history={props.history}
                />
            </Paper>
        </RootBox>
    )
}
export default withRouter(NewsDetail)

class NewsContents extends React.Component {
    constructor(props) {
        super(props);
        this.state = { news: "" }
        this.GetNews()
    }
    async GetNews() {
        await (axios.get(API_ROOT_URL + "news/" + this.props.params.id + "/", {}))
            .then(res => {
                this.setState({ news: res.data })
            })
    }
    GetDate = (datetime) => {
        if (typeof (datetime) === "string") {
            return (GetDate(datetime))
        } else {
            return (<></>)
        }
    }

    GetContent = (contents) => {
        if (typeof (contents) === "string") {
            return (GetContent(contents))
        } else {
            return (<></>)
        }
    }

    move = (url) => {
        this.props.history.push(url)
    }

    render() {
        return (
            <>
                <Breadcrumbs>
                    <Helmet>
                        <title>{SITE_TITLE} -お知らせ {this.state.news.title}-</title>
                    </Helmet>
                    <Link
                        color="inherit"
                        href="/"
                    >
                        TOP
                    </Link>
                    <Link
                        color="inherit"
                        href="/news/"
                    >
                        お知らせ一覧
                    </Link>
                    <Typography>
                        {this.state.news.title}
                    </Typography>
                </Breadcrumbs>
                <Divider />
                <Typography variant="h6" className={this.props.classes.title}>
                    {this.state.news.title}
                </Typography>
                <Typography variant="subtitle2" className={this.props.classes.title}>
                    {this.GetDate(this.state.news.make_date)}
                </Typography>
                <Divider />
                <NewsBox>
                    <Typography variant="subtitle1" lineHeight={2}>
                        {this.GetContent(this.state.news.contents)}
                    </Typography>
                </NewsBox>

            </>
        )
    }

}

const NewsBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 2),
        padding: theme.spacing(1),

    }
})

const NewsBox = withStyles(NewsBoxStyles)(Box);