import React from 'react';
import { TwitterShareButton, } from 'react-twitter-embed';

//material-ui
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import RepeatIcon from '@material-ui/icons/Repeat';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import Tooltip from '@material-ui/core/Tooltip';
import blue from '@material-ui/core/colors/blue';

//my component
import ReactHlsAudio from '../player/react-hls-audio'

//setting
import { ROOT_URL, SITE_NAME } from "../../setting"


export default class PlaylistPlayer extends React.Component {
    constructor(props) {
        super(props);
        const ua = window.navigator.userAgent.toLowerCase();
        const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
        this.state = {
            music: this.props.music,
            musics: this.props.musics,
            loop: this.props.loop,
            playlistLoop: this.props.playlistLoop,
            isiOS: isiOS,
            count: this.props.count,
            ids: this.props.ids,
            display: {},
            playlist: this.props.playlist,
            shareButton: <></>,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.music !== prevProps.music) {
            this.setState({ music: this.props.music, })
        }
        if (this.props.musics !== prevProps.musics && prevProps.musics.length === 0) {
            this.setState({ musics: this.props.musics, })
        }
        if (this.props.ids !== prevProps.ids && this.state.isiOS) {
            const display = {}
            this.props.ids.forEach((id) => display[id] = 'none')
            display[this.props.ids[this.props.count]] = "block"

            this.setState({
                ids: this.props.ids,
                display: display,
            })

        }
        if (this.props.count !== prevProps.count && this.state.isiOS) {
            const display = {}
            this.props.ids.forEach((id) => display[id] = 'none')
            display[this.props.ids[this.props.count]] = "block"
            if (!this.props.didShuffle) {
                document.getElementById("playlist_" + this.props.ids[prevProps.count]).pause()
                document.getElementById("playlist_" + this.props.ids[prevProps.count]).currentTime = 0
                document.getElementById("playlist_" + this.props.ids[this.props.count]).play()
            } else {
                this.props.onDidShuffle()
            }
            this.setState({
                count: this.props.count,
                display: display,
            })
        }

        if (this.props.loop !== prevProps.loop) {
            this.setState({ loop: this.props.loop })
        }

        if (this.props.playlistLoop !== prevProps.playlistLoop) {
            this.setState({ playlistLoop: this.props.playlistLoop })
        }

        if (this.props.playlist !== prevProps.playlist) {
            this.setState({
                playlist: this.props.playlist,
                shareButton: <><TwitterShareButton
                    url={ROOT_URL + "playlist/" + this.props.playlist.id}
                    options={{
                        text: "-" + SITE_NAME + "-\nプレイリスト：" + this.props.playlist.name + " #Utaloader",
                        size: 'large'
                    }}
                /></>
            })
        }

    }
    GetLoopIcon = () => {
        if (this.state.loop) {
            return (
                <>
                    <Tooltip title="この曲をループ再生中">
                        <IconButton onClick={this.props.onLoopClick}>
                            <RepeatOneIcon htmlColor={blue[500]} />
                        </IconButton>
                    </Tooltip>
                </>
            )
        } else if (this.state.playlistLoop) {
            return (
                <>
                    <Tooltip title="プレイリストをループ再生中">
                        <IconButton onClick={this.props.onLoopClick}>
                            <RepeatIcon htmlColor={blue[500]} />
                        </IconButton>
                    </Tooltip>
                </>
            )
        } else {
            return (
                <>
                    <Tooltip title="ループ再生無効">
                        <IconButton onClick={this.props.onLoopClick}>
                            <RepeatIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    }

    onLoopIconClick = () => {
        this.setState({ loop: !this.state.loop })
    }


    GetPlayer = () => {
        let count = 0
        if (this.state.isiOS && this.state.musics.length !== 0) {
            return (
                <>
                    {this.state.musics.map((music) => (
                        <audio
                            className={this.props.classes.playlistPlayer}
                            onEnded={this.props.onEnded}
                            id={"playlist_" + music.id}
                            style={{ display: this.state.display[music.id] }}
                            controls
                            onPlay={this.props.onPlay}
                        >
                            <source src={ROOT_URL + "Media/" + music.audio_path} type="application/x-mpegURL" />
                        </audio>
                    ))}
                </>
            )
        } else {
            return (
                <>
                    <ReactHlsAudio
                        url={ROOT_URL + "Media/" + this.state.music.audio_path}
                        onEnded={this.props.onEnded}
                        onLoadedData={this.props.onLoadedData}
                        onVolumeChange={this.props.onVolumeChange}
                        className={this.props.classes.playlistPlayer}
                        id="playlist"
                        audioProps={{ loop: false }}
                        onPlay={this.props.onPlay}
                    />
                </>
            )
        }
    }

    render() {
        return (
            <>
                <div className={this.props.classes.playlistRoot}>
                    <Box className={this.props.classes.playlistImgBox}>
                        <img
                            src={ROOT_URL + "Media/" + this.state.music.image_path}
                            alt="thumb"
                            className={this.props.classes.playlistThumb}
                        />
                    </Box>
                    <Box className={this.props.classes.playlistPlayerBox}>
                        <Typography variant="h6">
                            {this.state.music.title}
                        </Typography>
                        {this.GetPlayer()}
                        <IconButton onClick={this.props.onLoopClick}>
                            {this.GetLoopIcon()}
                        </IconButton>
                        <Tooltip title="シャッフル">
                            <IconButton onClick={this.props.ShuffleOnClick}>
                                <ShuffleIcon className={this.props.classes.IconMargin} />
                            </IconButton>
                        </Tooltip>
                        {this.state.shareButton}
                    </Box>
                </div>
            </>
        )
    }
}