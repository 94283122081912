import React from 'react';
import axios from 'axios';

//material-ui
import Typography from '@material-ui/core/Typography';

//my component
import MusicListDev from '../common/MusicListDev'

//setting
import { API_ROOT_URL } from "../../setting"

export default class RecentMusicListDev extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tileData: [] }
        this.GetTileData()
    }
    async GetTileData() {
        await (axios.get(API_ROOT_URL + "recent/", {}))
            .then(res => {
                this.setState({ tileData: res.data })
            })
    }

    render() {
        return (<>
            <Typography variant="h6" className={this.props.classes.title}>
                {this.props.title}
            </Typography>
            <MusicListDev
                classes={this.props.classes}
                tileData={this.state.tileData}
                musicOnClick={this.props.musicOnClick}
            />
        </>);
    }
}
