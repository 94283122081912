import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import blue from '@material-ui/core/colors/blue';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
//setting
import { API_ROOT_URL, SITE_TITLE } from "../../setting"
import { Divider, Button, Box } from '@material-ui/core';
import MarginedButton from '../common/MarginedButton';

class RuleTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: null,
            next: null,
            prev: null,
            select: null,
            keyword: "",
        }
        this.GetImages(API_ROOT_URL + this.props.api)
    }

    GetImages = async (url) => {
        await (axios.get(url)).then(res => {
            this.setState({
                images: res.data.results,
                prev: res.data.previous,
                next: res.data.next
            })
        })
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.api !== this.props.api) {
            this.setState({
                images: null,
                next: null,
                prev: null,
                select: null,
                keyword: "",
            })
            this.GetImages(API_ROOT_URL + this.props.api)
        }
    }

    OnKeywordChange = (e) => {
        const keyword = e.target.value
        if (keyword === "") {
            this.setState({
                images: null,
                next: null,
                prev: null,
                select: null,
                keyword: "",
            })
        } else {
            this.GetImages(API_ROOT_URL + this.props.api + keyword + "/")
            this.setState({ keyword: keyword })
        }
    }

    render() {
        return (<>
            <Typography variant="caption">サムネイルの制限に基づくボーカルクレジットは自動で登録されます。</Typography>
            {this.props.requirekey && <>
                <TextField
                    label="キーワードで検索"
                    name="keyword"
                    helperText="32文字以内"
                    fullWidth
                    inputProps={{ maxLength: 32 }}
                    onChange={this.OnKeywordChange}
                    value={this.state.keyword}
                />
                </>}
            {this.state.images === null ? !this.props.requirekey ? <>
                <LinearProgress />
            </> : <Typography variant="body2">
                    ↑キーワードを入力してください
                </Typography> :
                this.state.images.length === 0 ? <Typography variant="body2">
                    見つかりませんでした
                </Typography> : <Box style={{ display: 'flex', justifyContent: 'space-around', flexWrap: "wrap" }}>
                        {this.state.images.map((img, i) => (<Box style={{ maxWidth: 170 }}>
                            <img
                                src={img.file_path}
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderColor: blue[300],
                                    borderStyle: this.state.select === img.id ? "solid" : "none",
                                    margin: 8
                                }}
                                onClick={() => { this.setState({ select: img.id }) }}
                            /><br />
                            <Typography variant="caption">使用数：{img.link_count}</Typography><br />
                            {(img.require_vocal !== null && img.require_vocal !== "") && <Typography variant="caption" color="error">ボーカル制限：{img.require_vocal}</Typography>}
                        </Box>))}
                    </Box>

            }
            <Divider />
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <MarginedButton startIcon={<NavigateBeforeIcon />} variant="outlined" disabled={this.state.prev === null} onClick={() => { this.GetImages(this.state.prev) }}>前へ</MarginedButton>
                    <MarginedButton endIcon={<NavigateNextIcon />} variant="outlined" disabled={this.state.next === null} onClick={() => { this.GetImages(this.state.next) }}>次へ</MarginedButton>
                </Box>
                <MarginedButton variant="outlined" disabled={this.state.select === null} onClick={() => { this.props.SetImage(this.state.select) }}>決定</MarginedButton>
            </Box>
        </>)
    }
}
export default withRouter(RuleTabs)