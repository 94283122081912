import React from 'react';

//material-ui
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import blueGrey from '@material-ui/core/colors/blueGrey';

const CreditFieldItem = (props) => {
    const colors = [blueGrey[50], blueGrey[0]]
    const credits = [
        {
            value: 'None',
            label: '<選択>'
        },
        {
            value: 'MN',
            label: '曲名'
        },
        {
            value: 'V',
            label: '(当作品の)ボーカル'
        },
        {
            value: 'M',
            label: '作曲'
        },
        {
            value: 'L',
            label: '作詞'
        },
        {
            value: 'A',
            label: '編曲'
        },
        {
            value: 'P',
            label: '演奏/調声/Mix等'
        },
        {
            value: 'G',
            label: '採譜'
        },
        {
            value: 'S',
            label: 'サムネイル'
        },
        {
            value: 'J',
            label: 'JASRAC管理番号'
        },
    ]
    let viewNum = props.num + 1
    return (
        <>
            <Box className={props.classes.creditsBox} style={{ backgroundColor: colors[viewNum % 2] }}>
                <Typography className={props.classes.creditLabel}>
                    {"クレジット" + viewNum}
                </Typography>
                <TextField
                    label={"種類"}
                    name={"credit_type_" + props.num}
                    select
                    className={props.classes.creditTypeField}
                    onChange={props.onTypeChange}
                    defaultValue={props.value.type}
                >
                    {credits.map((credit) => (
                        <MenuItem key={credit.value} value={credit.value}>
                            {credit.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label={"名義_最大32文字"}
                    name={"credit_name_" + props.num}
                    className={props.classes.creditNameField}
                    maxLength={32}
                    inputProps={{ maxLength: 32 }}
                    number={props.num}
                    onChange={props.onNameChange}
                    defaultValue={props.value.name}
                />
            </Box>
        </>
    );
}
export default CreditFieldItem