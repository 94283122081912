import axios from 'axios';

//setting
import { API_ROOT_URL } from "../../setting"

export function tagUpload(self, musicId) {
    let params
    let i
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    for (i = 0; i < self.state.tags.length; i++) {
        params = new FormData()
        params.append("value", self.state.tags[i])

        axios.post(API_ROOT_URL + "tagmusic/" + musicId + "/",
            params
        ).then(res => {
        }).catch(err => {
            /*todo �G���[���e�ǂݍ���ł��ꂼ��̓��e��state.forms���X�V����*/
        });
    }
}