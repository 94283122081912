//material-ui
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const PlayerPaperStyles = () => ({
    root: {
        display: 'inline',
    }
})

const PlayerPaper = withStyles(PlayerPaperStyles)(Paper)

export default PlayerPaper