//material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const SearchButtonStyles = (theme) => ({
    root: {
        margin: theme.spacing(1),
    }
})

const SearchButton = withStyles(SearchButtonStyles)(Button);
export default SearchButton
