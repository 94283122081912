import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

//material-ui
import Paper from '@material-ui/core/Paper';

//my component
import { useStyles } from '../style/common'
import RecentMusicListDev from './top/RecentMusicListDev'
import FavoriteMusicList from './top/FavoriteMusicList'
import News from './top/News'
import Top from './top/Top'
import RootBox from './common/RootBox'
import WideAd from './common/WideAd'

//setting
import { SITE_TITLE } from "../setting"

function Dev(props) {
    const classes = useStyles();
    let isLoggedin = false
    if (document.getElementById('username').innerText !== "AnonymousUser") {
        isLoggedin = true
    } else {
        isLoggedin = false
    }
    function uploadOnClick() {
        props.history.push("/upload")
    }
    const musicOnClick = (musicId) => {
        props.history.push("/music/" + musicId)
    }

    const IsFavView = (isLoggedin) => {
        if (isLoggedin) {
            return (
                <>
                    <Helmet>
                        <title>{SITE_TITLE}</title>
                    </Helmet>
                    <Paper className={classes.userBlock}>
                        <FavoriteMusicList
                            title="お気に入りタグ"
                            classes={classes}
                            isLoggedin={isLoggedin}
                            musicOnClick={musicOnClick}
                        />
                    </Paper>
                    <WideAd />
                </>
            )
        }
        else {
            return (<></>)
        }
    }

    return (
        <RootBox>
            <Paper className={classes.userBlock}>
                <Top
                    classes={classes}
                    isLoggedin={isLoggedin}
                    uploadOnClick={uploadOnClick}
                />
            </Paper>
            <Paper className={classes.userBlock}>
                <News
                    classes={classes}
                    title="お知らせ"
                    history={props.history}
                />
            </Paper>
            <WideAd />
            {IsFavView(isLoggedin)}
            <Paper className={classes.userBlock}>
                <RecentMusicListDev
                    title="新着"
                    classes={classes}
                    musicOnClick={musicOnClick}
                />
            </Paper>
            <WideAd />
        </RootBox>
    );
}
export default withRouter(Dev)
