import React from 'react';

//material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

const TagButtonStyles = (theme) => ({
    root: {
        margin: theme.spacing(1),
        maxWidth: '90%'
    }
})

const TagButtonBase = withStyles(TagButtonStyles)(Chip);
export default TagButtonBase
