import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";


//my component
import { useStyles } from '../style/common'
import Box from '@material-ui/core/Box';
import RootBox from './common/RootBox'
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import RuleTabs from './RuleTabs'

//setting
import { SITE_TITLE } from "../setting"
const SimpleRule = (props) => {
    const classes = useStyles()


    return (
        <RootBox>
            <Helmet>
                <title>{SITE_TITLE} -利用規約(要約)-</title>
            </Helmet>
            <Paper className={classes.userBlock} id="music">
                <Breadcrumbs>
                    <Link
                        color="inherit"
                        onClick={() => { props.history.push("/") }}
                    >
                        TOP
                    </Link>
                    <Typography>
                        利用規約(要約)
                    </Typography>
                </Breadcrumbs>
                <Divider />
                <RuleTabs
                    value={1}
                />
                <RuleBox>
                    <Typography variant="subtitle1">
                        このページには利用規約の内、利用者の方に必ず知っておいて欲しい重要なポイントを掲載します。<br />
                        利用規約と「利用規約(要約)」の内容に差異がある場合には利用規約が優先されます。
                    </Typography>
                    <br />
                    <Typography variant="h6">
                        第2条 同意
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            各ガイドラインを含めたすべての規約に同意しないとサービスは利用できません。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第4条 アカウント
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            アカウントはいつでも自由に削除できますが、間違えて削除しても復旧はできません。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第5条 広告表示
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            広告やアフィリエイトリンクをサイト内に設置することがあります。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第6条 知的財産権の帰属及び使用許諾
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            著作権はクリエイターのものです。<br />
                            運営団体には、サムネイルの生成(加工)やスクリーンショットの引用等を認めてください。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第7条 禁止行為
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            他の方に迷惑がかかる行為はしないでください。<br />二次創作については各作品の二次創作ガイドラインに従ってください。
                            悪質なアカウントは予告なくアカウントを凍結、削除する場合があります。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第8条 投稿コンテンツの非表示、削除
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            権利者・公的団体等から苦情が来た場合、当該作品やユーザーを削除することがあります。<br />
                            本サービスでは投稿後7日以内に、適切なクレジットが設定されない作品は自動で非表示となります。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第9条 利用者の責任
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            利用者同士のトラブルは利用者同士で解決してください。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第10条 運営団体の免責
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            このサービスで何らかの金銭的問題が発生しても、ほとんどの場合において運営団体は責任を負いません。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第11条 プライバシー
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            運営団体はプライバシーポリシーに基づいて個人情報を管理しています。<br />
                            ハンドルネームやプロフィール画像の取得にTwitterログインを用いていますが、サービスが勝手に呟いたり、DMを覗いたりすることはありません。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第12条 準拠法及び管轄裁判所
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            もし裁判になった場合には大阪地方裁判所で行います。
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        第13条 改定
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            利用規約の変更にあたってはホームページやtwitter等でお知らせします。重要なポイントについては、分かりやすさを重視し告知なく日々修正しています。
                        </Typography>
                    </RuleSubBox>
                    <RuleDateBox>
                        <Typography variant="body2">
                            制定：2020年7月23日
                        </Typography>
                    </RuleDateBox>
                </RuleBox>
            </Paper>
        </RootBox>
    )

}
export default withRouter(SimpleRule)

const RuleBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(2),
    }
})

const RuleBox = withStyles(RuleBoxStyles)(Box);

const RuleSubBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 2, 4),
    }
})

const RuleSubBox = withStyles(RuleSubBoxStyles)(Box);


const RuleDateBoxStyles = (theme) => ({
    root: {
        textAlign: 'right',
    }
})

const RuleDateBox = withStyles(RuleDateBoxStyles)(Box);