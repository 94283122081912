import React from 'react';

//material-ui
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const IsMusicCheck = (props) => {
    let is_music = props.is_music
    if (is_music === undefined) {
        is_music = true
    }
    return (
        <>
            <FormControlLabel
                label="この音源は音楽ではありません(セリフ/メッセージ/読み上げなど)"
                control={
                    <Checkbox
                        name="is_music"
                        checked={is_music}
                    />
                }
                onChange={props.handleChange}
            /><br />
        </>
    )
}
export default IsMusicCheck