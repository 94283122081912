import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

//material-ui
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RepeatIcon from '@material-ui/icons/Repeat';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import Tooltip from '@material-ui/core/Tooltip';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import Slider from '@material-ui/core/Slider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

//my component
import ReactHLSAudio from './react-hls-audio'
import Box from '@material-ui/core/Box';

//setting
import { API_ROOT_URL } from "../../setting"

export default class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);
        const ua = window.navigator.userAgent.toLowerCase();
        this.isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
        let volume = localStorage.getItem("volume")
        if (volume === null) {
            volume = 0.5
        }
        this.state = {
            currentTime: 0,
            totalTime: 0,
            loop: true,
            volume: volume,
            tempVolume: volume,
            audioPath: this.props.audioPath,
            isPlaying: false,
            LoopBarOpen: false,
            UnLoopBarOpen: false,
            url: this.props.url,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.audioPath !== prevProps.audioPath) {
            this.setState({
                audioPath: this.props.audioPath,
                url: this.props.url
            })
        }
    }
    GetLoopIcon = () => {
        if (this.state.loop) {
            return (
                <>
                    <Tooltip title="ループ再生を無効化する">
                        <IconButton
                            onClick={this.onLoopIconClick}
                        >
                            <RepeatIcon htmlColor={blue[500]} className={this.props.classes.IconMargin} />
                        </IconButton>
                    </Tooltip>
                </>
            )
        } else {
            return (
                <>
                    <Tooltip title="ループ再生を有効化する">
                        <IconButton
                            onClick={this.onLoopIconClick}
                        >
                            <RepeatIcon color="inherit" className={this.props.classes.IconMargin} />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    }

    GetPlayIcon = () => {
        if (this.state.isPlaying) {
            return (<PauseIcon />)
        } else {
            return (<PlayArrowIcon />)
        }
    }

    GetVolumeIcon = () => {
        if (this.state.volume === 0) {
            return (<VolumeOffIcon />)
        } else {
            return (<VolumeUpIcon />)
        }
    }

    GetTime = (now, total) => {
        return (Math.floor(now / 60) + ":" + String(Math.floor(now % 60)).padStart(2, "0") + "/" + Math.floor(total / 60) + ":" + String(Math.floor(total % 60)).padStart(2, "0"))
    }

    GetAudio = () => {
        if (this.isiOS) {
            if (this.state.audioPath.indexOf('undefined') === -1) {
                return (
                    <>
                        <audio
                            id={"react-hls-" + this.props.id}
                            className={this.props.classes.hlsPlayer}
                            loop={false}
                            onLoadedData={this.onLoadedData}
                            onLoadedMetadata={this.onLoadedMetadata}
                            onEnded={this.onEnded}
                            onTimeUpdate={this.onTimeUpdate}
                            controls={false}
                        >
                            <source src={this.state.audioPath} type="application/x-mpegURL" />
                        </audio>
                        {this.GetLoopIcon()}
                    </>
                )
            } else {
                return (<></>)
            }
        } else {
            return (
                <>
                    <ReactHLSAudio
                        url={this.state.audioPath}
                        className={this.props.classes.hlsPlayer}
                        audioProps={{ loop: false, volume: this.state.volume }}
                        onLoadedData={this.onLoadedData}
                        onLoadedMetadata={this.onLoadedMetadata}
                        onEnded={this.onEnded}
                        onTimeUpdate={this.onTimeUpdate}
                        controls={false}
                        id={this.props.id}
                    />
                </>
            )
        }
    }

    GetVolumeArea = () => {
        if (this.isiOS) {
            return (
                <></>
            )
        } else {
            return (
                <>
                    <AudioPlayerSubBox>
                        {this.GetLoopIcon()}
                        <Box style={{
                            alignItems: 'center',
                            display: 'flex',
                        }}>
                            <IconButton onClick={this.OnVolumeClick}>
                                {this.GetVolumeIcon()}
                            </IconButton>
                            <VolumeSlider
                                min={0}
                                max={1}
                                step={0.01}
                                value={this.state.volume}
                                onChange={this.onVolumeChange}
                                disabled={this.state.volume !== this.state.tempVolume}
                            />
                        </Box>
                    </AudioPlayerSubBox>
                </>
            )
        }

    }

    OnCount = () => {
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.put(API_ROOT_URL + "musiccount/" + this.state.url + "/", {})
    }

    OnVolumeClick = () => {
        const playerId = "react-hls-" + this.props.id
        if (this.state.volume !== this.state.tempVolume) {
            this.setState({ volume: this.state.tempVolume })
            document.getElementById(playerId).volume = this.state.tempVolume
        } else {
            this.setState({ volume: 0 })
            document.getElementById(playerId).volume = 0
        }
    }

    onPlayIconClick = () => {
        const playerId = "react-hls-" + this.props.id
        if (document.getElementById(playerId).paused) {
            document.getElementById(playerId).play()
            this.setState({ isPlaying: true })
            if (this.state.currentTime === 0) {
                this.OnCount()
            }
        } else {
            document.getElementById(playerId).pause()
            this.setState({ isPlaying: false })
        }
    }

    onLoopIconClick = () => {
        if (this.state.loop) {
            this.setState({
                loop: false,
                LoopBarOpen: false,
                UnLoopBarOpen: true,
            })
        } else {
            this.setState({
                loop: true,
                LoopBarOpen: true,
                UnLoopBarOpen: false,
            })

        }
    }

    onVolumeChange = (event, newValue) => {
        this.setState({ tempVolume: newValue })
        this.setState({ volume: newValue })
        localStorage.setItem('volume', newValue)
        const playerId = "react-hls-" + this.props.id
        document.getElementById(playerId).volume = newValue
    }

    onLoadedData = (event) => {
        const playerId = "react-hls-" + this.props.id
        document.getElementById(playerId).volume = this.state.volume
    }

    onLoadedMetadata = (event) => {
        this.setState({ totalTime: event.target.duration })
    }

    onEnded = (event) => {
        const playerId = "react-hls-" + this.props.id
        document.getElementById(playerId).currentTime = 0
        if (this.state.loop) {
            this.setState({
                currentTime: 0
            })
            document.getElementById(playerId).play()
            this.OnCount()
        } else {
            this.setState({
                isPlaying: false,
                currentTime: 0
            })
        }
    }

    onTimeUpdate = (event) => {
        this.setState({ currentTime: event.target.currentTime })
    }

    onSeekBarChange = (event, nextValue) => {
        const playerId = "react-hls-" + this.props.id
        document.getElementById(playerId).currentTime = nextValue
        this.setState({ currentTime: nextValue })
    }



    render() {
        return (
            <>
                <AudioPlayerBaseBox>
                    <AudioPlayerMainBox>
                        <IconButton onClick={this.onPlayIconClick}>
                            {this.GetPlayIcon()}
                        </IconButton>
                        <TimeTypography variang="body2">
                            {this.GetTime(this.state.currentTime, this.state.totalTime)}
                        </TimeTypography>
                        <SeekSlider
                            step={0.1}
                            min={0}
                            max={this.state.totalTime}
                            value={this.state.currentTime}
                            onChange={this.onSeekBarChange}
                        />
                        {this.GetAudio()}
                    </AudioPlayerMainBox>
                    {this.GetVolumeArea()}
                </AudioPlayerBaseBox>
                <Snackbar open={this.state.LoopBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ LoopBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="info">ループ再生を設定しました</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.UnLoopBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ UnLoopBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="info">ループ再生を解除しました</MuiAlert>
                </Snackbar>
            </>
        )
    }
}


const AudioPlayerBaseBoxStyles = (theme) => ({
    root: {
        width: theme.spacing(82),
        maxWidth: '85%',
        display: 'inline-flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: "center",
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: grey[100],
        borderRadius: theme.spacing(1),
    }
})

const AudioPlayerBaseBox = withStyles(AudioPlayerBaseBoxStyles)(Box);

const AudioPlayerMainBoxStyles = (theme) => ({
    root: {
        width: theme.spacing(50),
        maxWidth: '100%',
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'none',
        justifyContent: "space-around",
        margin: theme.spacing(1),
        backgroundColor: grey[100],
        flexGrow: 1,
    }
})

const AudioPlayerMainBox = withStyles(AudioPlayerMainBoxStyles)(Box);
const AudioPlayerSubBoxStyles = (theme) => ({
    root: {
        width: theme.spacing(18),
        maxWidth: '100%',
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'none',
        justifyContent: "space-around",
        margin: theme.spacing(1),
        backgroundColor: grey[100],
        flexGrow: 1,
    }
})

const AudioPlayerSubBox = withStyles(AudioPlayerSubBoxStyles)(Box);

const SeekSliderStyles = (theme) => ({
    root: {
        flexGrow: 1,
    }
})
const SeekSlider = withStyles(SeekSliderStyles)(Slider);
const VolumeSliderStyles = (theme) => ({
    root: {
        width: theme.spacing(8),
        minWidth: theme.spacing(4),
    }
})
const VolumeSlider = withStyles(VolumeSliderStyles)(Slider);

const TimeTypographyStyles = (theme) => ({
    root: {
        margin: theme.spacing(0, 1)
    }
})
const TimeTypography = withStyles(TimeTypographyStyles)(Typography);



AudioPlayer.propTypes = {
    audioPath: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    id: PropTypes.string
}

AudioPlayer.defaultProps = {
    id: "audio"
}