import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

//material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PublishIcon from '@material-ui/icons/Publish';

//setting
import { ROOT_URL, IS_ALPHA, LOGOUT_URL, LOGIN_URL } from "./setting"
import { Avatar } from '@material-ui/core';


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);


class AccountMenu extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.isLoggedin) {
            this.state = { value: this.loggedInContents() }
        } else if (IS_ALPHA) {
            this.state = { value: this.AlphaContents() }
        }
        else {
            this.state = { value: this.loggedOutContents() }
        }
    }

    loggedOutClick = () => {
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.post(LOGOUT_URL, {})
            .then(res => {
                this.setState({ value: this.loggedOutContents() })
                document.location.href = ROOT_URL
            })
    };

    uploadOnClick = () => {
        this.props.history.push("/upload")
    }

    userOnClick = () => {
        this.props.history.push("/user/" + document.getElementById("username").innerHTML)
    }

    loggedInContents() {
        return (
            <UserButton
                classes={this.props.classes}
                loggedOutClick={this.loggedOutClick}
                uploadOnClick={this.uploadOnClick}
                userOnClick={this.userOnClick}
                userName={this.props.userName}
                userIcon={this.props.userIcon}
            />);
    }

    loggedOutContents() {
        return (
            <Button
                color="inherit"
                onClick={() => { document.location.href = LOGIN_URL + "?next=" + document.location.pathname }}
            >
                Login
            </Button>);
    }

    AlphaContents() {
        return (
            <Button
                color="inherit"
            >

            </Button>);
    }

    render() {
        return (
            <span className={this.props.classes.twitterIcon}>
                {this.state.value}
            </span>
        );
    }
}

export default withRouter(AccountMenu)

class UserButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    render() {
        return (
            <span>
                <Avatar
                    src={this.props.userIcon}
                    className={this.props.classes.twitterIcon}
                    onClick={this.handleClick}
                    alt="icon"
                    variant="square"
                    style={{
                        width: 32, height: 32,
                        display: 'inline'
                    }}
                    imgProps={{ style: { width: 32, height: 32,}}}
                />
                <Button
                    color="inherit"
                    onClick={this.handleClick}
                    className={this.props.classes.userName}
                >
                    {this.props.userName}
                </Button>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    <StyledMenuItem>
                        <ListItemIcon>
                            <PublishIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary="アップロード"
                            onClick={() => { this.props.uploadOnClick(); this.handleClose() }}
                        />
                    </StyledMenuItem>
                    <StyledMenuItem>
                        <ListItemIcon>
                            <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary="ユーザーページ"
                            onClick={() => { this.props.userOnClick(); this.handleClose() }}
                        />
                    </StyledMenuItem>
                    <StyledMenuItem>
                        <ListItemIcon>
                            <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary="ログアウト"
                            onClick={this.props.loggedOutClick}
                        />
                    </StyledMenuItem>
                </StyledMenu>
            </span>
        );
    }
}