import React from 'react';
import axios from 'axios';

//material-ui
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


//my component
import MarginedButton from '../common/MarginedButton'
import { DialogTitle, DialogContent } from '../common/DialogParts'
import { DialogOpen, DialogClose } from '../../action/common/DialogAction'


//setting
import { ROOT_URL, API_ROOT_URL } from "../../setting"

export default class NameDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            successBar: false,
            errorBar: false,
            errorText: <></>,
            name: this.props.name
        }
    }

    async OnSubmitClick() {
        const params = new FormData()
        if (this.state.name === "") {
            this.setState({
                errorBar: true,
                errorText: <>活動名義は空にできません。</>,
            })
        } else {
            params.append("first_name", this.state.name)
            axios.defaults.xsrfCookieName = 'csrftoken'
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            await (axios.put(API_ROOT_URL + "usersetname/a/", params)).then(() => {
                this.setState({
                    open: false,
                    successBar: true,
                })
            })
        }

    }
    onChange = (event) => {
        this.setState({ name: event.target.value })
    }

    render() {
        return (
            <DeleteDialogBox>
                <MarginedButton onClick={() => DialogOpen(this)} variant="contained">
                    活動名義の変更
                </MarginedButton>
                <Dialog onClose={() => DialogClose(this)} ari-labelledby="customized-dialog-title" open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={() => DialogClose(this)}>
                        活動名義の変更
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variang="body2">
                            ここで設定した名義はユーザーページに表示されるだけでなく、<br />
                            オリジナル楽曲を投稿した際の作詞・作曲者としてJASRACへの報告で利用されます。<br />
                        </Typography>
                        <br />
                        <TextField
                            value={this.state.name}
                            onChange={this.onChange}
                            fullWidth
                            helperText="30文字以内"
                            required
                            inputProps={{ maxLength: 30 }}
                        />
                        <br />
                        <br />
                        <MarginedButton
                            variant="outlined"
                            fullWidth
                            onClick={() => { this.OnSubmitClick() }}
                        >
                            活動名義の変更
                        </MarginedButton>
                        <br />
                    </DialogContent>
                </Dialog>
                <Snackbar open={this.state.successBar} autoHideDuration={6000} onClose={() => { window.location.reload() }}>
                    <MuiAlert elevation={6} severity="success">活動名義を変更しました</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.errorBar} autoHideDuration={6000} onClose={() => { this.setState({ errorBar: false }) }}>
                    <MuiAlert elevation={6} severity="error">{this.state.errorText}</MuiAlert>
                </Snackbar>
            </DeleteDialogBox>
        )
    }
}

const DeleteDialogBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 1, 1, 2),
        padding: theme.spacing(1),
    }
})

const DeleteDialogBox = withStyles(DeleteDialogBoxStyles)(Box);