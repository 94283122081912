import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';


//my component
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

class RuleTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
    }

    handleChange = (event, newValue) => {
        console.log(newValue)
        this.setState({ value: newValue });
    };

    render() {
        return (
            <>
                <RuleTabsBar
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    variant='fullWidth'
                >
                    <RuleTab label="利用規約" onClick={() => { this.props.history.push("/rule") }} />
                    <RuleTab label="利用規約(要約)" onClick={() => { this.props.history.push("/simplerule") }}/>
                    <RuleTab label="プライバシーポリシー" onClick={() => { this.props.history.push("/privacy") }}/>
                    <RuleTab label="ガイドライン" onClick={() => { this.props.history.push("/guideline") }} />
                    <RuleTab label="アプリサポート" onClick={() => { this.props.history.push("/appsupport") }} />
                </RuleTabsBar>
            </>
        )
    }
}

export default withRouter(RuleTabs)

RuleTabs.propTypes = {
    value: PropTypes.number,
}

RuleTabs.defaultProps = {
    id: 0
}


const RuleTabsStyles = (theme) => ({
    flexContainer: {
        [theme.breakpoints.down(785)]: {
            flexDirection: 'column'
        }
    },

    indicator: {
        [theme.breakpoints.down(785)]: {
            display: 'none',
        }
    },
    root: {
        display: 'flex',
    },
    '&& > *': {
        flexWrap: 'wrap'
    },
})

const RuleTabsBar = withStyles(RuleTabsStyles)(Tabs);

const RuleTabStyles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
})

const RuleTab = withStyles(RuleTabStyles)(Tab);