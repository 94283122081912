import React from 'react';
import { withRouter } from 'react-router-dom';

//material-ui
import Paper from '@material-ui/core/Paper';

//my component
import { useStyles } from '../../style/common'
import SearchTypeButtonArea from './SearchTypeButtonArea'



function SearchHeader(props) {

    function onTagSearchClick() {
        props.history.push("/tag/" + props.value + "/?order=" + props.order + "&page=" + props.page)
    }

    function onSearchClick() {
        props.history.push("/search/" + props.value + "/?order=" + props.order + "&page=" + props.page)
    }
    const classes = useStyles()
    return (
        <>
            <Paper className={classes.userBlock}>
                <SearchTypeButtonArea
                    history={props.history}
                    onTagSearchClick={onTagSearchClick}
                    onSearchClick={onSearchClick}
                    classes={classes}
                    url={props.url}
                    value={props.value}
                    order={props.order}
                    onSelectChange={props.onSelectChange}
                />
            </Paper>
        </>
    )
}
export default withRouter(SearchHeader)
