import React from 'react';

//material-ui
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

export default class TagAreaIcon extends React.Component {
    constructor(props) {
        super(props);
        const icon = this.GetIcon(this.props.edit)
        this.state = {
            icon: icon,
        }
    }
    GetIcon = () => {
        let icon
        if (this.props.lock) {
            icon = <LockIcon className={this.props.classes.IconMargin} />
        } else if (this.props.edit) {
            icon =
                <IconButton
                    onClick={this.props.onTagsEditClose}
                >
                    <CloseIcon
                        className={this.props.classes.IconMargin}
                    />
                </IconButton>
        } else {
            icon =
                <IconButton
                    onClick={this.props.onTagsEditClick}
                >
                    <EditIcon
                        className={this.props.classes.IconMargin}
                    />
                </IconButton >
        }
        return (icon)
    }

    componentDidUpdate(prevProps) {
        if (this.props.edit !== prevProps.edit) {
            const icon = this.GetIcon()
            this.setState({ icon: icon })
        }
        if (this.props.lock !== prevProps.lock) {
            const icon = this.GetIcon()
            this.setState({ icon: icon })
        }
    }

    render() {
        return (<>{this.state.icon}</>)
    }
}