import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    listroot: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 1),
            width: theme.spacing(90),
            maxWidth: '95%',
        },
    },
    block: {
        display: 'block',
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1),
        justifyContent: 'center'
    },
    hlsPlayer: {
        padding: theme.spacing(2),
        margin: theme.spacing(1, 0),
        width: theme.spacing(82),
        maxWidth: '85%',
    },
    thumb: {
        padding: theme.spacing(2),
        margin: theme.spacing(1, 0),
        width: theme.spacing(82),
        maxWidth: '85%',
        objectFit: 'contain',
    },
    body2: {
        margin: theme.spacing(1, 0, 1, 3),
    },
    contents: {
        padding: theme.spacing(1, 3),
        lineHeight: '200%'
    },
    IconMargin: {
        verticalAlign: 'middle',
    },
    select: {
        width: theme.spacing(40),
        maxWidth: '100%',
        '& > *': {
            padding: theme.spacing(1, 0, 1, 1),
        }
    },
    boxRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    titleBox: {
        display: 'inline',
        width: theme.spacing(56),
        maxWidth: '100%',
        verticalAlign: 'middle',
        padding: theme.spacing(0, 1),
    },
    ownerBox: {
        display: 'inline',
        width: theme.spacing(32),
        maxWidth: '100%',
        textAlign: "right",

    },
    title: {
        padding: theme.spacing(1),
        flexGrow: 1,
    },
}));