import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";



//material-ui
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import MuiAlert from '@material-ui/lab/Alert';
import { FormControlLabel, Snackbar, TextField } from '@material-ui/core';


//my component
import RootBox from './common/RootBox'
import BasePaper from './common/BasePaper';
import MarginedButton from './common/MarginedButton';

//setting
import { API_ROOT_URL, LIMIT_DATE, SITE_TITLE,LOGIN_URL,ROOT_URL, TWITTER_LOGIN_URL } from '../setting'

class Login extends React.Component {
    constructor(props) {
        super(props);
        if (document.getElementById('username').innerText !== "AnonymousUser") {
            document.location.href = ROOT_URL
        } 
        this.state = {
            mode: "login",
            signup: false,
            email: "",
            username:"",
            password1: "",
            password2: "",
            password1Error: false,
            password2Error: false,
            emailError: false,
            usernameError: false,
            agree: false,
            successBar: false,
            errorBar: false,
            errorText: <></>,
            successText: <></>,
            forgetPassword: false
        }
    }

    checkBoxChange = (event) => {
        console.log(event.target)
        this.setState({ [event.target.name]: event.target.checked})
    }

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            [event.target.name + "Error"]: false,
        })
        if (event.target.name === "password1" && event.target.value.length < 8) {
            this.setState({ "password1Error": true })
        } else if (event.target.name === "password1") {
            this.setState({ "password1Error": false })
        } else if (event.target.name === "password2" && event.target.value !== this.state.password1) {
            this.setState({ "password2Error": true })
        } else if (event.target.name === "password2") {
            this.setState({ "password2Error": false })
        }
    }

    onSubmitClick = () => {
        if (this.state.forgetPassword) {
            if (this.state.email === "") {
                this.setState({
                    emailError: true,
                    errorBar: true,
                    errorText: "メールアドレスが入力されていません"
                })
                return
            } else {
                this.SendResetMail(this.state.email)
                return
            }
        }
        if (this.state.username === "") {
            this.setState({
                usernameError: true,
                errorBar: true,
                errorText: "ユーザー名が入力されていません"
            })
            return
        }
        if (this.state.password1 === "") {
            this.setState({
                password1Error: true,
                errorBar: true,
                errorText: "パスワードが入力されていません"
            })
            return
        }
        if (this.state.password1.length < 8) {
            this.setState({
                password1Error: true,
                errorBar: true,
                errorText: "このパスワードは短すぎます"
            })
            return
        }
        if (this.state.signup) {
            if (!this.state.agree) {
                this.setState({
                    errorBar: true,
                    errorText: "利用規約をご確認の上同意願います"
                })
                return
            }
            if (this.state.email === "") {
                this.setState({
                    emailError: true,
                    errorBar: true,
                    errorText: "メールアドレスが入力されていません"
                })
                return
            }
            if (this.state.password2 !== this.state.password1) {
                this.setState({
                    password2Error: true,
                    errorBar: true,
                    errorText: "パスワードが一致しません"
                })
                return
            }
            this.SignUp()
        } else {
            this.LogIn()
        }
    }
    async SendResetMail(email) {
        const params = new FormData()
        params.append("email", email)
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        await (axios.post(API_ROOT_URL + "accounts/password/reset/", params)).then((res) => {
            this.setState({
                open: false,
                successBar: true,
                successText: <>パスワード再設定用のメールを送信しました。</>,
            })
        }).catch(error => {
            this.setState({
                errorBarOpen: true,
                errorBarText: "パスワード再設定用のメールの送信に失敗しました。",
            })
        })
    }
    async LogIn() {
        const params = new FormData()
        params.append("login", this.state.username)
        params.append("password", this.state.password1)
        params.append("remember", true)
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        await (axios.post(API_ROOT_URL + 'accounts/login/', params))
            .then(res => {
                console.log(res.data)
                if (res.data.match("ユーザー名もしくはパスワード")) {
                    this.setState({
                        usernameError: true,
                        passwordError: true,
                        errorBar: true,
                        errorText: "ユーザー名とパスワードが一致しません"
                    })
                } else if (res.data.match("メールアドレスもしくはパスワード")) {
                    this.setState({
                        usernameError: true,
                        passwordError: true,
                        errorBar: true,
                        errorText: "メールアドレスとパスワードが一致しません"
                    })
                } else if (res.data.match("メールアドレスを確認してください")) {
                    this.setState({
                        errorBar: true,
                        errorText: "メールアドレスの認証が完了していません。確認メールを送付しました。"
                    })
                } else {
                    document.location.href = ROOT_URL
                }
            })

    }
    async SignUp() {
        const params = new FormData()
        params.append("username", this.state.username)
        params.append("email", this.state.email)
        params.append("password1", this.state.password1)
        params.append("password2", this.state.password2)
        params.append("remember", false)
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        await (axios.post(API_ROOT_URL + 'accounts/signup/', params))
            .then(res => {
                console.log(res.data)
                if (res.data.match("このメールアドレスを使用しています")) {
                    this.setState({
                        emailError: true,
                        errorBar: true,
                        errorText: "他のユーザーがこのメールアドレスを使用しています"
                    })
                } else if (res.data.match("同じユーザー名が既に登録済みです")) {
                    this.setState({
                        usernameError: true,
                        errorBar: true,
                        errorText: "同じユーザー名が既に登録済みです"
                    })
                } else if (res.data.match("このパスワードは一般的すぎます")) {
                    this.setState({
                        password1Error: true,
                        password2Error: true,
                        errorBar: true,
                        errorText: "このパスワードは一般的すぎます"
                    })
                } else if (res.data.match("メールアドレスを確認してください")) {
                    document.location.href = '/signup/done/'
                } else {
                    document.location.href = ROOT_URL
                }
            })

    }

    render() {
        return (
            <>
            <RootBox>
                <Helmet>
                    <title>{SITE_TITLE} -ログイン-</title>
                </Helmet>
                <BasePaper>
                    <Breadcrumbs>
                        <Link
                            color="inherit"
                            onClick={() => { this.props.history.push("/") }}
                        >
                            TOP
                        </Link>
                        <Typography>
                            ログインページ
                        </Typography>
                    </Breadcrumbs>
                    <Divider />
                    <RuleBox>
                        <Typography variant="h6">
                            ソ－シャルログイン
                        </Typography>
                        <MarginedButton
                            variant="contained" size="large" color="primary"
                            href={TWITTER_LOGIN_URL}
                        >
                            Twitterでログイン
                        </MarginedButton>
                    </RuleBox>
                    <Divider />
                    <RuleBox>
                        <Typography variant="h6">
                            メールアドレスでログイン/会員登録
                            </Typography>
                            {!this.state.forgetPassword && <>
                        <FormControlLabel
                            control={<Checkbox
                                checked={this.state.signup}
                                onChange={this.checkBoxChange}
                                name="signup"
                                color="primary"
                            />}
                            label="初めての方はこちらを選択"
                            />
                                <TextField
                                label="ユーザー名(半角英数字)"
                                name="username"
                                value={this.state.username}
                                onChange={this.onChange}
                                fullWidth
                                required
                                inputProps={{ maxLength: 100 }}
                                error={this.state.usernameError}
                            /></>}
                        
                        {(this.state.signup || this.state.forgetPassword) && <>
                            <TextField
                                label="メールアドレス"
                                name="email"
                                value={this.state.email}
                                onChange={this.onChange}
                                fullWidth
                                required
                                inputProps={{ maxLength: 100 }}
                                error={this.state.emailError}
                            />
                            </>}
                            {!this.state.forgetPassword && <>
                        <TextField
                            label="パスワード(8文字以上)"
                            name="password1"
                            value={this.state.password1}
                            onChange={this.onChange}
                            fullWidth
                            required
                            inputProps={{ maxLength: 100 }}
                            type="password"
                            error={this.state.password1Error}
                            /></>}
                        {this.state.signup ? <>
                            <TextField
                                label="パスワード(確認)"
                                name="password2"
                                value={this.state.password2}
                                onChange={this.onChange}
                                fullWidth
                                required
                                inputProps={{ maxLength: 100 }}
                                type="password"
                                error={this.state.password2Error}
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={this.state.agree}
                                    onChange={this.checkBoxChange}
                                    name="agree"
                                    color="primary"
                                />}
                                label={<><Link href="/rule">利用規約</Link>および<Link href="/guideline">ガイドライン</Link>に同意します</>}
                            />
                            <Typography variant="body2">
                                利用規約/ガイドラインの必ずご理解いただきたい点
                                <ul>
                                    <li>音楽を投稿する際には、必ず楽曲名・作者名・ボーカル名をクレジット機能で表記する。</li>
                                    <li>クレジット機能において検索除けや、同士にしか通じない相性は用いず正式な名称を使用する。</li>
                                    <li>視聴用に販売されているCDに同梱されているカラオケ音源(原盤)の使用はできない。</li>
                                </ul>
                            </Typography>
                            </> : <>
                                <FormControlLabel
                                    control={<Checkbox
                                            checked={this.state.forgetPassword}
                                            onChange={this.checkBoxChange}
                                            name="forgetPassword"
                                            color="primary"
                                    />}
                                    label="パスワードを忘れた方はこちら"
                                /><br />
                            </>}
                        <MarginedButton
                            variant="contained" size="large" color="primary"
                            onClick={this.onSubmitClick}
                        >
                                {this.state.signup ? <>
                                    利用規約に同意して登録
                                </> : <>{this.state.forgetPassword ? <>
                                    パスワード再設定メール送信
                                </> : <>
                                    ログイン
                                </>}</>}
                        </MarginedButton>
                    </RuleBox>
                </BasePaper>
                </RootBox>
                <Snackbar open={this.state.successBar} autoHideDuration={6000} onClose={() => { window.location.reload() }}>
                    <MuiAlert elevation={6} severity="success">{this.state.successText}</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.errorBar} autoHideDuration={6000} onClose={() => { this.setState({ errorBar: false }) }}>
                    <MuiAlert elevation={6} severity="error">{this.state.errorText}</MuiAlert>
                </Snackbar>
            </>
        )
    }
}
export default withRouter(Login)



const RuleBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(2),
    }
})

const RuleBox = withStyles(RuleBoxStyles)(Box);