import React from 'react';

//material-ui
import CloseIcon from '@material-ui/icons/Close';
//my component
import TagButtonBase from '../common/TagButtonBase'

const TagButton = (props) => {
    return (
        <TagButtonBase
            variant="contained"
            icon={
                <CloseIcon
                    onClick={props.onTagDeleteClick}
                    id={"tags_value_" + props.value}
                />}
            label={props.value}
        />
    );
}

export default TagButton