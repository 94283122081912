import axios from 'axios';

//setting
import { API_ROOT_URL } from "../../setting"

export function QuickUpload(self) {
    const params = new FormData()
    let errorvalue = ["アップロードに失敗しました"]
    self.setState({
        isUploading: true,
    })
    params.append("title", self.state.title)
    if (self.state.title === "") {
        errorvalue.push("タイトルの入力は必須です")
    }
    params.append("description", self.state.description)
    if (self.state.description === "") {
        errorvalue.push("説明文の入力は必須です")
    }
    params.append("is_music", !self.state.is_music)
    params.append("official_title", self.state.official_title)
    if (self.state.official_title === null) {
        params.append("official_title", "")
    } else {
        params.append("official_title", self.state.official_title)
    }
    if ((!self.state.is_music) && ((self.state.official_title === "") || (self.state.official_title === null))) {
        errorvalue.push("音楽の場合曲名は省略できません。")
        self.setState({
            errorBarOpen: true,
            errorBarText: errorvalue,
            isUploading: false,
        })
        return
    }
    params.append("audio", self.props.audioId)
    params.append("image", self.state.imageId)
    params.append("publish", true)
    params.append("tag_lock", false)
    params.append("original", false)

    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.post(API_ROOT_URL + "music/",
        params
    ).then(res => {
        self.props.moveMusic(res.data.url)
    }).catch(err => {
        console.log(err.response.data['audio'])
        if (err.response.data['audio']) {
            if (err.response.data['audio'][0].indexOf("データが存在しません。") != -1) {
                errorvalue.push("アップロードした音源は別のタブから削除されました")
            } else if (err.response.data['audio'][0].indexOf("一意でなければなりません。") != -1) {
                errorvalue.push("この音源はすでに投稿されています") 
            }
        }
        self.setState({
            errorBarOpen: true,
            errorBarText: errorvalue,
            isUploading: false,
        })
    });

}