import React from 'react';
import PlaylistMusicItem from './PlaylistMusicItem'

export default class PlaylistMusics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            musics: this.props.musics,
            isOwner: this.props.isOwner
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.musics !== prevProps.musics) {
            this.setState({ musics: this.props.musics })
        }
        if (this.props.isOwner !== prevProps.isOwner) {
            this.setState({ isOwner: this.props.isOwner })
        }
    }

    render() {
        const nums = [...Array(this.state.musics.length)].map((_, i) => i)
        return (
            <>
                {nums.map((i) => (
                    <PlaylistMusicItem
                        music={this.state.musics[i]}
                        classes={this.props.classes}
                        num={i}
                        count={this.props.count}
                        PlayOnClick={this.props.PlayOnClick}
                        DeleteOnClick={this.props.DeleteOnClick}
                        history={this.props.history}
                        isOwner={this.state.isOwner}
                    />
                ))}
            </>
        )
    }

}