import React from 'react';

//material-ui
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';


const OffsetSelect = (props) => {
    return (
        <OffsetSelectBox>
            Pages:
            <Select
                className={props.classes.smallSelectBox}
                onChange={props.onOffsetChange}
                value={props.offset}
            >
                {[...Array(props.len)].map((_, i) => i).map((item) => (
                    <MenuItem key={item} value={item + 1}>
                        {item + 1}
                    </MenuItem>
                ))}
            </Select>
        </OffsetSelectBox>
    )
}
export default OffsetSelect


const OffsetSelectBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(0, 2),
    }
})

const OffsetSelectBox = withStyles(OffsetSelectBoxStyles)(Box);