import React from 'react';

//material-ui
import SearchIcon from '@material-ui/icons/Search';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

//my component
import OrderButton from './OrderButton'
import SearchButton from './SearchButton'


export default class SearchTypeButtonArea extends React.Component {
    constructor(props) {
        super(props);
        let search
        let tagSearch
        console.log(this.props.url)
        if (this.props.url === "search") {
            search = true
            tagSearch = false
        } else {
            search = false
            tagSearch = true
        }
        this.state = {
            search: search,
            tagSearch: tagSearch,
        };
    }

    render() {
        return (
            <>
                <SearchButton aria-label="keyword" component="span" variant="contained" size="small" onClick={() => { this.props.onSearchClick() }} disabled={this.state.search}>
                    <SearchIcon size="small" />
                </SearchButton>
                <SearchButton aria-label="keyword" component="span" variant="contained" size="small" onClick={() => { this.props.onTagSearchClick() }} disabled={this.state.tagSearch}>
                    <LocalOfferIcon size="small" />
                </SearchButton>
                <OrderButton
                    classes={this.props.classes}
                    order={this.props.order}
                    onSelectChange={this.props.onSelectChange}
                />
            </>
        )
    }
}
