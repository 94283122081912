import React from 'react';
import axios from 'axios'

//material-ui
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//my component
import MarginedButton from '../common/MarginedButton'
import { DialogTitle, DialogContent } from '../common/DialogParts'
import { DialogOpen, DialogClose } from '../../action/common/DialogAction'

//setting
import { API_ROOT_URL } from "../../setting"

export default class PlaylistButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.username,
            musicId: this.props.musicId,
            playlist: [],
            playlistSelected: "new",
            open: false,
            name: "",
            successBarOpen: false,
            errorBarOpen: false,
            errorText: ""
        }
        this.GetPlaylist()
    }
    componentDidUpdate(prevProps) {
        if (this.props.username !== prevProps.username) {
            this.GetPlaylist()
        }
        if (this.props.musicId !== prevProps.musicId) {
            this.setState({ musicId: this.props.musicId })
        }
    }
    async GetPlaylist() {
        await (axios.get(API_ROOT_URL + "user/" + this.props.username + "/", {}))
            .then(res => {
                this.setState({
                    playlist: res.data.playlist,
                })
            }).catch(() => {
            })

    }

    PlaylistSelect = (event) => {
        this.setState(
            {
                playlistSelected: event.target.value
            })
    }

    onRegistClick = () => {
        if (this.state.playlistSelected === "") {
            return
        } else if (this.state.playlistSelected !== "new") {
            this.PlaylistRegist(this.state.playlistSelected)
        } else {
            this.PlaylistAddDialogOpen()
        }
    }

    OnChangeText = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    OnSubmitClick = () => {
        const params = new FormData()
        params.append("name", this.state.name)
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.post(API_ROOT_URL + "playlist/",
            params
        ).then(res => {
            const list = this.state.playlist.slice(0, this.state.playlist.length)
            list.push(res.data)
            this.setState({
                playlist: list,
            })
            this.PlaylistRegist(res.data.id)
            DialogClose(this)
        }).catch(err => {
            this.setState({
                errorText: "プレイリストの作成に失敗しました。同じ名前のプレイリストがないか確認してください。",
                errorBarOpen: true,
            })
        });

    }

    PlaylistRegist = (id) => {
        const params = new FormData()
        params.append("music", this.state.musicId)
        params.append("playlist", id)

        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.post(API_ROOT_URL + "playlistmusic/",
            params
        ).then(res => {
            this.setState({
                successBarOpen: true,
            })
        }).catch(err => {
            this.setState({
                errorText: "プレイリストへの登録に失敗しました。このプレイリストにはすでに登録されています。",
                errorBarOpen: true,
            })
        });
    }
    PlaylistAddDialogOpen = () => {
        DialogOpen(this)
    }

    SuccessBarClose = () => {
        this.setState({ successBarOpen: false })
    }
    ErrorBarClose = () => {
        this.setState({ errorBarOpen: false })
    }


    render() {
        return (
            <>
                {this.props.isLoggedIn ? <>
                    <MarginedButton variant="contained" size="small" onClick={this.onRegistClick}>
                        プレイリスト登録
                    </MarginedButton>
                    <Select variant="filled" className={this.props.classes.select} onChange={this.PlaylistSelect} autoWidth={true} value={this.state.playlistSelected}>
                        <MenuItem value="new" key="new">[新しいプレイリストを作成]</MenuItem>
                        {this.state.playlist.map((play) => (
                            <MenuItem value={play.id} key={play.id} name={play.name}>{play.name}</MenuItem>
                        ))}
                    </Select>
                    <Dialog onClose={() => DialogClose(this)} open={this.state.open}>
                        <DialogTitle onClose={() => DialogClose(this)}>
                            新しいプレイリストの作成
                        </DialogTitle>
                        <DialogContent dividers>
                            <TextField
                                label="新しいプレイリストの名前"
                                name="name"
                                helperText="100文字以内"
                                required
                                fullWidth
                                inputProps={{ maxLength: 100 }}
                                onChange={this.OnChangeText}
                            />
                            <br />
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={this.OnSubmitClick}
                            >
                                プレイリストを作成して登録
                            </Button>
                            <br />
                        </DialogContent>
                    </Dialog>
                    <Snackbar open={this.state.successBarOpen} autoHideDuration={6000} onClose={this.SuccessBarClose}>
                        <MuiAlert elevation={6} severity="success">プレイリストに登録しました</MuiAlert>
                    </Snackbar>
                    <Snackbar open={this.state.errorBarOpen} autoHideDuration={6000} onClose={this.ErrorBarClose}>
                        <MuiAlert elevation={6} severity="error">{this.state.errorText}</MuiAlert>
                    </Snackbar>
                </> : <>
                    <MarginedButton variant="contained" size="small" disabled>
                        ログインするとプレイリストが利用できます
                    </MarginedButton>
                </>}
            </>
        )
    }
}
