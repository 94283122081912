import React from 'react';

//material-ui
import Typography from '@material-ui/core/Typography';


export function GetDate(datetime) {
    const year = datetime.slice(0, 4)
    const month = datetime.slice(5, 7)
    const day = datetime.slice(8, 10)
    return (year + "/" + month + "/" + day)
}


export function GetContent(contents){
    const words = contents.split("<br>")
    return (
        <Typography variant="body2">
            {words.map((word) => (
                <>
                    {word}
                    <br />
                </>
            ))}
        </Typography>
    )
}