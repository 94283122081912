import React from 'react';
import axios from 'axios';


//material-ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

//acction
import { GetDate, GetContent } from '../../action/news/NewsAction'

//setting
import { API_ROOT_URL } from "../../setting"

export default class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = { news: [] }
        this.GetNews()
    }
    async GetNews() {
        await (axios.get(API_ROOT_URL + "news/", {}))
            .then(res => {
                this.setState({ news: res.data })
            })
    }

    OnClick = (id) => {
        this.props.history.push('/news/' + id)
    }

    GetTopNews = () => {
        if (this.state.news.length === 0) {
            return (<></>)
        }
        const date = GetDate(this.state.news[0].make_date)
        return (
            <>
                <NewsBox lineHeight={2}>
                    <Link color="inherit" variant="subtitle1" href={'/news/' + this.state.news[0].id} >
                        {date}　{this.state.news[0].title}
                    </Link>
                    <NewsBox lineHeight={2}>
                        <Typography variant="body2">
                            {GetContent(this.state.news[0].contents)}
                        </Typography>
                    </NewsBox>
                </NewsBox>
                <Divider />
            </>
        )
    }


    render() {
        return (
            <>
                <Typography variant="h6" className={this.props.classes.title}>
                    {this.props.title}
                </Typography>
                {this.GetTopNews()}
                <Typography variant="h6" className={this.props.classes.title}>
                    最近のお知らせ
                </Typography>
                
                <NewsBox lineHeight={2}>
                    {this.state.news.slice(1, 4).map((news) => (
                        <>
                            <Link color="inherit" href={'/news/' + news.id} className={this.props.classes.newsLink}>
                                {GetDate(news.make_date)}　{news.title}
                            </Link>
                            <br />
                        </>
                    ))}
                </NewsBox>
                <Divider />
                <Link color="inherit" variant="h6" href={'/news/'} className={this.props.classes.title}>
                    過去のお知らせ一覧へ
                    </Link>
            </>
        )
    }
}
const NewsBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 1, 1, 2),
        padding: theme.spacing(1),

    }
})

const NewsBox = withStyles(NewsBoxStyles)(Box);