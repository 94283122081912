import axios from 'axios';

//setting
import { API_ROOT_URL } from "../../setting"

export async function creditUpload(self, musicId) {
    let params
    let i
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    for (i = 0; i < self.state.creditsCount; i++) {
        params = new FormData()
        if (self.state.credits[i].type === 'None') {
            continue
        }
        if (self.state.credits[i].name === '') {
            continue
        }
        params.append("type", self.state.credits[i].type)
        params.append("name", self.state.credits[i].name)
        if (self.state.credits[i].id !== 'None') {
            params.append("music", musicId)
            await (axios.put(API_ROOT_URL + "credit/" + self.state.credits[i].id+ "/",
                params
            )).then(res => {
            }).catch(err => {
            });
        } else {
            await (axios.post(API_ROOT_URL + "creditmusic/" + musicId + "/",
                params
            )).then(res => {
            }).catch(err => {
            });
        }
    }
    return 
}