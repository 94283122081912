import React from 'react';


//material-ui
import { Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import IconButton from '@material-ui/core/IconButton';

import CreditsHelp from './CreditsHelp'

export default class CreditsField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items !== this.props.items) {
            this.setState({ items: this.props.items })
        }
    }
    render() {
        return (
            <>
                <Typography variant="body2">
                    クレジットは検索には利用されません。<br />
                    <Typography variant="body1" color="secondary" style={{ display: 'inline' }}>検索への配慮を要する作品でも必ず適切に設定してください。</Typography>
                    <br /><br />
                    クレジットは、権利者から問い合わせを受けた際運営団体により活用されます。<br />
                    <Typography variant="body1" color="secondary" style={{ display: 'inline' }}>権利関係が曖昧/グレーな内容は必ず設定してください。</Typography><br /><br />
                    <Typography variant="body1" color="secondary" style={{ display: 'inline' }}>ボーカルの権利関係が曖昧な場合、キャラクター名と原作名を併記する等、界隈外の人が見てもわかる内容を設定してください。</Typography><br /><br />
                    <Typography variant="body1" color="secondary" style={{ display: 'inline' }}>クレジットの表記内で、いわゆる検索除けのような記述をしないでください</Typography><br /><br />
                    JASRAC信託曲は<Typography variant="body1" color="secondary" style={{ display: 'inline' }}>可能な限り</Typography>管理番号を登録してください。<br />
                    管理番号の確認は<a href="http://www2.jasrac.or.jp/eJwid/" target="_blank">コチラ</a>
                    <br /><br />
                </Typography>
                <CreditsHelp classes={this.props.classes} />
                {this.state.items}

                <Typography variant='caption'>
                    クレジット増減：
                    <IconButton onClick={this.props.onAdd} >
                        <AddCircleOutlineIcon />
                    </IconButton>
                    <IconButton onClick={this.props.onRemove} >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Typography>
            </>
        );

    }
}