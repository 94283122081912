import React from 'react';

//material-ui
import TextField from '@material-ui/core/TextField';

const TitleField = (props) => {
    return (
        <TextField
            id="standard-basic"
            label="タイトル"
            name="title"
            helperText="100文字以内"
            required
            fullWidth
            inputProps={{ maxLength: 100 }}
            onChange={props.onTextChange}
            value={props.value}
        />
    )
}
export default TitleField