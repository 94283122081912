import React from 'react';

//material-ui
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import green from '@material-ui/core/colors/green';

//my component
import MarginedButton from '../common/MarginedButton'

const PlaylistMusicItem = (props) => {
    function GetTitle(title) {
        if (title.length <= 32) {
            return (title)
        }
        return (title.slice(0, 31) + "…")
    }

    function GetDeleteButton() {
        if (props.isOwner) {
            return (
                <>
                    <MarginedButton
                        variant="contained"
                        onClick={() => { props.DeleteOnClick(props.music.id) }}
                    >
                        削除
                    </MarginedButton>
                </>
            )
        } else {
            return (<></>)
        }
    }

    function GetIcon() {
        if (props.num === props.count) {
            return (<><PlayCircleFilledIcon htmlColor={green[500]} /></>)
        } else {
            return (<><PlayCircleFilledIcon /></>)
        }
    }

    return (
        <>
            <div className={props.classes.playlistMusicItemRoot}>
                <IconButton onClick={() => { props.PlayOnClick(props.num) }} >
                    {GetIcon()}
                </IconButton>
                <Link
                    color="inherit"
                    className={props.classes.playlistMusicItemTypography}
                    onClick={() => { props.PlayOnClick(props.num) }}
                >
                    {GetTitle(props.music.title)}
                </Link>
                <Box>
                    <MarginedButton
                        variant="contained"
                        href={"/music/" + props.music.url}
                    >
                        音源ページ
                    </MarginedButton>
                    {GetDeleteButton()}
                </Box>
            </div>
            <Divider />
        </>
    )
}
export default PlaylistMusicItem