import React from 'react';
import axios from 'axios';

//material-ui
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { DropzoneDialogBase } from 'material-ui-dropzone'
import { Divider, Box } from '@material-ui/core';

import MarginedButton from '../common/MarginedButton';
import { DialogTitle, DialogContent } from '../common/DialogParts'
//setting
import { API_ROOT_URL, SITE_TITLE, ROOT_URL } from "../../setting"

export default class UserImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: null,
            target: -1,
            keyword: "",
            credit_name: "",
            require_vocal: "",
            dialogOpen: false,
            newImage: [],
            newImageDialogOpen: false,
            errorBarOpen: false,
            infoBarOpen: false,
            errorBarText: "",
            infoBarText: "",
        }

        this.GetImages(API_ROOT_URL + "myimage/")
    }

    GetImages = async (url) => {
        await (axios.get(url)).then(res => {
            this.setState({
                images: res.data.results,
            })
            if (res.data.next !== null) {
                this.AddImages(res.data.next)
            }
        })
    }
    AddImages = async (url) => {
        await (axios.get(url)).then(res => {
            const img = this.state.images.concat(res.data.results)
            this.setState({
                images: img,
            })
            if (res.data.next !== null) {
                this.AddImages(res.data.next)
            }
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    UnPublish = async (index) => {
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        await (axios.patch(API_ROOT_URL + 'imagefile/' + this.state.images[index].id + '/', { publish: false }))
            .then(res => {
                const images = this.state.images.slice()
                images[index].publish = false
                this.setState({ infoBarOpen: true, infoBarText: "この画像を自分専用にしました", images: images })
            }).catch(err => {
                this.setState({ errorBarOpen: true, errorBarText: "操作に失敗しました。時間を空けて再度お試しください" })
            })

    }

    Publish = async () => {
        if (this.state.keyword === "") {
            this.setState({ errorBarOpen: true, errorBarText: "キーワードの設定がされていません。キーワードを設定してください。" })
            return
        }
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        await (axios.patch(API_ROOT_URL + 'imagefile/' + this.state.images[this.state.target].id + '/', {
            publish: true,
            keyword: this.state.keyword,
            credit_name: this.state.credit_name,
            require_vocal: this.state.require_vocal,
        }))
            .then(res => {
                const images = this.state.images.slice()
                images[this.state.target].publish = true
                images[this.state.target].keyword = this.state.keyword
                images[this.state.target].credit_name = this.state.credit_name
                images[this.state.target].require_vocal = this.state.require_vocal
                this.setState({ infoBarOpen: true, infoBarText: "この画像を共用画像に設定しました", dialogOpen: false, target: -1, keyword: "", images: images  })
            }).catch(err => {
                this.setState({ errorBarOpen: true, errorBarText: "操作に失敗しました。時間を空けて再度お試しください" })
            })

    }

    UploadImage = async (files) => {
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        const params = new FormData()
        params.append("file_path", files[0].file)
        axios.post(API_ROOT_URL + "imagefile/",
            params,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            }
        )
            .then(res => {
                console.log(res.data)
                const images = this.state.images.slice()
                images.push(res.data)
                images[images.length - 1].file_path = ROOT_URL + "Media/img/" + images[images.length - 1].id+".png"
                this.setState({
                    infoBarOpen: true,
                    infoBarText: "画像を投稿しました",
                    newImageDialogOpen: false,
                    images: images 
                })
            }).catch(err => {
                this.setState({
                    errorBarOpen: true,
                    errorBarText: "画像の投稿に失敗しました。"
                })
            });

    }

    render() {
        return (
            <>
                <Typography variant="h6" className={this.props.classes.title}>
                    投稿した画像一覧
                </Typography>
                <Typography variant="body2">サムネイルとして活用されていない画像は、サーバー整理時に削除される場合があります。</Typography>
                <MarginedButton
                    variant="contained"
                    color="inherit"
                    fullWidth
                    onClick={() => { this.setState({newImageDialogOpen:true}) }}
                >
                    サムネイル用画像を投稿する
                </MarginedButton>

                {this.state.images === null ? <LinearProgress />
                    : this.state.images.length === 0 ? <Typography>投稿画像がありません。</Typography>
                        : this.state.images.map((img, i) => (<>
                            <Box style={{ width: '100%', display: 'flex', justifyContents: 'space-between', alignContent: 'center' }}>
                                <Box style={{ display: 'flex', justifyContents: 'space-between', alignContent: 'center', padding: 8 }}>
                                    {img.publish ? <PublicIcon /> : <LockIcon />}
                                    <img src={img.file_path} style={{ width: 80, height: 80 }} />
                                </Box>
                                <Box style={{ flexGrow: 1, padding: 8 }}>
                                    <Typography variant="body2" style={{ width: '100%' }}>キーワード：{img.keyword}</Typography>
                                    <Typography variant="body2" style={{ width: '100%' }}>クレジット：{img.credit_name}</Typography>
                                    <Typography variant="body2" style={{ width: '100%' }}>ボーカル制限：{(img.require_vocal === null || img.require_vocal === "") ? "無し" : img.require_vocal}</Typography>
                                    <Typography variant="body2" style={{ width: '100%' }}>使用数：{img.link_count}</Typography>
                                </Box>
                                <Box style={{ padding: 8 }}>
                                    {img.publish
                                        ? <MarginedButton variant="contained" color="inherit" onClick={() => { this.UnPublish(i) }}>自分専用にする</MarginedButton>
                                        : <MarginedButton variant="contained" color="inherit" onClick={() => {
                                            this.setState({
                                                target: i,
                                                dialogOpen: true,
                                                keyword: img.keyword,
                                                credit_name: img.credit_name,
                                                require_vocal: img.require_vocal
                                            })
                                        }}>共有する</MarginedButton>}
                                </Box>
                            </Box>
                            <Divider />
                        </>))}
                <Snackbar open={this.state.infoBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ infoBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="info">{this.state.infoBarText}</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.errorBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ errorBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="error">{this.state.errorBarText}</MuiAlert>
                </Snackbar>
                <Dialog onClose={() => this.setState({ dialogOpen: false })} ari-labelledby="customized-dialog-title" open={this.state.dialogOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.setState({ dialogOpen: false })}>
                        画像の共有設定
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variang="body2">
                            共有設定した画像は、ほかのユーザーが音楽を投稿する際のサムネイルに使用できます。<br />
                            他のユーザーが検索する際のキーワードを設定してください。<br />
                        </Typography>
                        <br />
                        <TextField
                            label="検索用キーワード"
                            name="keyword"
                            value={this.state.keyword}
                            onChange={this.onChange}
                            fullWidth
                            helperText="30文字以内"
                            required
                            inputProps={{ maxLength: 30 }}
                        />
                        <br />
                        <br />
                        <TextField
                            label="クレジット"
                            name="credit_name"
                            value={this.state.credit_name}
                            onChange={this.onChange}
                            fullWidth
                            helperText={<>この画像を他の人が使用され場合、ここに登録した名義でクレジットされます。<br />空欄の場合、アカウント名が自動で利用されます<br />30文字以内</>}
                            inputProps={{ maxLength: 30 }}
                        />
                        <br />
                        <br />
                        <TextField
                            label="ボーカル制限"
                            name="require_vocal"
                            value={this.state.require_vocal}
                            onChange={this.onChange}
                            fullWidth
                            helperText={<>この画像を他の人が使用する際の条件を表示することができます。<br />複数入力する場合「,」で区切ってください<br />空欄の場合、無条件で使用できます<br />100文字以内</>}
                            inputProps={{ maxLength: 100 }}
                        />
                        <br />
                        <br />
                        <MarginedButton
                            variant="outlined"
                            fullWidth
                            onClick={() => { this.Publish() }}
                        >
                            この画像を共有する
                        </MarginedButton>
                        <br />
                    </DialogContent>
                </Dialog>
                <DropzoneDialogBase
                    open={this.state.newImageDialogOpen}
                    showPreviews={true}
                    maxFileSize={100 * 1024 * 1024}
                    onAdd={newFileObjs => { this.UploadImage(newFileObjs) }}
                    onSave={this.UploadImage}
                    onClose={() => this.setState({ newImageDialogOpen: false })}
                    filesLimit={1}
                    fileObjects={this.state.newImage}
                    dialogTitle="サムネイル画像投稿"
                    submitButtonText="アップロード"
                    cancelButtonText="キャンセル"
                    dropzoneText={<>"画像ファイル(jpg,gif,tiff,png)<br />1024 x 1024 pixelに圧縮されます(100MB以内)"</>}
                />
            </>)
    }
}

