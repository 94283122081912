import React from 'react';
import axios from 'axios'
import { withRouter } from 'react-router-dom';

//material-ui
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TwitterIcon from '@material-ui/icons/Twitter';


//setting
import { API_ROOT_URL } from "../../setting"
import { Avatar } from '@material-ui/core';
import MarginedButton from '../common/MarginedButton';


class TitleArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: document.getElementById('username').innerText,
            title: this.props.data.title,
            id: this.props.data.id,
            src: "",
            open: false
        }
        this.GetIcon()
    }
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                title: this.props.data.title,
                id: this.props.data.id
            })
            this.GetIcon()
        }
    }

    async GetIcon() {
        await (axios.get(API_ROOT_URL + "icon/" + this.props.data.owner + "/", {}))
            .then((res) => {
                this.setState({ src: res.data.icon })
            })
    }

    render() {
        return (
            <>

                <Box className={this.props.classes.boxRoot}>
                    <Box className={this.props.classes.titleBox}>
                        <Typography variant="h6">
                            {this.state.title}
                        </Typography><br />
                    </Box>
                    <Box className={this.props.classes.ownerBox}>
                        <UserButtonBase
                            variant="outlined"
                            color="primary"
                            startIcon={<Avatar src={this.state.src} alt="icon" variant="square" />}
                            href={"/user/" + this.props.data.owner}
                        >
                            {this.props.data.owner}
                        </UserButtonBase>
                        {this.props.data.with_twitter &&
                            <MarginedButton
                                startIcon={<TwitterIcon />}
                                variant="contained"
                                style={{ textTransform: 'none', backgroundColor: '#1DA1F2', color: '#FFFFFF' }}
                                small
                                href={"https://twitter.com/" + this.props.data.owner}
                            >
                                @{this.props.data.owner}
                            </MarginedButton>
                        }
                    </Box>
                </Box>

                <Divider />
            </>
        )
    }
}
export default withRouter(TitleArea)

const UserButtonStyles = (theme) => ({
    root: {
        margin: theme.spacing(1),
        textTransform: 'none',
    }
})

export const UserButtonBase = withStyles(UserButtonStyles)(Button);