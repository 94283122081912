import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

//material-ui
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

//my component
import { useStyles } from '../style/common'
import MusicDetailForm from './uploader/MusicDetailForm'
import DropzoneArea from './common/DropZoneArea/DropzoneArea'
import MarginedButton from './common/MarginedButton'
import RootBox from './common/RootBox'

//setting
import { API_ROOT_URL,SITE_TITLE } from "../setting"

function Uploader(props) {
    const classes = useStyles();
    function moveMusic(id) {
        props.history.push("/music/" + id)
    }
    return (
        <>
            <Helmet>
                <title>{SITE_TITLE} -アップロード-</title>
            </Helmet>
            <RootBox>
                <Paper className={classes.userBlock}>
                    <MusicUploadForm
                        classes={classes}
                        moveMusic={moveMusic}
                    />
                </Paper>
            </RootBox>
        </>
    )
}

export default withRouter(Uploader)

class MusicUploadForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            forms: <></>,
            musicDropZoneText: "オーディオファイル(aac,wav,mp3,ogg,aiff) 100MBまで",
            errorBarOpen: false,
            encodeDialogOpen: false,
            errorBarText: "",
            cache: {},
            cacheDialogOpen: false,
        };
        this.CheckCache()
    }

    async CheckCache() {
        await (axios.get(API_ROOT_URL + "audiocache/", {}))
            .then(res => {
                this.setState({
                    cache: res.data,
                    cacheDialogOpen: true
                })
            })
    }

    componentWillMount() {
        this.setState(
            {
                forms:
                    <>
                        <MusicUploadDropzone
                            classes={this.props.classes}
                            musicDropZoneChange={this.musicDropZoneChange}
                            musicDropZoneText={this.state.musicDropZoneText}
                        />
                        投稿すると<Link href="/rule/" target="_blank">利用規約</Link>に同意したとみなします。<br />
                    JASRAC信託曲も投稿できます。<br />
                    原盤(CDに収録されている音源、DL販売で購入した音源)や原盤を加工したもの(カラオケに歌をmixしたもの含む)は投稿できません。
                    </>
            }
        )
    }
    musicDropZoneChange = (files) => {
        if (files.length === 0) {
            return
        }
        const params = new FormData()
        params.append("file_path", files[0])
        this.setState({ encodeDialogOpen: true })
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.post(API_ROOT_URL + "audiofile/",
            params,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            }
        )
            .then(res => {
                this.setState(
                    {
                        files: files,
                        musicId: res.data.id,
                        encodeDialogOpen: false,
                        forms:
                            <>
                                <MusicDetailForm
                                    classes={this.props.classes}
                                    audioId={res.data.id}
                                    audioName={files[0].name}
                                    moveMusic={this.props.moveMusic}
                                />
                            </>
                    }
                )
            }).catch(err => {
                console.log(err)
                this.setState({
                    encodeDialogOpen: false,
                    errorBarOpen: true,
                    errorBarText: "この音源はすでに投稿されています。"
                })
            });

    }

    ContinueOnClick = () => {
        this.setState(
            {
                musicId: this.state.cache.id,
                cacheDialogOpen: false,
                forms:
                    <>
                        <MusicDetailForm
                            classes={this.props.classes}
                            audioId={this.state.cache.id}
                            audioName={this.state.cache.upload_date}
                            moveMusic={this.props.moveMusic}
                        />
                    </>
            }
        )
    }
    DeleteOnClick = () => {
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.delete(API_ROOT_URL + "audiofile/" + this.state.cache.id + "/")
        this.setState({ cacheDialogOpen: false, })
    }

    render() {
        return (
            <>
                {this.state.forms}
                <Snackbar open={this.state.errorBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ errorBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="error">{this.state.errorBarText}</MuiAlert>
                </Snackbar>
                <Dialog open={this.state.encodeDialogOpen}>
                    <DialogContent className={this.props.classes.progressDialog}>
                        <CircularProgress size={200} /><br />
                    エンコード中です。<br />しばらくお待ちください。
                    </DialogContent>
                </Dialog>
                <Dialog open={this.state.cacheDialogOpen}>
                    <DialogContent className={this.props.classes.progressDialog}>
                        <Typography variant="h6">
                            投稿中の音源が見つかりました。<br />
                            再開しますか?
                        </Typography>
                        <Typography variant="body2">
                            ※ 別のタグで投稿作業中の場合、削除をすると投稿に失敗します。
                        </Typography><br /><br />
                        <Divider /><br />
                        <Typography variant="body2">
                            投稿日時：{this.state.cache.upload_date}
                        </Typography><br />
                        <MarginedButton fullWidth variant="outlined" onClick={this.ContinueOnClick}>
                            再開する
                        </MarginedButton>
                        <MarginedButton fullWidth variant="outlined" onClick={this.DeleteOnClick}>
                            削除する
                        </MarginedButton>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

class MusicUploadDropzone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            musicId: -1
        };
    }
    render() {
        return (
            <>
                <DropzoneArea
                    onChange={this.props.musicDropZoneChange.bind(this)}
                    acceptedFiles={['audio/x-m4a', 'audio/mp4', 'audio/aac', 'audio/wav', 'audio/mpeg', 'audio/ogg','audio/x-wav','audio/x-aiff','audio/aiff']}
                    maxFileSize={100 * 1024 * 1024}
                    filesLimit={1}
                    dropzoneText={this.props.musicDropZoneText}
                />
            </>
        );
    }

}
