import React from 'react';


//material-ui
import HelpIcon from '@material-ui/icons/Help';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SimpleTable from '../common/SimpleTable'
import Link from '@material-ui/core/Link';

//my component
import { DialogTitle, DialogContent } from '../common/DialogParts'
import CreditButton from '../player/CreditButton'
import { DialogOpen, DialogClose } from '../../action/common/DialogAction'

export default class CreditsHelp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    render() {
        return (
            <>

                <Button
                    onClick={() => DialogOpen(this)}
                    variant="outlined"
                >
                    クレジットについて詳細を表示する
                </Button>
                <Dialog onClose={() => DialogClose(this)} ari-labelledby="customized-dialog-title" open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={() => DialogClose(this)}>
                        クレジットの表記について
                    </DialogTitle>
                    <DialogContent dividers>
                        詳細は<Link href="/guideline" target="_blank">ガイドライン</Link>をご覧ください。
                        <SimpleTable
                            align="center"
                            header={['種類', '内容']}
                            body={[
                                ["曲名", '投稿コンテンツの楽曲名'],
                                ["ボーカル", <>投稿コンテンツを歌唱しているボーカル<br />セリフや文章読み上げの場合は話者</>],
                                ["作曲", '投稿コンテンツの作曲者'],
                                ["作詞", '投稿コンテンツの作詞者'],
                                ["編曲", '投稿コンテンツの編曲者'],
                                ["採譜", 'カバー等で採譜を行った人が投稿者と異なる'],
                                [<><span style={{ whiteSpace: 'nowrap' }}>調声/</span><span style={{ whiteSpace: 'nowrap' }}>演奏/</span>MIX</>, '上記以外の音源作成に協力した人'],
                                ["サムネイル", 'サムネイル画像およびその素材の作成'],
                                ["JASRAC管理番号", 'JASRAC信託作品の管理番号'],
                            ]}
                        />
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}
