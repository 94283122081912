import React from 'react';

//material-ui
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

//my component
import TagValueArea from './TagValueArea'
import TagAreaIcon from './TagAreaIcon'
import TagEditTextField from './TagEditTextField'


export default class TagArea extends React.Component {
    constructor(props) {
        super(props);
        const editArea = this.GetEditArea()
        this.state = {
            editArea: editArea
        }
    }

    GetEditArea = () => {
        let editArea
        if (this.props.edit) {
            editArea =
                <TagEditTextField
                    value={this.props.tagArea}
                    onTagsChange={this.props.onTagsChange}
                    onTagKeyDown={this.props.onTagKeyDown}
                />

        } else {
            editArea = <></>
        }
        return editArea
    }

    componentDidUpdate(prevProps) {
        if ((this.props.edit !== prevProps.edit) ||
            (this.props.tags !== prevProps.tags) ||
            (this.props.tagArea !== prevProps.tagArea)) {
            const editArea = this.GetEditArea()
            this.setState({ editArea: editArea })
        }
    }

    render() {
        return (
            <>
                <Typography variant="h6" className={this.props.classes.title}>
                    {this.props.title}
                    <TagAreaIcon
                        lock={this.props.lock}
                        edit={this.props.edit}
                        classes={this.props.classes}
                        onTagsEditClick={this.props.onTagsEditClick}
                        onTagsEditClose={this.props.onTagsEditClose}
                    />
                </Typography>
                <TagValueArea
                    tags={this.props.tags}
                    onDeleteClick={this.props.onTagDeleteClick}
                    edit={this.props.edit}
                    value={this.props.tagArea}//更新を子コンポーネントに伝えるためだけのprops

                />
                {this.state.editArea}
            </>
        )
    }
}

TagArea.defaultProps = {
    title: "タグ"
}
