import { fade, makeStyles, createMuiTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        flexGrow: 1,
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    logo: {
        color: 'inherit',
        whiteSpace: 'nowrap',
        minWidth: '15ch',
    },
    twitterIcon: {
        verticalAlign: 'middle',
    },
    userName: {
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    listroot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        '& > *': {
            padding: theme.spacing(1),
            margin: theme.spacing(1),
        },
    },
    userPageRoot: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 1),
            width: theme.spacing(90),
            maxWidth: '95%',
        },
    },
    button: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    block: {
        display: 'block',
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        justifyContent: 'center'
    },
    paper: {
        display: 'inline',
        padding: theme.spacing(1),
        width: theme.spacing(30),
        height: theme.spacing(42),
        textTransform: 'none',
    },
    dummyPaper: {
        display: 'inline',
        padding: theme.spacing(1),
        width: theme.spacing(30),
        height: theme.spacing(42),
        textTransform: 'none',
        cursor: 'default',
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&:active": {
            backgroundColor: "transparent",
        }
    },
    title: {
        padding: theme.spacing(1),
        flexGrow: 1,
    },
    thumb: {
        width: '100%',
    },
    formRoot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& > span': {
            margin: theme.spacing(2),
        },
    },
    selectBox: {
        margin: theme.spacing(2),
        width: '25ch',
    },
    smallSelectBox: {
        margin: theme.spacing(1),
    },
    userPageIcon: {
        margin: theme.spacing(2, 3),
        padding: theme.spacing(1),
        width: theme.spacing(30),
        height: theme.spacing(30),
        objectFit: 'contain',

    },
    userBlock: {
        display: 'block',
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        justifyContent: 'center',
        width: theme.spacing(184),
        maxWidth: '100%'
    },
    playlistRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            padding: theme.spacing(1),
            margin: theme.spacing(1),
        },

    },
    playlistThumb: {
        width: theme.spacing(30),
        margin: theme.spacing(2),
    },
    playlistImgBox: {
        width: theme.spacing(32)
    },
    playlistPlayerBox: {
        width: theme.spacing(64),
        maxWidth: '100%'
    },
    playlistPlayer: {
        width: "90%",
        margin: theme.spacing(2),
    },
    playlistMusicItemRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        '& > *': {
            padding: theme.spacing(1),
            margin: theme.spacing(1),
        },
        justifyContent: "space-between",

    },
    playlistMusicItemTypography: {
        flexGrow: 1,
    },
    IconMargin: {
        verticalAlign: 'middle',
    },
    footer: {
        width: '100%',
        flexGrow: 1,
        bottom: 0,
        verticalAlign: 'middle',
    },
    footerBox: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: "space-between",
        alignItems: 'center',
    },
    footerLink: {
        padding: theme.spacing(1)
    },
    footerSubBox: {
        textAlign: 'center',
    },
    progressDialog: {
        overflow: 'hidden',
    },
    creditsBox: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    creditLabel: {
        margin: theme.spacing(1),
        width: '100%',
    },
    creditTypeField: {
        width: theme.spacing(40),
        maxWidth: '100%',
        '& > *': {
            padding: theme.spacing(1),
            margin: theme.spacing(1),
        },
    },
    creditNameField: {
        flexGrow: 1,
        '& > *': {
            padding: theme.spacing(1),
            margin: theme.spacing(1),
        },
    },
    newsLink: {
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}));