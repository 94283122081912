import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";


//my component
import { useStyles } from '../style/common'
import Box from '@material-ui/core/Box';
import RootBox from './common/RootBox'
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import RuleTabs from './RuleTabs'
//setting
import { SITE_TITLE } from "../setting"

const Guideline = (props) => {
    const classes = useStyles()


    return (
        <RootBox>
            <Helmet>
                <title>{SITE_TITLE} -ガイドライン-</title>
            </Helmet>
            <Paper className={classes.userBlock} id="music">
                <Breadcrumbs>
                    <Link
                        color="inherit"
                        onClick={() => { props.history.push("/") }}
                    >
                        TOP
                    </Link>
                    <Typography>
                        ガイドライン
                    </Typography>
                </Breadcrumbs>
                <Divider />
                <RuleTabs
                    value={3}
                />
                <RuleBox>
                    <Typography variant="h6">
                        このサイトについて
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ul>
                                <li>とある歌声合成ファンが個人1名で開発・運営をしているサービスです。</li>
                                <li>β版につき、頻繁に仕様変更や試行錯誤を行っております。</li>
                                <li>お申し出への対応も含めて、継続が困難と判断した場合にはサービスを終了します。</li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        音楽を投稿するには
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ul>
                                <li>twitterでログイン後、<Link onClick={() => { props.history.push("/upload") }}>こちらのリンク</Link>から音源を投稿できます。</li>
                                <li>投稿できるデータ形式は下記のとおりです。<br />
                                    データ形式：aac,wav,mp3,ogg<br />
                                    ファイルサイズ：100MB以下</li>
                                <li>音源は投稿時自動でエンコードされます。</li>
                                <li>当サイトはJASRACとの包絡契約を締結しておりますので、JASRAC管理楽曲が投稿できます。<br />
                                    なお、包絡契約により使用が許諾されるのは楽曲事態であり、視聴用に販売されているCDに同梱されているカラオケ音源(原盤)の使用はできません。</li>
                                <li>オリジナル楽曲もしくは、インターネット上で配信を行う権利を個人が有している楽曲について、各権利者の意向の範囲内で投稿してください。</li>
                                <li>1度投稿したデータは、削除できません(非公開にはできます)。</li>
                                <li>1度投稿したデータと同じデータは、同一アカウントから投稿できません。</li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        クイックアップロードとは
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            音楽を投稿する際に、
                            <Typography variant="body2" color="secondary" style={{ display: 'inline' }}>タイトルと説明文だけを設定して投稿</Typography>することができます。<br />
                            すぐに投稿したいときに便利ですが、以下2点に注意してください。
                            <ul>
                                <li>サムネイルは投稿時にしか設定できません。</li>
                                <li>投稿後7日以内にクレジット情報を入力しない場合、自動で非公開となります。</li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        サムネイルを設定するには
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ul>
                                <li>音源投稿時、サムネイルを設定することができます。</li>
                                <li>投稿できるデータ形式は下記のとおりです。<br />
                                    データ形式：jpg,gif,tiff,png<br />
                                    ファイルサイズ：100MB以下</li>
                                <li>投稿した画像は1,024 x 1,024pixelに圧縮されます。</li>
                                <li><Typography variant="body2" color="secondary" style={{ display: 'inline' }}>サムネイルは投稿時にしか設定できません。</Typography></li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        公開設定/プライベート設定
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ul>
                                <li>「公開」のチェックを外した音源は投稿者以外は閲覧をすることができません。</li>
                                <li>「urlを知っている人のみに公開する」にチェックを付けた音源は、新着音源一覧/検索画面/ユーザーページ等に表示されません。</li>
                                <li>公開設定は投稿後もいつでも編集できます。</li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        クレジット/オリジナル宣言とは
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ul>
                                <li>「クレジット」とは、投稿した作品のうち投稿者以外が作成した内容を明記する機能です。<br />
                            検索には利用されませんので、検索に配慮を要する作品であっても
                            <Typography variant="body2" color="secondary" style={{ display: 'inline' }}>必ず適切に設定してください。</Typography></li>
                                <li>投稿者以外にクレジットすべき権利者がいない作品の場合、投稿時に「この作品には投稿主以外にクレジットが必要な権利者はいません」をチェックしてください。</li>
                                <li>上記のいずれも設定されていない音源は、
                                    <Typography variant="body2" color="secondary" style={{ display: 'inline' }}>投稿から7日後自動で非公開となります。</Typography></li>
                                <li>「他者のコンテンツを自作発言する」、「明らかに適正ではないクレジットを設定する」等の行為を行った場合、音源・アカウントを削除する場合があります。</li>
                                <li>クレジットは投稿後もいつでも編集できます。</li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        クレジットの種類について
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            クレジットは以下の8種類をそれぞれ必要数設定できます。<br />
                            いずれも設定内容は目安であり、権利者がクレジットを不要としている場合、掲載の義務はありません。<br />
                            歌以外のコンテンツ(インスト、セリフ、文章の読み上げ等)の場合、下記内容に準じてください。<br /><br />
                            クレジットは、権利者から問い合わせを受けた際運営団体により活用されます。<br />
                            <Typography variant="body2" color="secondary" style={{ display: 'inline' }}>権利関係が曖昧/グレーな内容は必ず設定してください。</Typography><br /><br />
                            <Typography variant="body1" color="secondary" style={{ display: 'inline' }}>クレジットの表記内で、いわゆる検索除けのような記述をしないでください</Typography><br /><br />
                            <ul>
                                <li>曲名：<br />
                                    自作曲でない場合、曲名を記入してください。<br /><br />
                                </li>
                                <li>ボーカル：<br />
                                    人間が歌唱している曲の場合、その方の活動名義を設定してください。<br />
                                    音声合成の場合、その音声ライブラリの名称を設定してください<br />
                                    ただし表情ライブラリー等の種類まで記載する必要はありません
                                    <br /><br />
                                    <Typography variant="body2" color="secondary" style={{ display: 'inline' }}>権利関係が曖昧な音声を使う場合、キャラクター名と原作名を併記する等、界隈外の人が見てもわかる内容を設定してください。</Typography>
                                    <br /><br />
                                </li>
                                <li>作曲：<br />
                                    投稿楽曲を作曲した方の活動名義を設定してください。<br /><br />
                                </li>
                                <li>作詞：<br />
                                    投稿楽曲を作詞した方の活動名義を設定してください。<br />
                                    ただし、作曲者と同一の場合省略しても構いません。<br /><br />
                                </li>
                                <li>編曲：<br />
                                    投稿楽曲を編曲した方の活動名義を設定してください。<br />
                                    ただし、作曲者と同一の場合省略しても構いません。<br /><br />
                                </li>
                                <li>採譜：<br />
                                    midiやvsq,ust等の楽譜ファイルを投稿主以外が作成した場合設定してください。<br /><br />
                                </li>
                                <li>調声・演奏・MIXなど：<br />
                                    音声合成におけるボーカルエディットや、楽器の演奏、Mix、マスタリング等、投稿主以外かつ上記に該当しない形で音源制作に携わった人の名義を設定してください。<br /><br />
                                </li>
                                <li>サムネイル：<br />
                                    投稿楽曲に設定したサムネイルを投稿主以外が作成した場合、こちらに名義を設定してください。<br />
                                    静画の作成にあたり、クレジットが必要な素材を使用した場合もこちらに名義を設定してください。
                                </li>
                                <li>JASRAC管理番号：<br />
                                    投稿楽曲がJASRACに信託されている場合登録してください。<br />
                                    楽曲名と作者名が正しく登録されていれば、登録は義務ではありませんが、登録いただけると非常に助かります。<br /><br />
                                </li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        タグとは
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ul>
                                <li>各音源に対し、検索で使用するタグを設定できます。</li>
                                <li>タグ検索は完全一致検索です。</li>
                                <li>各ユーザが設定できる「お気に入りタグ」に合致するタグが設定されている場合、そのユーザのTOPページに表示されます。</li>
                                <li>コンテンツの内容と著しく乖離するタグは、検索妨害になるため設定しないようにしてください。</li>
                                <li>タグは投稿者以外でもログインしている利用者であれば、だれでも編集できます。</li>
                                <li>投稿者以外にタグを編集してほしくない場合、投稿時に「タグロック」をチェックしてください。</li>
                                <li>「タグロック」の設定は投稿後いつでも編集できます。</li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        イイネとは
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ul>
                                <li>音楽再生ページにあるハートマークのアイコンをクリックすることで、投稿者にイイネすることができます。</li>
                                <li>イイネされた数は、音源ページに表示されますが、どのユーザがどの音源をイイネしたかは公開されません。</li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        プレイリストとは
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ul>
                                <li>気に入った音源をプレイリストに登録することで、連続再生をすることができます。</li>
                                <li>プレイリストは、自身のユーザーページから確認できます。</li>
                                <li>プレイリストを公開設定すると、自身のプレイリストを他のユーザも閲覧・連続再生できるようになります。<br />
                                ただし、非公開音源・プライベート設定された音源等は、投稿した本人以外には表示されません。</li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        権利侵害の報告とは
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ul>
                                <li>もしご自身のコンテンツが、当該コンテンツの利用規約の範囲を超えて利用されている場合、音源ページ下部のボタンより、権利侵害の申請ができます。</li>
                                <li>権利侵害の申請を頂いた場合、いたずら防止のため本人確認を実施させていただきます。</li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                    <Typography variant="h6">
                        権利侵害以外の違反報告について
                    </Typography>
                    <RuleSubBox>
                        <Typography variant="body2">
                            <ul>
                                <li>下部リンクより、公式TwitterもしくはサポートDiscordまでご連絡ください。</li>
                            </ul>
                        </Typography>
                    </RuleSubBox>
                </RuleBox>
            </Paper>
        </RootBox>
    )

}
export default withRouter(Guideline)

const RuleBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(2),
    }
})

const RuleBox = withStyles(RuleBoxStyles)(Box);

const RuleSubBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 2, 4),
    }
})

const RuleSubBox = withStyles(RuleSubBoxStyles)(Box);


const RuleDateBoxStyles = (theme) => ({
    root: {
        textAlign: 'right',
    }
})

const RuleDateBox = withStyles(RuleDateBoxStyles)(Box);