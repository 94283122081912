import React from 'react';
import { withRouter } from 'react-router-dom';

import Link from '@material-ui/core/Link';
//material-ui
import TagButtonBase from '../common/TagButtonBase'
import { MoveTag } from '../../action/player/LockedTagButtonAction'

const LockedTagButton = (props) => {
    return (
        <Link href={"/tag/" + props.value}>
            <TagButtonBase
                variant="contained"
                id={"tag_" + props.tagId}
                label={props.value}
            />
        </Link>
    )

}

export default withRouter(LockedTagButton)