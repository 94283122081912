import React from 'react';

//material-ui
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

export default class OrderButton extends React.Component {
    constructor(props) {
        super(props);
        let value
        if (this.props.order === "upload_date") {
            value = "投稿が古い順"
        } else if (this.props.order === "-view_count") {
            value = "再生数が多い順"
        } else if (this.props.order === "view_count") {
            value = "再生数が少ない順"
        } else {
            value = "投稿が新しい順"
        }
        this.state = {
            value: value,
        };
    }

    render() {
        const items = [
            {
                value: "-upload_date",
                label: "投稿が新しい順",
            },
            {
                value: "upload_date",
                label: "投稿が古い順",
            },
            {
                value: "-view_count",
                label: "再生数が多い順",
            },
            {
                value: "view_count",
                label: "再生数が少ない順",
            },
        ]
        return (
            <>
                <FormControl>
                    <Select
                        className={this.props.classes.selectBox}
                        onChange={this.props.onSelectChange}
                        value={this.props.order}
                    >

                        {items.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>
        )
    }
}