import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";


//my component
import PlayerPaper from './player/PlayerPaper'
import Player from './player/Player'
import NotFoundBar from './player/NotFoundBar'

//action
import {
    onTagsEditClick,
    onTagsEditClose,
    TagsDeleteClick,
    onTagsChange,
    onTagsEditKeyDown,
} from '../action/player/TagEditAction'

//setting
import { API_ROOT_URL, SITE_TITLE } from "../setting"

class MusicPlayer extends React.Component {
    constructor(props) {
        super(props);
        const { params } = this.props.match
        this.state = {
            id: params.id,
            tags: [],
            data: {},
            tagsEdit: false,
            tag_lock: false,
            tagArea: "",
            notFoundBar:false,
        }
        axios.get(API_ROOT_URL + "music/" + this.state.id + "/", {})
            .then(res => {
                this.setState({
                    data: res.data,
                    tags: res.data.tag,
                    tag_lock: res.data.tag_lock,
                    id: res.data.id,
                })
            }).catch(() => {
                this.notFoundBarOpen()
            })
    }

    notFoundBarOpen = () => {
        this.setState({notFoundBar:true})
    }
    notFoundBarClose = () => {
        this.setState({ notFoundBar: false })
        this.props.history.push("/")
    }

    onTagsEditClick = () => {
        onTagsEditClick(this)
    }
    onTagsEditClose = () => {
        onTagsEditClose(this)
    }

    onTagsChange = (event) => {
        onTagsChange(event, this, this.state.tags, this.state.id)
    }

    onTagsEditKeyDown = (event) => {
        onTagsEditKeyDown(this, event, this.state.id)
    }

    TagsDeleteClick = (event) => {
        TagsDeleteClick(event, this, this.state.tags)
    }
    render() {
        return (
            <>
                <PlayerPaper>
                    <Helmet>
                        <title>{SITE_TITLE} -{this.state.data.title}-</title>
                    </Helmet>
                    <Player
                        tags={this.state.tags}
                        data={this.state.data}
                        tagsEdit={this.state.tagsEdit}
                        tag_lock={this.state.tag_lock}
                        tagArea={this.state.tagArea}
                        onTagsEditClick={this.onTagsEditClick}
                        onTagsEditClose={this.onTagsEditClose}
                        onTagsChange={this.onTagsChange}
                        onTagDeleteClick={this.TagsDeleteClick}
                        onTagsEditKeyDown={this.onTagsEditKeyDown}
                        history={this.props.history}
                    />
                </PlayerPaper>
                <NotFoundBar open={this.state.notFoundBar} handleClose={this.notFoundBarClose}/>
            </>
        )
    }
}

export default withRouter(MusicPlayer)
