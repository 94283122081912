import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";


//my component
import { useStyles } from '../style/common'
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MusicDetailForm from './uploader/MusicDetailForm'
import RootBox from './common/RootBox'

//setting
import { API_ROOT_URL, SITE_TITLE } from "../setting"

const MusicEdit = (props) => {
    const classes = useStyles();
    const { params } = props.match
    function moveMusic(id) {
        props.history.push("/music/" + id)
    }
    return (
        <RootBox>
            <Helmet>
                <title>{SITE_TITLE} -編集画面-</title>
            </Helmet>
            <Paper className={classes.userBlock}>
                <MusicEditBase
                    classes={classes}
                    musicId={params.id}
                    history={props.history}
                    moveMusic={moveMusic}
                />
            </Paper>
        </RootBox>
    )
}
export default withRouter(MusicEdit)

class MusicEditBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            visible: false,
            errorBarOpen: false,
            errorBarText: "",
        }
        this.GetMusic()
    }

    async GetMusic() {
        await (axios.get(API_ROOT_URL + "music/" + this.props.musicId + "/", {}))
            .then(res => {
                if (!res.data.is_owner) {
                    this.props.history.push("/music/" + this.props.musicId)
                }
                const credits = []
                res.data.credit.forEach(credit => {
                    if (credit.id !== 0) {
                        credits.push(credit)
                    }
                })
                this.setState({
                    data: res.data,
                    credit: credits,
                    visible: true
                })
            }).catch(() => {
                this.setState({
                    errorBarOpen: true,
                    errorBarText: "この音源は存在しません",
                })
            })
    }

    errorBarClose = () => {
        this.setState({ errorBarOpen: false })
        this.props.history.push("/")
    }
    GetView = () => {
        if (this.state.visible) {
            console.log(this.state.data.is_music)
            return (
                <>
                    <MusicDetailForm
                        classes={this.props.classes}
                        audioId={this.state.data.audio}
                        audioName={this.state.data.title}
                        moveMusic={this.props.moveMusic}
                        title={this.state.data.title}
                        official_title={this.state.data.official_title}
                        description={this.state.data.description}
                        tags={this.state.data.tag}
                        creditValue={this.state.credit}
                        publish={this.state.data.publish}
                        private={this.state.data.private}
                        tag_lock={this.state.data.tag_lock}
                        is_music={this.state.data.is_music}
                        original={this.state.data.original}
                        imageId={this.state.data.image}
                        musicId={this.state.data.url}
                        tagEditId={this.state.data.id}
                        isUpdate={true}
                    />
                </>
            )
        } else {
            return (<></>)
        }
    }

    render() {
        return (
            <>
                {this.GetView()}
                <Snackbar open={this.state.errorBarOpen} autoHideDuration={6000} onClose={() => { this.errorBarClose() }}>
                    <MuiAlert elevation={6} severity="error">{this.state.errorBarText}</MuiAlert>
                </Snackbar>
            </>
        )
    }
}