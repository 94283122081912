import React from 'react';

//material-ui
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import blueGrey from '@material-ui/core/colors/blueGrey';
import purple from '@material-ui/core/colors/purple';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import GroupIcon from '@material-ui/icons/Group';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';



import TileAd from './TileAd'
//setting
import { ROOT_URL, LIMIT_DATE } from "../../setting"

export default class MusicListDev extends React.Component {
    constructor(props) {
        super(props);
        this.embed = []
        let temp
        for (let i = 0; i < this.props.tileData.length; i++) {
            temp = (Math.random() < 0.05)
            this.embed.push(temp)
        }
        this.state = {
            tileData: this.props.tileData,
        };
        this.ads = 0
        this.count = 0
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tileData !== this.props.tileData) {
            this.embed = []
            let temp
            for (let i = 0; i < this.props.tileData.length; i++) {
                temp = (Math.random() < 0.05)
                this.embed.push(temp)
            }
            this.setState({
                tileData: this.props.tileData,
            })
        }
    }
    GetTitle = (title) => {
        if (title.length <= 26) {
            return (title)
        } else {
            return (title.slice(0, 25) + "…")
        }
    }
    GetBgi = (publish, isPrivate) => {
        if (publish) {
            if (isPrivate) {
                return (purple[50])
            } else {
                return (blueGrey[0])
            }
        } else {
            return (blueGrey[50])
        }
    }
    IsMusicPublish = (music) => {
        if (music.original) {
            return (music.publish)
        } else if (music.credit_count > 0) {
            return (music.publish)
        }
        const limitDate = new Date(
            music.upload_date.slice(0, 4),
            parseInt(music.upload_date.slice(5, 7)) - 1,
            parseInt(music.upload_date.slice(8, 10)) + LIMIT_DATE,
            music.upload_date.slice(11, 13),
            music.upload_date.slice(14, 16),
            music.upload_date.slice(17, 19),
        )
        if (limitDate.getTime() > Date.now()) {
            return (music.publish)
        } else {
            return (false)
        }
    }

    GetNotPublish = (publish, isPrivate) => {
        if (publish) {
            if (isPrivate) {
                return (
                    <>
                        <Tooltip title="この音源はurlを知っている人だけに公開されます">
                            <GroupIcon />
                        </Tooltip>
                    </>
                )
            } else {
                return (<></>)
            }
        } else {
            return (
                <>
                    <Tooltip title="この音源は非公開です">
                        <VisibilityOffIcon />
                    </Tooltip>
                </>
            )
        }
    }

    EmbedAd = () => {
        console.log(this.embed)
        console.log(this.embed[this.count])
        console.log(this.count)
        if (this.embed[this.count]) {
            this.count++
            this.ads++
            return (<TileAd />)
        } else {
            this.count++
            return (<></>)
        }
    }

    render() {
        this.ads = 0
        this.count = 0
        return (
            <>
                <MusicListBox className={this.props.classes.listroot}>
                    {this.props.tileData.map((tile) => (
                        <>
                            <Button key={tile.image_path}
                                className={this.props.classes.paper}
                                href={"/music/" + tile.url}
                                style={{ backgroundColor: this.GetBgi(this.IsMusicPublish(tile), tile.private) }}
                                variant="outlined"
                            >
                                <img src={ROOT_URL + "Media/" + tile.image_path} alt={tile.title} className={this.props.classes.thumb} /><br />
                                {this.GetNotPublish(this.IsMusicPublish(tile), tile.private)}
                                <Typography variant="subtitle1">
                                    {this.GetTitle(tile.title)}
                                </Typography>
                            </Button>
                            {this.EmbedAd()}
                        </>
                    ))}
                    <Dummies
                        length={this.props.tileData.length + this.ads}
                        classes={this.props.classes}
                    />
                </MusicListBox>
            </>

        );
    }
}

class Dummies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        };
        window.addEventListener('resize', () => {
            this.onResize()
        });
    }
    onResize = () => {
        this.setState({ width: window.innerWidth })
    }
    render() {
        let len = 5
        if (this.state.width < 665) {
            len = 1
        } else if (this.state.width < 921) {
            len = 2
        } else if (this.state.width < 1177) {
            len = 3
        } else if (this.state.width < 1433) {
            len = 4
        }
        const num = len - ((this.props.length) % len)
        if (num !== len) {
            return (
                <>
                    {[...Array(num)].map((_, i) => i).map((item) => (
                        <Button
                            className={this.props.classes.dummyPaper}
                            disableRipple
                            disableFocusRipple
                        >
                        </Button>
                    ))}
                </>
            )

        } else {
            return (<></>)
        }
    }
}

const MusicListBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 1, 1, 2),
        padding: theme.spacing(1),
    }
})

const MusicListBox = withStyles(MusicListBoxStyles)(Box);