import React from 'react';

//material-ui
import TagButtonBase from '../common/TagButtonBase'
import CloseIcon from '@material-ui/icons/Close';

const UnLockedTagButton=(props) => {
    return (
        <TagButtonBase
            icon={
                <CloseIcon
                    id={"tag_" + props.tagId + " " + props.value}
                    onClick={props.onDeleteClick}
                />
            }
            variant="contained"
            label={props.value}
        / >
    )

}

export default UnLockedTagButton