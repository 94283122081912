import React from 'react';


//material-ui
import TextField from '@material-ui/core/TextField';


//my component
import TagButton from './TagButton'

const TagField = (props) => {
    return (
        <>
            {props.tags.map((tagValue) => (
                <TagButton
                    value={tagValue}
                    classes={props.classes}
                    onTagDeleteClick={props.onTagDeleteClick}
                />
            ))}
            <TextField
                label="タグ"
                name="tag"
                helperText="検索に使用します(完全一致) 1タグあたり15文字 半角スペース/全角スペース区切り Enterで確定 入力欄空欄時Backspaceで最後のタグ削除"
                fullWidth
                inputProps={{ maxLength: 1024 }}
                value={props.value}
                onChange={props.onTagsChange}
                onKeyDown={props.onTagKeyDown}
            />
        </>
    )
}

export default TagField