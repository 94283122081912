import React from 'react';

//material-ui
import TextField from '@material-ui/core/TextField';

const AudioField = (props) => {
    return (
        <TextField
            id="standard-basic"
            label="アップロードしたファイル"
            name="audio"
            defaultValue={props.value}
            helperText="(ファイル名は保存されません)"
            required
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            disabled
        />
    )
}
export default AudioField