import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

//material-ui
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//my component
import { useStyles } from '../style/common'
import PlaylistMusics from './playlist/PlaylistMusics'
import PlaylistPlayer from './playlist/PlaylistPlayer'
import PlaylistOwnerDialog from './playlist/PlaylistOwnerDialog'
import PlaylistDeleteDialog from './playlist/PlaylistDeleteDialog'
import RootBox from './common/RootBox'
import WideAd from './common/WideAd'

//setting
import { API_ROOT_URL, LIMIT_DATE, SITE_TITLE, ROOT_URL, SITE_NAME } from "../setting"

const PlayList = (props) => {
    const classes = useStyles()
    const { params } = props.match
    const loggedInUser = document.getElementById('username').innerText
    return (
        <RootBox>
            <Paper className={classes.userBlock}>
                <PlayListBase
                    classes={classes}
                    playlistId={params.id}
                    history={props.history}
                    loggedInUser={loggedInUser}
                />
            </Paper>
        </RootBox>
    )
}


export default withRouter(PlayList)

class PlayListBase extends React.Component {
    constructor(props) {
        super(props);
        let volume = localStorage.getItem("volume")
        if (volume === null) {
            volume = 0.5
        }
        const ua = window.navigator.userAgent.toLowerCase();
        const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
        this.state = {
            loggedInUser: this.props.loggedInUser,
            playlist: {},
            musics: [],
            music: {},
            playCounter: 0,
            name: "",
            publish: false,
            successBarOpen: false,
            errorBarOpen: false,
            successBarText: "",
            errorBarText: "",
            isiOS: isiOS,
            loop: false,
            playlistLoop: true,
            volume: volume,
            didShuffle: false,
            OneLoopBarOpen: false,
            LoopBarOpen: false,
            UnLoopBarOpen: false,
            ShuffleBarOpen: false,
            shareButton: <></>,
        }
        this.GetPlayLists()
    }
    async GetPlayLists() {
        await (axios.get(API_ROOT_URL + "playlist/" + this.props.playlistId + "/", {}))
            .then(res => {
                const musics = res.data.playlist_music.map((playlist_music) => playlist_music.music)
                const filterMusics = musics.filter(music => this.IsMusicPublish(music))
                const ids = filterMusics.map((music) => music.id)
                console.log(res.data)
                let music = {}
                if (filterMusics.length !== 0) {
                    music = filterMusics[0]
                }
                this.setState({
                    playlist: res.data,
                    musics: filterMusics,
                    music: music,
                    playCounter: 0,
                    atFirst: true,
                    name: res.data.name,
                    publish: res.data.publish,
                    ids: ids,
                })
            }).catch(error => {
                this.setState({
                    errorBarOpen: true,
                    errorBarText: "読込に失敗しました",
                })
            })
    }


    IsMusicPublish = (music) => {
        if (music.is_owner) {
            return (true)
        }
        if (music.original) {
            return (music.publish && !music.private)
        } else if (music.credit_count > 0) {
            return (music.publish && !music.private)
        }
        const limitDate = new Date(
            music.upload_date.slice(0, 4),
            parseInt(music.upload_date.slice(5, 7)) - 1,
            parseInt(music.upload_date.slice(8, 10)) + LIMIT_DATE,
            music.upload_date.slice(11, 13),
            music.upload_date.slice(14, 16),
            music.upload_date.slice(17, 19),
        )
        if (limitDate.getTime() > Date.now()) {
            return (music.publish && !music.private)
        } else {
            return (false)
        }
    }


    PlayerOnEnded = () => {
        let playerId
        if (this.state.isiOS) {
            playerId = "playlist_" + this.state.ids[this.state.playCounter]
        } else {
            playerId = "react-hls-playlist"
        }
        if (this.state.loop) {
            document.getElementById(playerId).currentTime = 0
            document.getElementById(playerId).play()
        } else if ((this.state.playCounter >= this.state.musics.length - 1) && (this.state.playlistLoop)) {
            this.setState({
                music: this.state.musics[0],
                playCounter: 0,
            })
        } else if ((this.state.playCounter < this.state.musics.length - 1)) {
            const count = this.state.playCounter + 1
            this.setState({
                music: this.state.musics[count],
                playCounter: count,
            })
        }
    }

    PlayerOnPlay = () => {
        let playerId
        if (this.state.isiOS) {
            playerId = "playlist_" + this.state.ids[this.state.playCounter]
        } else {
            playerId = "react-hls-playlist"
        }
        if (document.getElementById(playerId).currentTime === 0) {
            axios.defaults.xsrfCookieName = 'csrftoken'
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            axios.put(API_ROOT_URL + "musiccount/" + this.state.music.url + "/", {})
        }
    }

    PlayerOnLoadedData = (event) => {
        if (this.state.atFirst) {
            event.target.volume = this.state.volume
            this.setState({ atFirst: false })
        } else {
            event.target.play()
        }
    }
    onVolumeChange = (event) => {
        this.setState({ volume: event.target.volume })
        localStorage.setItem('volume', event.target.volume)
    }

    ShuffleOnClick = () => {
        let playerId
        let newList
        if (this.state.isiOS) {
            playerId = "playlist_" + this.state.ids[this.state.playCounter]
        } else {
            playerId = "react-hls-playlist"
        }
        if (document.getElementById(playerId).paused) {
            newList = this.state.musics.slice()
            newList.sort(() => Math.random() - 0.5)
        } else {
            let tmpList
            if (this.state.playCounter !== 0) {
                tmpList = this.state.musics.slice(0, this.state.playCounter).concat(this.state.musics.slice(this.state.playCounter + 1))
            } else {
                tmpList = this.state.musics.slice(this.state.playCounter + 1)
            }
            tmpList.sort(() => Math.random() - 0.5)
            newList = [this.state.musics[this.state.playCounter]].concat(tmpList)
            console.log(newList)
        }
        const ids = newList.map((music) => music.id)
        this.setState({
            musics: newList,
            music: newList[0],
            playCounter: 0,
            ids: ids,
            didShuffle: this.state.playCounter !== 0,
            OneLoopBarOpen: false,
            LoopBarOpen: false,
            UnLoopBarOpen: false,
            ShuffleBarOpen: true,
        })


    }

    DidShuffle = () => {
        this.setState({
            didShuffle: false,
        })
    }

    PlayOnClick = (num) => {
        if (num === this.state.playCounter) {
            let playerId
            if (this.state.isiOS) {
                playerId = "playlist_" + this.state.ids[num]
            } else {
                playerId = "react-hls-playlist"
            }

            if (document.getElementById(playerId).paused) {
                document.getElementById(playerId).play()
            } else {
                document.getElementById(playerId).pause()
            }
        } else {
            this.setState({
                music: this.state.musics[num],
                playCounter: num,
            })
        }
    }

    DeleteOnClick = (musicId) => {
        const playListMusic = this.state.playlist.playlist_music.filter(x => x.music.id === musicId)
        const newMusics = this.state.musics.filter(x => x.id !== musicId)
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.delete(API_ROOT_URL + "playlistmusic/" + playListMusic[0].id + "/", {})
        this.setState({
            musics: newMusics,
            successBarOpen: true,
            successBarText: "プレイリストから削除しました",
        })
    }

    OnChangeText = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }


    SelectChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.checked
            }
        );
    }

    OnSubmitClick = () => {
        const params = new FormData()
        params.append("name", this.state.name)
        params.append("publish", this.state.publish)
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.put(API_ROOT_URL + "playlist/" + this.state.playlist.id + "/",
            params
        ).then(res => {
            this.setState({
                successBarOpen: true,
                successBarText: "変更しました",
            })
        }).catch(err => {
            this.setState({
                errorBarOpen: true,
                errorBarText: "変更に失敗しました",
            })
        });
    }

    PlaylistDeleteClick = () => {
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.delete(API_ROOT_URL + "playlist/" + this.state.playlist.id + "/", {})

        this.setState({
            successBarOpen: true,
            successBarText: "削除しました",
        })
        this.props.history.push("/")
    }

    GetOwnerMenu = () => {
        if (this.state.playlist.is_owner) {
            return (
                <>
                    <PlaylistOwnerDialog
                        name={this.state.name}
                        publish={this.state.publish}
                        OnChangeText={this.OnChangeText}
                        SelectChange={this.SelectChange}
                        OnSubmitClick={this.OnSubmitClick}
                    />
                    <PlaylistDeleteDialog
                        OnSubmitClick={this.PlaylistDeleteClick}
                    />
                    <Divider />
                </>
            )
        } else {
            return (<></>)
        }
    }
    onLoopIconClick = () => {
        if (this.state.loop) {
            this.setState(
                {
                    loop: false,
                    playlistLoop: false,
                    OneLoopBarOpen: false,
                    LoopBarOpen: false,
                    UnLoopBarOpen: true,
                    ShuffleBarOpen: false,
                }
            )
        } else if (this.state.playlistLoop) {
            this.setState(
                {
                    loop: true,
                    playlistLoop: false,
                    OneLoopBarOpen: true,
                    LoopBarOpen: false,
                    UnLoopBarOpen: false,
                    ShuffleBarOpen: false,
                }
            )
        } else {
            this.setState(
                {
                    loop: false,
                    playlistLoop: true,
                    OneLoopBarOpen: false,
                    LoopBarOpen: true,
                    UnLoopBarOpen: false,
                    ShuffleBarOpen: false,
                }
            )
        }
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>{SITE_TITLE} -プレイリスト：{this.state.name}-</title>
                </Helmet>
                <Breadcrumbs>
                    <Link
                        color="inherit"
                        href="/"
                    >
                        TOP
                    </Link>
                    <Link
                        color="inherit"
                        href={"/user/" + this.state.playlist.username}
                    >
                        {this.state.playlist.username}のユーザーページ
                    </Link>
                    <Typography>
                        プレイリスト：{this.state.name}
                    </Typography>
                </Breadcrumbs>
                <Divider />
                {this.GetOwnerMenu()}
                <PlaylistPlayer
                    music={this.state.music}
                    musics={this.state.musics}
                    onPlay={this.PlayerOnPlay}
                    onEnded={this.PlayerOnEnded}
                    onLoadedData={this.PlayerOnLoadedData}
                    ShuffleOnClick={this.ShuffleOnClick}
                    classes={this.props.classes}
                    count={this.state.playCounter}
                    ids={this.state.ids}
                    loop={this.state.loop}
                    playlistLoop={this.state.playlistLoop}
                    onLoopClick={this.onLoopIconClick}
                    onVolumeChange={this.onVolumeChange}
                    didShuffle={this.state.didShuffle}
                    onDidShuffle={this.DidShuffle}
                    playlist={this.state.playlist}
                />

                <Divider />
                <WideAd />
                <PlaylistMusics
                    musics={this.state.musics}
                    count={this.state.playCounter}
                    classes={this.props.classes}
                    PlayOnClick={this.PlayOnClick}
                    DeleteOnClick={this.DeleteOnClick}
                    history={this.props.history}
                    isOwner={this.state.playlist.is_owner}
                />
                <Snackbar open={this.state.successBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ successBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="success">{this.state.successBarText}</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.errorBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ errorBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="error">{this.state.errorBarText}</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.OneLoopBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ OneLoopBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="info">現在再生している曲をループします</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.LoopBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ LoopBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="info">プレイリスト全体をループします</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.UnLoopBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ UnLoopBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="info">ループ再生を解除しました</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.ShuffleBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ ShuffleBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="info">シャッフルしました</MuiAlert>
                </Snackbar>
            </>
        )
    }
}