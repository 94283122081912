import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

//material-ui
import { useStyles } from '../style/common'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import RootBox from './common/RootBox'
//acction
import { GetDate } from '../action/news/NewsAction'

//setting
import { API_ROOT_URL,SITE_TITLE } from '../setting'


const NewsList = (props) => {
    const classes = useStyles()
    return (
        <RootBox>
            <Helmet>
                <title>{SITE_TITLE} -お知らせ-</title>
            </Helmet>
            <Paper className={classes.userBlock}>
                <Breadcrumbs>
                    <Link
                        color="inherit"
                        href="/"
                    >
                        TOP
                    </Link>
                    <Typography>
                        お知らせ一覧
                </Typography>
                </Breadcrumbs>
                <Divider />
                <NewsListContents
                    classes={classes}
                    history={props.history}
                />
            </Paper>
        </RootBox>
    )
}
export default withRouter(NewsList)

class NewsListContents extends React.Component {
    constructor(props) {
        super(props);
        this.state = { news: [] }
        this.GetNews()
    }
    async GetNews() {
        await (axios.get(API_ROOT_URL + "news/", {}))
            .then(res => {
                this.setState({ news: res.data })
            })
    }
    render() {
        return (
            <NewsBox lineHeight={2}>
                {this.state.news.map((news) => (
                    <>
                        <Link color='inherit' href={"/news/" + news.id}>
                            {GetDate(news.make_date)}　{news.title}
                        </Link>
                        <Divider />
                    </>
                ))}
            </NewsBox>
        )
    }

}
const NewsBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 2),
        padding: theme.spacing(1),

    },
})

const NewsBox = withStyles(NewsBoxStyles)(Box);