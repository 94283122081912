import React from 'react';


//material-ui
import HelpIcon from '@material-ui/icons/Help';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

//my component
import { DialogTitle, DialogContent } from '../common/DialogParts'
import CreditButton from './CreditButton'
import { DialogOpen, DialogClose } from '../../action/common/DialogAction'

export default class CreditsHelp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    render() {
        return (
            <>

                <IconButton
                    onClick={() => DialogOpen(this)}
                >
                    <HelpIcon className={this.props.classes.IconMargin} />
                </IconButton>
                <Dialog onClose={() => DialogClose(this)} ari-labelledby="customized-dialog-title" open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={() => DialogClose(this)}>
                        クレジットの表記について
                    </DialogTitle>
                    <DialogContent dividers>
                        <CreditButton type="MN" value="曲名" /><br />
                        <CreditButton type="V" value="ボーカル" /><br />
                        <CreditButton type="M" value="作曲" /><br />
                        <CreditButton type="L" value="作詞" /><br />
                        <CreditButton type="A" value="編曲" /><br />
                        <CreditButton type="G" value="採譜" /><br />
                        <CreditButton type="P" value="調声・演奏・MIXなど" /><br />
                        <CreditButton type="S" value="サムネイル" /><br />
                        <CreditButton type="J" value="JASRAC管理番号" /><br />
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}
