import React from 'react';

//material-ui
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
import { withStyles } from '@material-ui/core/styles';

const UserPlaylistItem = (props) => {
    function GetItem() {
        if ((props.isLoggedin) || (props.publish)) {
            return (
                <UserPlaylistItemBox>
                    {GetIcon()}
                    <Link href={"/playlist/" + props.playListId}>
                        {props.title}
                    </Link>
                    <Divider />
                </UserPlaylistItemBox>
            )
        } else {
            return (<></>)
        }
    }

    function GetIcon() {
        if (props.publish) {
            return (
                <><PublicIcon /></>
            )
        } else {
            return (
                <><LockIcon /></>
            )
        }
    }
    return (
        <>{GetItem()}</>
    )
}
export default UserPlaylistItem

const UserPlaylistItemBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 1, 1, 2),
        padding: theme.spacing(1),
    }
})

const UserPlaylistItemBox = withStyles(UserPlaylistItemBoxStyles)(Box);