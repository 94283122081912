import React from 'react';
import { useEffect } from 'react';
import { useStyles } from '../../style/common'

export default function TileAd(props) {
    const classes = useStyles();
    useEffect(() => {
        if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
            window.adsbygoogle.push({});
        }
    }, [])
    return (
        <ins className={"adsbygoogle" + ' ' + classes.paper}
            data-ad-format="fluid"
            data-ad-layout-key="+1a+s6-1h-2r+au"
            data-ad-client="ca-pub-9233272768468620"
            data-ad-slot="2243372070"
        >
        </ins>
    )
}