import React from 'react';

//material-ui
import TextField from '@material-ui/core/TextField';

const DescriptionField = (props) => {
    return (
        <TextField
            id="standard-basic"
            label="説明文"
            name="description"
            helperText="1024文字以内"
            rows={10}
            required
            fullWidth
            multiline
            inputProps={{ maxLength: 1024 }}
            onChange={props.onTextChange}
            value={props.value}
        />
    )
}
export default DescriptionField