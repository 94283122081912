import React from 'react';

//material-ui
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const OriginalCheck = (props) => {
    return (
        <FormControlLabel
            label="この作品には投稿主以外にクレジットが必要な権利者はいません"
            control={
                <Checkbox
                    name="original"
                    checked={props.original}
                />
            }
            onChange={props.handleChange}
        />
    )
}

export default OriginalCheck