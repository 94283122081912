import React from 'react';


import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

//my component
import SimpleTable from '../common/SimpleTable'

export default class CounterArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewCount: this.props.data.view_count,
            likeCount: this.props.data.like_count,
            playlistCount: this.props.data.playlist_count,
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                viewCount: this.props.data.view_count,
                likeCount: this.props.data.like_count,
                playlistCount: this.props.data.playlist_count,
            })
        }
    }
    render() {
        return (
            <CounterBox>
                <SimpleTable
                    align="center"
                    header={['再生', 'いいね', 'プレイリスト']}
                    body={[[this.state.viewCount, this.state.likeCount, this.state.playlistCount]]}
                />
            </CounterBox>

        )
    }

}

const CounterBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(0, 2),
        padding: theme.spacing(1),
    }
})

const CounterBox = withStyles(CounterBoxStyles)(Box);