import React from 'react';

//material-ui
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//my component
import MarginedButton from '../common/MarginedButton'
import { DialogTitle, DialogContent } from '../common/DialogParts'
import { DialogOpen, DialogClose } from '../../action/common/DialogAction'


export default class PlaylistOwnerDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }
    render() {
        return (
            <>
                <MarginedButton variant="contained" onClick={() => DialogOpen(this)}>
                    プレイリストの編集
                </MarginedButton>
                <Dialog onClose={() => DialogClose(this)} open={this.state.open}>
                    <DialogTitle onClose={() => DialogClose(this)}>
                        プレイリストの編集
                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            label="プレイリストの名前"
                            name="name"
                            helperText="100文字以内"
                            required
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            onChange={this.props.OnChangeText}
                            value={this.props.name}
                        />
                        <FormControlLabel
                            label="公開"
                            control={
                                <Checkbox
                                    name="publish"
                                    checked={this.props.publish}
                                />
                            }
                            onChange={this.props.SelectChange}
                        />
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => { this.props.OnSubmitClick(); DialogClose(this) }}
                        >
                            変更
                        </Button>
                        <br />
                    </DialogContent>
                </Dialog>

            </>
        )
    }

}