import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

import RootBox from './common/RootBox'
import SearchHeader from './search/SearchHeader'
import SearchBody from './search/SearchBody'
import { API_ROOT_URL, SITE_TITLE } from '../setting';


class Search extends React.Component {
    constructor(props) {
        super(props);
        const urlParse = window.location.pathname.split("/").filter(x => x !== "")
        const url = urlParse[0]
        const value = urlParse[1]
        let page = 1
        let order = "-upload_date"
        const getParam = window.location.search.substring(1).split('&')
        getParam.forEach(param => {
            if (param.startsWith("order=")) {
                order = param.replace("order=", "")
            } else if (param.startsWith("page=")) {
                page = param.replace("page=", "")
            }
        })
        this.state = {
            url: url,
            value: value,
            page: page,
            order: order,
            count: 0,
            tileData: [],
        };
        this.getTileData(url, value, page, order)
    }

    componentDidMount() {
        this.unregisterHistoryCallback = this.props.history.listen(() => {
            this.update()
        })
    }

    update() {
        const urlParse = window.location.pathname.split("/").filter(x => x !== "")
        const url = urlParse[0]
        const value = urlParse[1]
        let page = 1
        let order = "-upload_date"
        const getParam = window.location.search.substring(1).split('&')
        getParam.forEach(param => {
            if (param.startsWith("order=")) {
                order = param.replace("order=", "")
            } else if (param.startsWith("page=")) {
                page = param.replace("page=", "")
            }
        })
        this.setState({
            url: url,
            value: value,
            page: page,
            order: order,
            count: 0,
            tileData: [],
        })
        this.getTileData(url, value, page, order)
    }



    getTileData(url, value, page, order) {
        const api = this.getApiPath(url, value, page, order)
        axios.get(api, {}).then((res) => {
            const title = this.setTitle(res, url, value)
            this.setState({
                tileData: res.data.results,
                count: res.data.count,
                title: title,
            })
        })
    }

    setTitle(res, url, value) {
        if (url === "search") {
            return "「" + decodeURI(value) + "」でキーワード検索　結果：" + res.data.count + "件"
        } else {
            return "「" + decodeURI(value) + "」でタグ検索　結果：" + res.data.count + "件"
        }
    }

    getApiPath(url, value, page, order) {
        let api = API_ROOT_URL
        if (url === "search") {
            api = api + url + "/"
        } else {
            api = api + "tagsearch" + "/"
        }
        api = api + value + "/"
        if (order !== "-upload_date") {
            api = api + "?order=" + order
        }
        if (page !== 1) {
            if (order !== "-upload_date") {
                api = api + "&page=" + page
            } else {
                api = api + "?page=" + page
            }
        }
        return (api)

    }

    onSelectChange = (event) => {
        this.setState({
            offset: 0,
            order: event.target.value,
        })
        this.props.history.push("/" + this.state.url + "/" + this.state.value + "?order=" + event.target.value)
    }

    onOffsetChange = (event) => {
        this.setState({
            offset: event.target.value,
        })
        if (this.state.order === "-upload_date") {
            this.props.history.push("/" + this.state.url + "/" + this.state.value + "?page=" + event.target.value)
        } else {
            this.props.history.push("/" + this.state.url + "/" + this.state.value + "?order=" + this.state.order + "&page=" + event.target.value)
        }

    }



    render() {
        return (
            <>
                <Helmet>
                    <title>{SITE_TITLE} -検索画面- {this.state.title}</title>
                </Helmet>
                <RootBox>
                    <SearchHeader
                        url={this.state.url}
                        value={this.state.value}
                        page={this.state.page}
                        order={this.state.order}
                        onSelectChange={this.onSelectChange}
                    />
                    {this.state.tileData.length !== 0 && <>
                        <SearchBody
                            tileData={this.state.tileData}
                            title={this.state.title}
                            onOffsetChange={this.onOffsetChange}
                            offset={this.state.offset}
                            count={this.state.count}
                        />
                    </>}
                </RootBox>
            </>
        )
    }

}



export default withRouter(Search)