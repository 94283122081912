import React from 'react';

//material-ui
import Typography from '@material-ui/core/Typography';

//my component
import MusicList from '../common/MusicList'



export default class MyMusicList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tileData: [] }
    }

    componentDidUpdate(prevProps) {
        if (this.props.tileData !== prevProps.tileData) {
            this.setState({tileData:this.props.tileData})
        }
    }
    render() {
        return (
            <>
                <Typography variant="h6" className={this.props.classes.title}>
                    {this.props.title}
                </Typography>
                <MusicList
                    classes={this.props.classes}
                    tileData={this.state.tileData}
                    musicOnClick={this.props.musicOnClick}
                />
            </>);
    }
}
