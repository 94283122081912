import React from 'react';

//material-ui
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

//my component
import MarginedButton from '../common/MarginedButton'
import { DialogTitle, DialogContent } from '../common/DialogParts'
import { DialogOpen, DialogClose } from '../../action/common/DialogAction'

export default class PlaylistDeleteDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    render() {
        return (
            <>
                <MarginedButton variant="contained" onClick={() => DialogOpen(this)}>
                    プレイリストの削除
                </MarginedButton>
                <Dialog onClose={() => DialogClose(this)} open={this.state.open}>
                    <DialogTitle onClose={() => DialogClose(this)}>
                        プレイリストの削除
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variang="body2">
                            削除したプレイリストは復元できません。<br /><br />
                            本当に削除しますか?<br /><br />
                        </Typography>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => { this.props.OnSubmitClick(); }}
                        >
                            削除
                        </Button>
                        <br />
                    </DialogContent>
                </Dialog>

            </>
        )
    }
}