import React from 'react';

//material-ui
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const PublishCheck = (props) => {
    return (
        <>
            <FormControlLabel
                label="公開"
                control={
                    <Checkbox
                        name="publish"
                        checked={props.publish}
                    />
                }
                onChange={props.handleChange}
            /><br />
            <FormControlLabel
                label="urlを知っている人のみに公開する"
                control={
                    <Checkbox
                        name="private"
                        checked={props.private}
                    />
                }
                onChange={props.handleChange}
                disabled={!props.publish}
            />
        </>
    )
}
export default PublishCheck