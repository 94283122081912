import React from 'react';
import { withRouter } from 'react-router-dom';


//material-ui
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Box from '@material-ui/core/Box';
//my component
import { useStyles } from './style/common'


//setting
import { ROOT_URL, SITE_NAME } from "./setting"

const Footer = (props) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.footer}>
                <Divider />
                <Box className={classes.footerBox}>
                    <Box className={classes.footerSubBox}>
                        <Breadcrumbs className={classes.footerLink}>
                            <Link href="/rule">利用規約</Link>
                            <Link href="/guideline">ガイドライン</Link>
                        </Breadcrumbs>
                        <Breadcrumbs className={classes.footerLink}>
                            <Link href="https://twitter.com/utaloader" target="_blank">公式Twitter</Link>
                            <Link href="https://discord.gg/avY9qbU" target="_blank">サポートDiscord</Link>
                        </Breadcrumbs>
                    </Box>
                    <Box className={classes.footerSubBox}>
                        <Typography variant="body2" className={classes.footerLink}>
                            Ⓒきみがため<br />
                            各作品の権利は各クリエイターに帰属し無断転載を禁じます
                        </Typography>
                    </Box>
                    <Box className={classes.footerSubBox}>
                        <img src={ROOT_URL + "/Media/jasrac.JPG"} style={{ 'width': '50px' }} />
                        <Typography variant="body2" className={classes.footerLink}>
                            JASRAC許諾第9025437001Y31015号
                        </Typography>
                        <img src={ROOT_URL + "/Media/nextone.png"} style={{ 'width': '50px' }} />
                        <Typography variant="body2" className={classes.footerLink}>
                            NexTone許諾番号：ID000007039
                        </Typography>
                    </Box>
                </Box>
                <br />
                <br />
            </div>
        </>
    )
}

export default withRouter(Footer)
