
export function onTagDeleteButtonClick(self, event) {
    let temp = event.target.id.replace("tags_value_", "")
    let tags = self.state.tags.filter(tag => tag !== temp)
    self.setState(
        {
            tags: tags,
        }
    );
}

export function onTagsValueChange(self, event) {
    let temp = event.target.value
    let temps = []
    let tags = []
    if (self.state.tags.length !== 0) {
        tags = self.state.tags.slice(0, self.state.tags.length)
    }
    if (temp.match(" ")) {
        temps = temp.split(" ")
        temp = temps[temps.length - 1]
        for (let value of temps) {
            if ((value !== "") && (tags.indexOf(value) < 0)) {
                tags.push(value.slice(0, 32))
            }
        }
    } else if (temp.match("　")) {
        temps = temp.split("　")
        temp = temps[temps.length - 1]
        for (let value of temps) {
            if ((value !== "") && (tags.indexOf(value) < 0)) {
                tags.push(value.slice(0, 32))
            }
        }
    } else if (temp.length === 33) {
        if ((tags.indexOf(temp))) {
            tags.push(temp.slice(0, 32))
        }
        temp = ""
    }
    self.setState(
        {
            tags: tags,
            tagArea: temp
        }
    );

}

export function onTagKeyDown(self, event) {
    if (event.key === 'Enter') {
        let temp = event.target.value
        let tags = []
        if (self.state.tags.length !== 0) {
            tags = self.state.tags.slice(0, self.state.tags.length)
        }
        tags.push(temp.slice(0, 32))
        temp = ""
        self.setState(
            {
                tags: tags,
                tagArea: temp
            }
        )
    } else if ((event.key === "Backspace") && (event.target.value === "") && (self.state.tags.length !== 0)) {
        let tags = self.state.tags.slice(0, self.state.tags.length - 1)
        self.setState(
            {
                tags: tags,
            }
        )

    }
}