export const ROOT_URL = document.location.protocol + "//" + document.location.host + "/";
//export const ROOT_URL = "https://utaloader.net/";

export const API_ROOT_URL = ROOT_URL + "api/";
export const SITE_NAME = "UTALOADER(β)";
export const SITE_TITLE = SITE_NAME + " twitter連携音楽ストリーミングサービス";
export const LIMIT_DATE = 7
export const LOGIN_URL = ROOT_URL + "login/"
export const TWITTER_LOGIN_URL = ROOT_URL + "api/accounts/twitter/login/"
export const LOGOUT_URL = ROOT_URL + "api/accounts/logout/"
export const IS_ALPHA = false