import React from 'react';

//my component
import Box from '@material-ui/core/Box';
import TagButton from './TagButton'
import { withStyles } from '@material-ui/core/styles';

const TagValueArea = (props) => {
    return (
        <TagValueAreaBox>
            {
                props.tags.map((tag) => (
                    <TagButton
                        value={tag.value}
                        tagId={tag.id}
                        edit={props.edit}
                        onDeleteClick={props.onDeleteClick}
                    />
                ))
            }
        </TagValueAreaBox>
    )
}
export default TagValueArea


const TagValueAreaBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(0,1,1),
        padding: theme.spacing(1),
    }
})

const TagValueAreaBox = withStyles(TagValueAreaBoxStyles)(Box);