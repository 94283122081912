//material-ui
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const BasePaperStyles = (theme) => ({
    root: {
        display: 'block',
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        justifyContent: 'center',
        width: theme.spacing(184),
        maxWidth: '100%'
    }
})

const BasePaper = withStyles(BasePaperStyles)(Paper)
export default BasePaper