import axios from 'axios';

//setting
import { API_ROOT_URL } from "../../setting"


export async function GetLikeMusic(id){
    return await (axios.get(API_ROOT_URL + "likemusic/" + id + "/", {}))
}

export const onFavClick = (self, id) => {
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.post(API_ROOT_URL + "likemusic/" + id + "/",
        {}
    ).then(() => {
        self.setState({ isFavorite:true })
    })
}

export const onFavDelete = (self, id) => {
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.delete(API_ROOT_URL + "likemusic/" + id + "/",
        {}
    )
    self.setState({ isFavorite: false })
}