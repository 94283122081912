import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";



//my component
import { useStyles } from '../style/common'
import RuleTabs from './RuleTabs'
import Box from '@material-ui/core/Box';
import RootBox from './common/RootBox'
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
//setting
import { SITE_TITLE } from "../setting"
import SimpleTable from './common/SimpleTable';

const Appsupport = (props) => {
    const classes = useStyles()


    return (
        <RootBox>
            <Helmet>
                <title>{SITE_TITLE} -アプリサポート-</title>
            </Helmet>
            <Paper className={classes.userBlock} id="music">
                <Breadcrumbs>
                    <Link
                        color="inherit"
                        onClick={() => { props.history.push("/") }}
                    >
                        TOP
                    </Link>
                    <Typography>
                        アプリサポート
                    </Typography>
                </Breadcrumbs>
                <Divider />
                <RuleTabs
                    value={4}
                />
                <RuleBox>
                    <SimpleTable
                        header={["項目", "内容"]}
                        body={[
                            ["開発者", "デルタ＠きみがため"],
                            ["メールサポート", <Link href="mailto://info@utaloader.net">info@utaloader.net</Link>],
                            ["公式twitter", <Link href="https://twitter.com/utaloader">@utaloader</Link>],
                            ["サポートDiscord", <Link href="https://discord.gg/avY9qbU">ウタローダーサポート</Link>]
                        ]}
                    />
                </RuleBox>

            </Paper>
        </RootBox>
    )

}
export default withRouter(Appsupport)

const RuleBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(2),
    }
})

const RuleBox = withStyles(RuleBoxStyles)(Box);