import React from 'react';
import axios from 'axios'
import PropTypes from 'prop-types';

//material-ui
//import { DropzoneArea } from 'material-ui-dropzone'
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

//my component
import TagField from './TagField'
import ImagePreview from './ImagePreview'
import IsMusicCheck from './IsMusicCheck'
import TagLockCheck from './TagLockCheck'
import OriginalCheck from './OriginalCheck'
import CreditFieldItem from './CreditFieldItem'
import CreditsField from './CreditsField'
import PublishCheck from './PublishCheck'
import AudioField from './AudioField'
import DescriptionField from './DescriptionField'
import TitleField from './TitleField'
import OfficialTitleField from './OfficialTitleField'
import TagArea from '../player/TagArea'
import DropzoneArea from '../common/DropZoneArea/DropzoneArea'

//setting
import { API_ROOT_URL, ROOT_URL } from "../../setting"

//Action
import { imageDropZoneChange } from '../../action/uploader/ImageDropZoneAction'
import { QuickUpload } from '../../action/uploader/QuickUploadAction'
import { Upload } from '../../action/uploader/UploadAction'
import {
    onCreditsAddCircleClick,
    onCreditsRemoveCircleClick,
    onCreditsTypeChange,
    onCreditsNameChange
} from '../../action/uploader/CreditEditAction'
import {
    onTagDeleteButtonClick,
    onTagsValueChange,
    onTagKeyDown,
} from '../../action/uploader/TagEditAction'
import {
    onTagsEditClick,
    onTagsEditClose,
    TagsDeleteClick,
    onTagsChange,
    onTagsEditKeyDown,
    onTagAdd,
    onTagAdds,
} from '../../action/player/TagEditAction'
import ImageList from './ImageList';



class MusicDetailForm extends React.Component {
    constructor(props) {
        super(props);
        let items = []
        let creditValue = [{
            id: 'None',
            type: 'None',
            name: ''
        }, {
            id: 'None',
            type: 'None',
            name: ''
        }, {
            id: 'None',
            type: 'None',
            name: ''
        }]
        let i

        if (this.props.creditValue.length !== 0) {
            creditValue = this.props.creditValue
        }


        for (i = 0; i < creditValue.length; i++) {
            items.push(<CreditFieldItem num={i} onTypeChange={this.onCreditsTypeChange} onNameChange={this.onCreditsNameChange} value={creditValue[i]} classes={this.props.classes} />);
        }
        this.state = {
            title: this.props.title,
            official_title: this.props.official_title,
            description: this.props.description,
            imageId: this.props.imageId,
            tagArea: "",
            tags: this.props.tags,
            creditItems: items,
            credits: creditValue,
            creditsCount: creditValue.length,
            publish: this.props.publish,
            private: this.props.private,
            tag_lock: this.props.tag_lock,
            is_music: !this.props.is_music,
            original: this.props.original,
            successBarOpen: false,
            successBarText: "",
            errorBarOpen: false,
            errorBarText: [],
            isUploading: false,
            imageTabValue: 0,
        }
        if (this.props.imageId !== 1 && this.props.image === undefined) {
            this.GetImage(this.props.imageId)
        }

    }

    GetImage = async (id) => {
        axios.get(API_ROOT_URL + "imagefile/" + id + "/").then(res => {
            this.setState({ image: res.data })
        })
    }

    deleteAudioFile = () => {
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.delete(API_ROOT_URL + "audiofile/" + this.props.audioId + "/", {})
    }


    onTextChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        );

    }
    onTagsChange = (event) => {
        onTagsValueChange(this, event)
    }

    handleChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.checked
            }
        );
    }

    onCreditsAddCircleClick = () => {
        onCreditsAddCircleClick(this)
    }
    onCreditsRemoveCircleClick = () => {
        onCreditsRemoveCircleClick(this)
    }

    onCreditsTypeChange = (event) => {
        onCreditsTypeChange(this, event)
    }

    onCreditsNameChange = (event) => {
        onCreditsNameChange(this, event)
    }


    onTagDeleteButtonClick = (event) => {
        onTagDeleteButtonClick(this, event)
    }

    onTagKeyDown = (event) => {
        onTagKeyDown(this, event)
    }

    onTagsEditKeyDown = (event) => {
        onTagsEditKeyDown(this, event, this.props.tagEditId)
    }

    onTagsEditClick = () => {
        onTagsEditClick(this)
    }
    onTagsEditClose = () => {
        onTagsEditClose(this)
    }

    onTagsEditChange = (event) => {
        onTagsChange(event, this, this.state.tags, this.props.tagEditId)
    }

    TagsDeleteClick = (event) => {
        TagsDeleteClick(event, this, this.state.tags)
    }

    GetCredits = () => {
        if (this.state.original) {
            return (<></>)
        } else {
            return (
                <>
                    <CreditsField
                        classes={this.props.classes}
                        onAdd={this.onCreditsAddCircleClick}
                        onRemove={this.onCreditsRemoveCircleClick}
                        items={this.state.creditItems}
                    />
                </>
            )
        }
    }


    onQuickUploadClick = () => {
        QuickUpload(this)
    }

    onUploadClick = () => {
        Upload(this)
    }

    imageDropZoneChange = (files) => {
        imageDropZoneChange(this, files)
    }

    GetImageDropzoneArea = (imageId, tagValue) => {
        if (tagValue === 0) {
            return (
                <>
                    <DropzoneArea
                        name="image"
                        acceptedFiles={['image/jpeg', 'image/gif', 'image/tiff', 'image/png']}
                        maxFileSize={100 * 1024 * 1024}
                        filesLimit={1}
                        dropzoneText="画像ファイル(jpg,gif,tiff,png) 1024 x 1024 pixelに圧縮されます(100MB以内)"
                        onChange={(files) => { this.imageDropZoneChange(files) }}
                    />

                </>
            )
        }
        else if (tagValue === 1) {
            return (<>
                <ImageList api={"myimage/"} requirekey={false} SetImage={(id) => { this.setState({ imageId: id, imageTabValue: 3 }) }} />
            </>)

        }
        else if (tagValue === 2) {
            return (<>
                <ImageList api={"publishimage/"} requirekey={true} SetImage={(id) => { this.setState({ imageId: id, imageTabValue: 3 }) }} />
            </>)

        } else {
            return (
                <>
                    <ImagePreview
                        imageId={imageId}
                        rotatable={this.state.image === undefined ? false : this.state.image.is_owner}
                    />
                </>
            )
        }
    }

    GetTagArea = () => {
        if (this.props.isUpdate) {
            return (
                <>
                    <TagArea
                        classes={this.props.classes}
                        tags={this.state.tags}
                        tagArea={this.state.tagArea}
                        title="タグ"
                        lock={false}
                        onTagsEditClick={this.onTagsEditClick}
                        onTagsEditClose={this.onTagsEditClose}
                        onTagsChange={this.onTagsEditChange}
                        onTagDeleteClick={this.TagsDeleteClick}
                        onTagKeyDown={this.onTagsEditKeyDown}
                        edit={this.state.tagsEdit}
                    />
                </>
            )
        } else {
            return (
                <>
                    <TagField
                        onTagsChange={this.onTagsChange}
                        onTagDeleteClick={this.onTagDeleteButtonClick}
                        onTagKeyDown={this.onTagKeyDown}
                        value={this.state.tagArea}
                        tags={this.state.tags}
                        classes={this.props.classes}
                    />
                </>
            )
        }
    }


    GetQuickUploadButton = () => {
        if (this.props.isUpdate) {
            return (<></>)
        } else {
            return (
                <>
                    <UploadBox>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={this.onQuickUploadClick}
                            disabled={this.state.isUploading}
                        >
                            クイックアップロード
                    </Button>
                        <Typography variant="body2">
                            以下の設定は無視されます。<br />
                        </Typography>
                    </UploadBox>
                    <br />
                    <br />
                </>
            )
        }
    }

    GetUploadButton = () => {
        if (this.props.isUpdate) {
            return (
                <>
                    <UploadBox>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={this.onUploadClick}
                            disabled={this.state.isUploading}
                        >
                            編集完了
                    </Button>
                    </UploadBox>
                </>
            )
        } else {
            return (
                <>
                    <UploadBox>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={this.onUploadClick}
                            disabled={this.state.isUploading}
                        >
                            アップロード
                    </Button>
                    </UploadBox>
                </>
            )
        }

    }

    onCopyCredit = () => {

        if (this.props.isUpdate) {
            onTagAdds(this, this.state.credits, this.props.tagEditId)
        } else {
            let tags = this.state.tags.slice()
            for (let credit of this.state.credits) {
                console.log(credit)
                if ((tags.indexOf(credit.name) === -1) && credit.name !== "") {
                    tags.push(credit.name)
                }
            }
            this.setState({
                tags: tags
            })
        }
    }

    GetOfficialTitleArea = (is_music) => {
        console.log(is_music)
        if (is_music) {
            return (<></>)
        } else {
            return (
                <>
                    <UploadBox>
                        <OfficialTitleField onTextChange={this.onTextChange} value={this.state.official_title} />
                    </UploadBox>
                </>
            )
        }
    }


    render() {
        return (
            <>
                <form
                    clasName={this.props.classes.formRoot}
                    autoComplete="off"
                >
                    <UploadBox>
                        <AudioField value={this.props.audioName} />
                    </UploadBox>
                    <UploadBox>
                        <TitleField onTextChange={this.onTextChange} value={this.state.title} />
                    </UploadBox>
                    <UploadBox>
                        <DescriptionField onTextChange={this.onTextChange} value={this.state.description} />
                    </UploadBox>
                    <UploadBox>
                        <IsMusicCheck handleChange={this.handleChange} is_music={this.state.is_music} />
                    </UploadBox>
                    {this.GetOfficialTitleArea(this.state.is_music)}
                    <br />
                    <br />
                    {this.GetQuickUploadButton()}
                    <Divider /><br />
                    <Tabs
                        value={this.state.imageTabValue}
                        onChange={(e, newValue) => { this.setState({ imageTabValue: newValue }) }}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        variant='fullWidth'
                    >
                        <Tab label="画像投稿" />
                        <Tab label="自分が投稿した画像" />
                        <Tab label="利用可能画像" />
                        <Tab label="プレビュー" disabled={this.state.imageId === 1} />
                    </Tabs>
                    {this.GetImageDropzoneArea(this.state.imageId, this.state.imageTabValue)}
                    <UploadBox>
                        <PublishCheck publish={this.state.publish} private={this.state.private} handleChange={this.handleChange} />
                    </UploadBox>
                    <Divider />
                    <UploadBox>
                        <OriginalCheck original={this.state.original} handleChange={this.handleChange} />
                        {this.GetCredits()}
                    </UploadBox>
                    <Divider />
                    <UploadBox>
                        {this.GetTagArea()}
                    </UploadBox>
                    <UploadBox>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={this.onCopyCredit}
                        >
                            クレジットからタグを生成
                    </Button>
                    </UploadBox>
                    <UploadBox>
                        <TagLockCheck tag_lock={this.state.tag_lock} handleChange={this.handleChange} />
                    </UploadBox>
                    <br />
                    <br />
                    {this.GetUploadButton()}
                    <br />
                    <br />
                </form>
                <Snackbar open={this.state.successBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ successBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="success">{this.state.successBarText}</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.errorBarOpen} autoHideDuration={6000} onClose={() => { this.setState({ errorBarOpen: false }) }}>
                    <MuiAlert elevation={6} severity="error">
                        {
                            this.state.errorBarText.map((text) => (
                                <>
                                    {text}
                                    <br />
                                </>
                            ))
                        }
                    </MuiAlert>
                </Snackbar>
            </>
        );
    }
}

MusicDetailForm.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    official_title: PropTypes.string,
    tags: PropTypes.array,
    creditValue: PropTypes.array,
    publish: PropTypes.bool,
    private: PropTypes.bool,
    tag_lock: PropTypes.bool,
    is_music: PropTypes.bool,
    original: PropTypes.bool,
    imageId: PropTypes.number,
    musicId: PropTypes.number,
    tagEditId: PropTypes.number,
    isUpdate: PropTypes.bool,
}
MusicDetailForm.defaultProps = {
    title: "",
    official_title: "",
    description: "",
    tags: [],
    creditValue: [{
        id: 'None',
        type: 'None',
        name: ''
    }, {
        id: 'None',
        type: 'None',
        name: ''
    }, {
        id: 'None',
        type: 'None',
        name: ''
    }],
    publish: true,
    private: false,
    tag_lock: false,
    is_music: true,
    original: false,
    imageId: 1,
    image: undefined,
    musicId: -1,
    tagEditId: -1,
    isUpdate: false,
}

export default MusicDetailForm


const UploadBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 1),
        padding: theme.spacing(1),

    }
})

const UploadBox = withStyles(UploadBoxStyles)(Box);