import React from 'react';
import { withRouter } from 'react-router-dom';


//material-ui
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';


//my component
import {useStyles} from './style/common'
import AccountMenu from './AccountMenu'

//setting
import { ROOT_URL, SITE_NAME} from "./setting"

function Header(props) {
    const classes = useStyles();
    let isLoggedin = false
    if (document.getElementById('username').innerText !== "AnonymousUser") {
        isLoggedin = true
    } else {
        isLoggedin = false
    }

    const onSearch = (event) => {
        props.history.push("/search/"+event.target.value)
        
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" id="header">
                <Toolbar>
                    <Button
                        href={ROOT_URL}
                        size="large"
                        className={classes.logo}
                    >
                        {SITE_NAME}
                    </Button>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={onSearch}
                        />
                    </div>
                    <AccountMenu
                        isLoggedin={isLoggedin}
                        classes={classes}
                        userName={document.getElementById('username').innerText}
                        userIcon={document.getElementById('usericon').innerText}
                    />
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default withRouter(Header)