import React from 'react';
import axios from 'axios';

//material-ui
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//my component
import MarginedButton from '../common/MarginedButton'
import { DialogTitle, DialogContent } from '../common/DialogParts'
import { DialogOpen, DialogClose } from '../../action/common/DialogAction'


//setting
import { ROOT_URL, API_ROOT_URL } from "../../setting"



export default class EmailDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            successBar: false,
            errorBar: false,
            errorText: <></>,
            successText:<></>,
            email: undefined,
            newEmail: "",
            selectIndex: -1,
        }
    }

    async OnAddEmailClick() {
        const params = new FormData()
        if (this.state.newEmail === "") {
            this.setState({
                errorBar: true,
                errorText: <>新しいメールアドレスは空にできません。</>,
            })
        } else {
            params.append("email", this.state.newEmail)
            params.append("action_add", "")
            axios.defaults.xsrfCookieName = 'csrftoken'
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            await (axios.post(API_ROOT_URL + "accounts/email/", params)).then((res) => {
                if (res.data.match("このメールアドレスはすでに登録されています")) {
                    this.setState({
                        errorBarOpen: true,
                        errorBarText: "このメールアドレスはすでに登録されています",
                    })

                } else if (res.data.match("このメールアドレスは別のアカウントで使用されています")) {
                    this.setState({
                        errorBarOpen: true,
                        errorBarText: "このメールアドレスは別のアカウントで使用されています",
                    })

                } else if (res.data.match("有効なメールアドレスを入力してください")) {
                    this.setState({
                        errorBarOpen: true,
                        errorBarText: "有効なメールアドレスを入力してください",
                    })
                } else {
                    this.setState({
                        open: false,
                        successBar: true,
                        successText: <>確認メールを送信しました</>,
                    })
                }
            }).catch(error => {
                this.setState({
                    errorBarOpen: true,
                    errorBarText: "メールアドレスの追加に失敗しました",
                })
            })
        }
    }

    async MailAction(action) {
        const params = new FormData()
        if (this.state.selectIndex === -1) {
            this.setState({
                errorBar: true,
                errorText: <>操作するメールアドレスを選択してください。</>,
            })
        } else {
            params.append("email", this.state.email[this.state.selectIndex].email)
            if (action === "send") {
                params.append("action_send", "")
            } else if (action === "remove") {
                params.append("action_remove", "")
            } else if (action === "primary") {
                params.append("action_primary", "")
            }
            axios.defaults.xsrfCookieName = 'csrftoken'
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            await (axios.post(API_ROOT_URL + "accounts/email/", params)).then((res) => {
                if (action === "send") {
                    this.setState({
                        open: false,
                        selectIndex:-1,
                        successBar: true,
                        successText: <>確認メールを送信しました</>,
                    })
                } else if (action === "remove") {
                    this.setState({
                        open: false,
                        selectIndex: -1,
                        successBar: true,
                        successText: <>削除しました</>,
                    })
                } else if (action === "primary") {
                    this.setState({
                        open: false,
                        selectIndex: -1,
                        successBar: true,
                        successText: <>メインのアドレスを変更しました</>,
                    })
                } else {
                    this.setState({
                        errorBarOpen: true,
                        errorBarText: "予期せぬエラー発生しました",
                    })
                }
            }).catch(error => {
                this.setState({
                    errorBarOpen: true,
                    errorBarText: "予期せぬエラー発生しました",
                })
            })
        }

    }


    onChange = (event) => {
        this.setState({ newEmail: event.target.value })
    }

    DialogOpen = () => {
        this.setState({ open: true })
        this.GetEmail()
    }

    SelectEmail = (event) => {
        this.setState({ selectIndex: Number(event.target.value) })
    }

    async GetEmail() {
        await (axios.get(API_ROOT_URL + "email/" + this.props.username + "/", {}))
            .then(res => {
            this.setState({
                email: res.data,
            })
            this.GetIcon()
        }).catch(error => {
            console.log(error)
            this.setState({
                errorBarOpen: true,
                errorBarText: "メールアドレスの取得に失敗しました",
            })
        })
    }

    render() {
        return (
            <DeleteDialogBox>
                <MarginedButton onClick={() => this.DialogOpen()} variant="contained">
                    メールアドレスの設定 {!this.props.hasEmail && <>(未設定)</> }
                </MarginedButton>
                <Dialog onClose={() => DialogClose(this)} ari-labelledby="customized-dialog-title" open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={() => DialogClose(this)}>
                        メールアドレスの設定
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variang="body2">
                            メールアドレスでのログイン操作やパスワード登録に必要なメールアドレスを設定します。
                        </Typography>
                        <br />
                        <Divider />
                        {this.state.email === undefined ? <>読込中<br /></> : <>
                            {this.state.email.length === 0 ? <Typography variang="body2">登録済みのメールアドレスはありません<br /></Typography> : <>
                                <RadioGroup name="email-radio-group" onChange={this.SelectEmail}>
                                    {this.state.email.map((email,index) => (<>
                                        <FormControlLabel value={String(index)} control={<Radio />} label={<>
                                            {email.primary ? "★" : ""}
                                            {email.email}
                                            {email.verified ? "" : " 【未認証】"}
                                        </>} />
                                    </>))}
                                </RadioGroup>
                                <MarginedButton disabled={this.state.selectIndex === -1 || !this.state.email[this.state.selectIndex].verified || this.state.email[this.state.selectIndex].primary} variant="contained"
                                    onClick={()=>this.MailAction("primary")}
                                >
                                    メインにする
                                </MarginedButton>
                                <MarginedButton disabled={this.state.selectIndex === -1 || this.state.email[this.state.selectIndex].verified} variant="contained"
                                    onClick={() => this.MailAction("send")}
                                >
                                    確認メール再送
                                </MarginedButton>
                                <MarginedButton disabled={this.state.selectIndex === -1 || this.state.email[this.state.selectIndex].primary} variant="contained"
                                    onClick={() => this.MailAction("remove")}
                                >
                                    削除する
                                </MarginedButton>
                            </>}
                            {this.state.email.length < 3 && <>
                                <Divider />
                                <Typography variang="body2">
                                    新しいメールアドレスの追加
                                </Typography>

                                <TextField
                                    label="メールアドレス"
                                    value={this.state.newEmail}
                                    onChange={this.onChange}
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 100 }}
                                />
                                <MarginedButton onClick={() => this.OnAddEmailClick()} variant="contained" disabled={ this.state.newEmail === ""}>
                                    メールアドレスの追加
                                </MarginedButton>
                            </>}
                        </>}
                        
                        <br />
                        <br />
                    </DialogContent>
                </Dialog>
                <Snackbar open={this.state.successBar} autoHideDuration={6000} onClose={() => { window.location.reload() }}>
                    <MuiAlert elevation={6} severity="success">{this.state.successText}</MuiAlert>
                </Snackbar>
                <Snackbar open={this.state.errorBar} autoHideDuration={6000} onClose={() => { this.setState({ errorBar: false }) }}>
                    <MuiAlert elevation={6} severity="error">{this.state.errorText}</MuiAlert>
                </Snackbar>
            </DeleteDialogBox>
        )
    }
}

const DeleteDialogBoxStyles = (theme) => ({
    root: {
        margin: theme.spacing(1, 1, 1, 2),
        padding: theme.spacing(1),
    }
})

const DeleteDialogBox = withStyles(DeleteDialogBoxStyles)(Box);