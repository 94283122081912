import React from 'react';

//my component
import LockedTagButton from './LockedTagButton'
import UnLockedTagButton from './UnLockedTagButton'

const TagButton = (props) => {
    let view
    if (props.edit) {
        view =
            <UnLockedTagButton
                value={props.value}
                tagId={props.tagId}
                onDeleteClick={props.onDeleteClick}
            />
    } else {
        view =
            <LockedTagButton
                value={props.value}
                tagId={props.tagId}
            />
    }
    return (<>{view}</>)
}

export default TagButton