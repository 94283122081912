import React from 'react';

//material-ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const SimpleTable = (props) => {
    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                props.header.map((col) => (
                                    <TableCell align={props.align}>{col}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.body.map((row) => (
                                <TableRow>
                                    {
                                        row.map((col) => (
                                            <TableCell align={props.align}>{col}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default SimpleTable